import React, {ReactElement} from "react";

export const shapesSVG: {[shapeIndex: number]: ReactElement} = {
    /*rectangle*/
    1: <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
    <mask id="path-1-inside-1_4711_6971" fill="white">
    <rect width="24" height="24" rx="1"/>
    </mask>
    <rect width="24" height="24" rx="1" strokeWidth="4" mask="url(#path-1-inside-1_4711_6971)"/>
    </svg>,
    /*triangle*/
    7: <svg className="svg_shape" width="24" height="23" viewBox="0 0 24 23">
    <path d="M1.61803 22L12 1.23607L22.382 22H1.61803Z" strokeWidth="2"/>
    </svg>,
    /*circle*/
    9: <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
    <rect x="1" y="1" width="22" height="22" rx="11" strokeWidth="2"/>
    </svg>,
    /*hexagon*/
    10: <svg className="svg_shape" width="21" height="24" viewBox="0 0 21 24">
    <path
        d="M10.5 1.15175L20 6.58032V17.4197L10.5 22.8482L1 17.4197L1 6.58032L10.5 1.15175L10.0039 0.283508L10.5 1.15175Z"
    strokeWidth="2"/>
    </svg>,
    /*rounded_rectangle*/
    5: <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
    <rect x="1" y="1" width="22" height="22" rx="7" strokeWidth="2"/>
    </svg>,
    /*parallelogram*/
    2: <svg className="svg_shape" width="27" height="24" viewBox="0 0 27 24">
    <path d="M13.6694 1L25.3164 0.999999L13.3306 23H1.68359L13.6694 1Z" strokeWidth="2"/>
    </svg>,
    /*rhombus*/
    4: <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
    <path
        d="M1.41421 12L12 1.41421L11.2929 0.707107L12 1.41421L22.5858 12L12 22.5858L1.41421 12L0.707107 12.7071L1.41421 12Z"
    strokeWidth="2"/>
    </svg>,
    /*heart*/
    21: <svg className="svg_shape" width="24" height="24" viewBox="0 0 24 24">
    <path
        d="M11.0722 4.87234L12.017 7.22144L12.9324 4.86075C13.5944 3.15341 15.2525 1 18 1C20.5984 1 23 3.59737 23 7.2C23 8.81306 22.3452 10.6154 21.2616 12.4693C20.1856 14.3105 18.7363 16.1188 17.2634 17.7227C15.7938 19.3231 14.3208 20.6984 13.2137 21.6745C12.7262 22.1043 12.311 22.4555 12 22.7132C11.689 22.4555 11.2738 22.1043 10.7863 21.6745C9.67917 20.6984 8.20619 19.3231 6.73656 17.7227C5.26371 16.1188 3.81442 14.3105 2.73836 12.4693C1.65483 10.6154 1 8.81306 1 7.2C1 3.59737 3.40155 1 6 1C6.96462 1 8.02674 1.43977 8.98392 2.19218C9.93742 2.94171 10.6932 3.92992 11.0722 4.87234Z"
    strokeWidth="2"/>
    </svg>,
    /*lightning*/
    102: <svg className="svg_shape" width="20" height="20" viewBox="0 0 20 20">
    <path
        d="M1.83804 11.5014L11.5286 1.35595L10.4196 6.03764C10.122 7.29381 11.0748 8.49863 12.3657 8.49863L18.9063 8.49863L9.17095 18.6561L10.2828 13.9624C10.5803 12.7062 9.62758 11.5014 8.33665 11.5014H1.83804Z"
    strokeWidth="2"/>
    </svg>,
    /*leaf*/
    104: <svg className="svg_shape" width="23" height="24" viewBox="0 0 23 24">
    <path d="M1 22.5C1 10.9611 10.0901 1.54523 21.5 1.02284V1.5C21.5 13.0389 12.4099 22.4548 1 22.9772V22.5Z"
    strokeWidth="2"/>
    </svg>,
};