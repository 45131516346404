import React, {useRef, useState} from 'react';
import { motion } from 'framer-motion';
import t from "../../../../../lib/lng";
import * as Sentry from "@sentry/browser";
import UploadImageCrop from "./upload-image-crop.block";
import { useOutletContext } from "react-router";
import { useUploadLogoMutation } from "../../../../store/api/presentation.api";

const UploadLogoBlock = (props) => {
    const { onCloseUpload } = props;
    const [imgSrc, setImgSrc] = useState('');
    const [useBlackBg, setUseBlackBg] = useState(false);
    const [fileInfo, setFileInfo] = useState({name: '', type: ''});
    const [isSaving, setIsSaving] = useState(false);
    const previewCanvasRef = useRef(null);
    const [uploadLogoMutation] = useUploadLogoMutation();


    const { updateData, context } = useOutletContext();

    const onSelectFile = e => {
        if (!e.target.files[0]) return;
        if (!e.target.files[0].type.match(/jpeg|png/)) {
            onError(t.weSupportOnlyJpgPng);
            return;
        }
        if (e.target.files[0].size > 5242880) {
            onError(t.maximumSizeExceeded);
            return;
        }
        setFileInfo({
            name: e.target.files[0].name,
            type: e.target.files[0].type
        });
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result.toString() || '');
        });
        reader.readAsDataURL(e.target.files[0]);
    }

    const onClickSave = async () => {
        try {
            setIsSaving(true);
            previewCanvasRef?.current.toBlob(async blob => {
                const file = new File([blob], fileInfo.name, {type: fileInfo.type});
                const formData = new FormData();
                const saveLogoPrevState = context?.['user-preset']?.logo ?? context?.['user_preset']?.logo ;
                formData.append('file', file);
                formData.append('color', useBlackBg ? '#000000' : '#ffffff');
                uploadLogoMutation({body: formData}).unwrap().then((response) => {
                    setIsSaving(false);
                    if (response.status) {
                        // onSetUploadedLogo((prevState) => [...prevState, response.result.value]);
                        updateData(() => {
                            const logos = context?.['user-preset']?.logo ?? context?.['user_preset']?.logo;
                            if (!logos) return;
                            const logo = logos.filter(i => i.value.includes(name))[0];
                            if (!logo) return;
                            // this.selectOption('logo', logo.value, true, true);
                            // this.setState({newLogo: logo.value});
                        });
                        onCloseUpload();
                    }
                });
                // let response = await fetch('/upload-logo/', {
                //     method: 'post',
                //     body: formData,
                //     headers: xhrHeaders()
                // });
                // if (!response.ok) throw({msg: response.statusText, file: file});
                // response = await response.json();
                // setIsSaving(false);
                // if (response.status) {
                //     updateData(() => {
                //         const logos = context['user-preset'].logo;
                //         if (!logos) return;
                //         const logo = logos.filter(i => i.value.includes(name))[0];
                //         if (!logo) return;
                //         this.selectOption('logo', logo.value, true, true);
                //         this.setState({newLogo: logo.value});
                //     });
                //     close();
                // }
                // else {
                //     throw({msg: response.errors, file: file});
                // }
            });
        }
        catch(e) {
            Sentry.captureException({
                function: 'UploadLogo',
                msg: e.msg,
                file_name: e.file ? e.file.name : '',
                file_size: e.file ? e.file.size : ''
            });
        }
    }
    return(
        <motion.div initial={{scale: 0 }} animate={{ scale: '100%'}} exit={{scale: 0}}  className="modal upload_logo">
            <div className="modal_overlay" onClick={onCloseUpload}></div>
            <div className="modal_window">
                <div className="modal_close" onClick={onCloseUpload}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <line y1="-1" x2="21.2624" y2="-1" transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)" stroke="#fff" strokeWidth="2"/>
                        <line y1="-1" x2="21.2624" y2="-1" transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)" stroke="#fff" strokeWidth="2"/>
                    </svg>
                </div>
                <div className="upload_logo__title">{t.UploadLogo}</div>
                {
                    !imgSrc &&
                    <div className="gen_upload">
                        <label>
                            <div className="gen_btn_main gen_upload__btn">{t.SelectYourFile}</div>
                            <input className="gen_upload__input" type="file" accept=".png, .jpg"
                                   onChange={onSelectFile}
                                   onDragLeave={()=>{onDragLeaveHandler()}}
                                   onDrop={()=>{onDragLeaveHandler()}} />
                        </label>
                        <div className="gen_upload__subtext">{t.UploadLogoText}</div>
                    </div>
                }
                {
                    imgSrc &&
                    <div className="upload_logo__crop">
                        <div className={'upload_logo__crop_wrap' + (useBlackBg ? ' black_bg' : '')}>
                            <UploadImageCrop imgSrc={imgSrc} previewCanvasRef={previewCanvasRef} />
                        </div>
                        <div className="upload_logo__cbar">
                            <label className="checkbox_wrap">
                                <input type="checkbox" style={{display: 'none'}} value={useBlackBg} onChange={(e) => {setUseBlackBg(!useBlackBg)}} />
                                <span className="checkbox checkbox-color"></span>
                                {t.useBlackBg}
                            </label>
                            <div className={'about_btn upload_logo__save' + (isSaving ? ' disabled' : '')} onClick={onClickSave}>{isSaving ? t.Saving+'...' : t.Save}</div>
                        </div>
                    </div>
                }
            </div>
        </motion.div>
    );
};

export default UploadLogoBlock;