import React from 'react';
import {useParams} from "react-router-dom";
import SetupStyleBlock from "./blocks/setup-style.block";

const SetupStylePage = () => {
    const params = useParams();

    return <SetupStyleBlock projectId={String(params.projectId)} />
};

export default SetupStylePage;