import React, { useEffect, useRef, useState } from 'react'
import { OnboardingMessage } from '../../../../components/template'
import ThemeItem from './theme-item.block'
import { useOutletContext } from 'react-router'

const ONBOARDING_MESSAGES = {
	title: 'Choose your style',
	description:
		'Learn how to use Wonderslide effectively.\n' +
		'Pick “Create from scratch” to start.',
	boardId: 2,
	onBoardType: 'presentation',
	isFullWidthChild: true,
}
const LOCAL_THEME = [
	'scandic',
	'marble elegance',
	'nordic essence',
	'simple',
	'flat bubble',
	'waves',
	'geometric',
	'bold circle',
	'spark pulse',
	'rigid square',
	'eco leaf',
	'heart flare',
	'angular edge',
]
const FREE_THEME_REGEXP = new RegExp(/flat bubble|scandic|simple/, 'g')
const GenThemes = (props) => {
	const { selectedTheme, setSelectedTheme } = props
	const { onboardingMessage, context, setTrialPopup } = useOutletContext()
	const [hoveredTheme, changeHoveredTheme] = useState(1)
	const [themes, setThemes] = useState(LOCAL_THEME)

	const useOnboardingRef = useRef(null)

	useEffect(() => {
		context.options?.asset_themes &&
			setThemes((prevState) =>
				Array.from(
					new Set([
						...prevState,
						...(context.options?.asset_themes ?? []),
					])
				)
			)
	}, [context.options?.asset_themes])

    function selectHandler (isLocked, theme) {
            if (isLocked) {
                setTrialPopup({type: 'style'});
            }
            else {
                onboardingMessage(ONBOARDING_MESSAGES.onBoardType)[1]({ step: 3, freeze: 2 });
                setSelectedTheme(theme);
            }
    }
    function onBoardingMessageHandler(theme){
        setSelectedTheme(theme);
    }
    const themesLength = themes.length;

	function renderThemeList({ isNotPremium }) {
		return (
			<>
				{themes
					.filter((theme, index) => {
						return (
							Boolean(
								theme?.match?.(FREE_THEME_REGEXP)?.length
							) === isNotPremium
						)
					})
					.map((theme, i) => {
						if (theme === 'classic') return
						const isLocked =
							context?.product_balance?.product?.is_trial &&
							!isNotPremium
						if (
							isLocked ||
							localStorage.getItem(
								`${ONBOARDING_MESSAGES.onBoardType}_process`
							) === 'finish'
						)
							return (
								<ThemeItem
									key={i}
									theme={theme}
									isActive={selectedTheme === theme}
									isLocked={isLocked}
									select={() =>
										selectHandler(isLocked, theme)
									}
									style={{ width: '100%', zIndex: 1000 }}
								/>
							)
						return (
							<div
								key={i}
								onMouseEnter={() => {
									changeHoveredTheme(i)
								}}
							>
								<OnboardingMessage
									key={i}
									ignoreRef={[useOnboardingRef]}
									boardId={2}
									zIndex={themesLength + 1 - i++}
									isActivate={onboardingMessage(
										ONBOARDING_MESSAGES.onBoardType
									)[0]()}
									onNext={() =>
										onBoardingMessageHandler(theme)
									}
									forceShow={i === hoveredTheme}
									{...ONBOARDING_MESSAGES}
								>
									<ThemeItem
										key={i}
										theme={theme}
										isActive={selectedTheme === theme}
										isLocked={isLocked}
										select={() =>
											selectHandler(isLocked, theme)
										}
										style={{ width: '100%', zIndex: 1000 }}
									/>
								</OnboardingMessage>
							</div>
						)
					})}
			</>
		)
	}
	return (
		<div className="gen_styles z-[0]">
			<h1 className="gen_styles_header">Choose the presentation style</h1>
			<div className="gen_styles_list">
				{renderThemeList({ isNotPremium: true })}
			</div>
			<div>
				<h2 className="text-font_ds_title_title_title_24 text-general_text_MII_default pl-[20px] mt-[28px]">
					Premium styles
				</h2>
				<div className="gen_styles_list">
					{renderThemeList({ isNotPremium: false })}
				</div>
			</div>
		</div>
	)
}

export default GenThemes
