import React, {useEffect, useState} from 'react';

import {useOutletContext} from "react-router";

import SlideResultItemBlock from "./slide-result-item.block";
import SlideLoader from "./slide-loader.block";
import { useToastContext } from "../../../hooks/use-toast-provider";
import SlideDraftMode from "./slide-draft-mode.block";
import SlideError from "./slide-error.block";

const SlideBlock = (props) => {
    const {
            isNoneGeneratedSlides,
            slideInfo,
            genListInfo,
            slideQuery,
            slideIndex,
            addRef,
            draftInfo,
            projectId,
            isNewSlide,
            themeOption,
            noClone,
            noDelete,
            shapes,
            setLastOperation,
            lastOperation
    } = props;
    const { TagManager } = useOutletContext();
    const { getToast } = useToastContext();
    const [ slideIsLoading, setSlideLoading ] = useState(true);
    const [ errors, setErrorList ] = useState(null);
    const [isEditMode, toggleEditMode] = useState(false);

    useEffect(() => {
        setSlideLoading(!slideInfo.empty && !slideQuery.ready);
        const errorsList = [];
        let catchErrorState = null;
        for(const messagesList of genListInfo){
            if(messagesList?.error?.message) {
                errorsList.push(messagesList?.error?.message);
                catchErrorState = messagesList.error;
                getToast({
                    systemMessage: {
                        msg: 'slide ' + (Number(slideIndex) + 1) + ' : ' + messagesList?.error?.message,
                        autoclose: 4000
                    }
                });
            }
        }
        setErrorList(errorsList);
        if(errorsList.length){
            amplitude.getInstance().logEvent('service error preview', {
                action: lastOperation,
                ...catchErrorState,
            });
            TagManager.dataLayer({
                dataLayer: {
                    event: 'track_error',
                    error_type: 'preview_error',
                }
            });
        }
    }, [slideQuery.ready, slideInfo.empty]);

    if (slideIsLoading) return (<SlideLoader slideIndex={slideIndex}/>);
    if(!slideQuery?.status && errors) return (
        <SlideError
            slideIndex={slideIndex}
            errors={errors}
            projectId={projectId}
            noDelete={noDelete}
        />);

    if ((slideInfo.empty || isEditMode) && (draftInfo || isNewSlide) ) {
        return <SlideDraftMode
            addRef={addRef}
            isNoneGeneratedSlides={isNoneGeneratedSlides}
            isNewSlide={isNewSlide}
            slideIndex={slideIndex}
            projectId={projectId}
            themeOption={themeOption}
            draftInfo={draftInfo}
            toggleEditMode={toggleEditMode}
            noClone={noClone}
            noDelete={noDelete}
            setLastOperation={setLastOperation}
        />
    }
    if (!slideInfo.empty) {
        return <SlideResultItemBlock
            slideIsLoading={slideIsLoading}
            slideIndex={slideIndex}
            themeOption={themeOption}
            addRef={addRef}
            arrayIdx={slideIndex}
            shapes={shapes}
            isEditable={!!draftInfo}
            projectId={projectId}
            onToggleEditMode = {toggleEditMode}
            noClone={noClone}
            noDelete={noDelete}
            setLastOperation={setLastOperation}
        />
    }
    return (<SlideLoader slideIndex={slideIndex}/>);
};

export default SlideBlock;