import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import { Outlet } from "react-router";
import TagManager from 'react-gtm-module';

const ONBOARD_PRESENTATION_TYPE = 'presentation';

export type CommonOutletContext = {
    user_ctx: any;
    updateData: any;
    logout: () => void;
    context: any;
    updateSharedFile: (data: {[x: string]: string | number | boolean}) => void;
    onboardingMessage: (type: string, keepSteps?: boolean) => (({step, freeze}: { step: number; freeze: number; }) => void)[];
    changePresentationType: Dispatch<SetStateAction<'pptx' | 'draft'>>;
    TagManager: typeof TagManager;
}

type CommonOutletProps = { updateData: any; logout: () => void; context: any, updateSharedFile:  (data: {[x: string]: string | number | boolean}) => void,};

const CommonOutlet: FC<CommonOutletProps> = (props) => {
    TagManager.initialize({'gtmId': 'GTM-K8P9426'});
    const { updateData, logout, updateSharedFile, context } = props;
    const [ onboardingState, changeOnboardingState ] = useState<{[type: string]: number}>({});
    const [presentationType, changePresentationType ] = useState<CommonOutletContext['changePresentationType']['arguments']>('pptx');
    function onboardingMessage(type: string, keepSteps: boolean = true){
        return [() => {
            if(presentationType === 'pptx' && type === ONBOARD_PRESENTATION_TYPE){
                return 1;
            }
            if(onboardingState?.['type'] === 1 && window.localStorage.getItem(`${type}_onboard_index`) !== window.localStorage.getItem('onboard_freeze')) {
                window.localStorage.setItem(`${type}_onboard_index`, window.localStorage.getItem(`${String(type)}_onboard_freeze`) ?? '1');
                return window.localStorage.getItem(`${type}_onboard_freeze`) ?? 1;
            }
            return window.localStorage.getItem(`${type}_onboard_index`) ?? onboardingState?.[type]  ?? 1
        }, ({ step, freeze }: { step: number, freeze: number }) => {
            if(presentationType==='pptx' && type === ONBOARD_PRESENTATION_TYPE) return;
            const localStep = window.localStorage.getItem(`${type}_onboard_index`);
            if(Number(localStep) >= Number(step) && keepSteps) return;
            window.localStorage.setItem(`${type}_onboard_index`, String(step));
            window.localStorage.setItem(`${type}_onboard_freeze`, String(freeze));
            changeOnboardingState((prevState) => ({ ...prevState, [type]: Number(step) }));
        }]
    }
    return (
        <>
            <Outlet context={{user_ctx: context.user_ctx, updateData, logout, context, updateSharedFile, onboardingMessage, changePresentationType, TagManager, } satisfies CommonOutletContext } />
        </>
    );
};

export default CommonOutlet;