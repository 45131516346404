import { hexToRgba, rgbaToHex } from "@uiw/react-color";
import { assetThemes, themeColors } from "../data";
import { contrast } from "../../lib/utils";

export default function useSendOptionsBuild(theme) {
    return ({ slide,  setup }) => {
        const currentColor = Array.isArray(setup.color) ? setup.color : Object.values(hexToRgba('#' + setup.color));
        const currentColorHex = rgbaToHex( {r: currentColor[0], g:currentColor[1], b: currentColor[2]}).replace('#', '');
        setup['color'] = currentColorHex;
        const formData = new FormData();
        for(const [styleFieldKey, styleFieldValue] of Object.entries(setup)){
            formData.set(styleFieldKey, styleFieldValue);
        }
        if(!assetThemes[theme].shapes.includes(setup.shape)) formData.set('shape', assetThemes[theme].shapes[0]);
        formData.set('asset_theme', assetThemes[theme].origin);
        if(setup?.logo?.length > 0) formData.set('custom_logo', 1);
        const ratioWhite = +contrast([255,255,255],  currentColor).toFixed(2),
            ratioBlack = +contrast([0,0,0], currentColor).toFixed(2);
        const textColor = [];
        if (themeColors.includes(currentColorHex.toLowerCase()) || currentColorHex.toLowerCase() === 'ed2626' && textColor.length > 1) textColor.push('FFFFFF');
        if (ratioWhite > 2) textColor.push('FFFFFF');
        if (ratioBlack > 4.2) textColor.push('000000');
        if (ratioWhite <= 2 && ratioBlack <= 4.2) {
            if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
            else textColor.push('000000');
        }
        formData.append('text_color', JSON.stringify(textColor));
        if (assetThemes[theme].image_data_source) {
            formData.append('image_data_source', assetThemes[theme].image_data_source);
        }
        if(slide){
            if (typeof(slide.to) !== 'undefined') {
                formData.append('index', slide.to);
                if (typeof(slide.text) === 'object') {
                    formData.append('marked_text', JSON.stringify(slide.text));
                }
                else {
                    formData.append('text', String(slide.text ?? ''));
                }
            }
        }

        return formData;
    }
}