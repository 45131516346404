import React from 'react';
import LegalLayoutFeature from "./features/legal-layout.feature";
import {legalData} from "./features/legal.data";
import {Link} from "react-router-dom";
import {route_path} from "../../utils/route_path";

const TermsPage = () => {
    return (
        <LegalLayoutFeature {...legalData['terms']}>
            <h2>Terms of use Wonderslide</h2>
                <p>
                    Wonderslide products and services are provided by Everypixel Labs Inc., located on 2021
                    Fillmore Street PMB 15, San Francisco, CA 94115 (“Wonderslide").
                </p>
                <p>
                    Wonderslide is an online automatic (AI-driven) presentation design service. Oure users
                can upload their own presentation file in .pptx format and download back the formatted
                file, as if the designer did this work. This Term of use applies to the Wonderslide.
                    Wonderslide is provided "as is", in the form of a Site-as-a-Service (SaaS).
                </p>
                <p>These Terms of Use ("Terms") govern your access to and use of Wonderslide's website
                    and services <a href="https://wonderslide.com/" target="_blank">https://wonderslide.com/</a>  (the “Site”)
                </p>
                <p>
                    Please read these Terms carefully, and contact us if you have any questions. By
                accessing or using Site, you agree to be bound by these Terms and by our {' '}
                    <Link to={route_path.legal_privacy}>Privacy Policy</Link>.
                </p>
                <p>
                    All confidential information you provided to us, we carefully guarded and not transmitted
                to third parties. But for improving the service and developing new features we may use
                the user's presentations, including the involvement of third parties for correct and
                    reliable operation.
                </p>
                <p>
                    In the event of problems with the Site operation, employees or contractors may have
                access to user data. Also to improve the operation of the site, we may also collect and
                    analyze the data that you provide to the site.
                </p>
                <p>
                    For more information about how we collect and store your information, may find it in {' '}
                    <Link to={route_path.legal_privacy}>Privacy Policy</Link>.
                </p>
                <p>
                    We will delete your data if you make a request to the address {' '}
                    <a href="mailto:support@wonderslide.com">support@wonderslide.com</a>.
                </p>
                <p>
                    In your personal account you have the option to let us know in advance if you wish not
                    to store your data on Site.
                </p>
                <p>
                    If you do not agree to all of these terms, do not use this Site. By using the Site, you
                represent that you are over thirteen (13) and have the authority and right to agree to
                these terms. If you are under 18 you must have your parent or legal guardian’s
                permission to use the Site. If you are accessing this Site on behalf of any entity, you
                further represent and warrant that you are authorized to accept these terms on such
                entity's behalf, and that such entity agrees to indemnify Wonderslide for violations of
                    these Site terms.
                </p>
                <p>
                    If We need to rely on consent as a legal basis for processing Your information and Your
                country requires consent from a parent, We may require Your parent's consent before
                    We collect and use that information.
                </p>
                <p>
                    Wonderslide reserves the right to alter, modify, or update these terms of use at any time
                    and you agree to be bound by such modifications, alterations or updates.
                </p>
            <h3>Ownership of this site</h3>
                <p>
                    This Site is owned and operated by Wonderslide. Some elements: text, images, data,
                the selection and arrangement of those elements (“Content”) and the general design are
                owned by Wonderslide or its licensors and are protected by copyright, trademark, trade
                dress, moral rights and other international laws or treaties relating to intellectual
                    property.
                </p>
                <p>
                    However, not all elements of the site are owned by Wonderslide: some text, still images,
                moving images, graphics, illustrations, audio, multimedia, graphics, vectors, software.
                This content is owned by other websites. Our Site may contain links to third-party
                websites, advertisers, services, special offers, or other events or activities that are not
                owned or controlled by Wonderslide.
                </p>
            <h3>Restriction in using of the Site</h3>
                <p>
                    You may use the presentations obtained from the Site in any way. However, you may
                not use a presentation template separately from the originally rendered content for that
                presentation. This means that users do not have the right to use the template of the
                    received presentation in the future with other data.
                </p>
            <h3>Trademarks</h3>
                <p>
                    You may not use Wonderslide’s trademarks or trade names without prior written
                consent. In addition, the look and feel of the Site, including all elements of the design is
                the service mark, trademark and/or trade dress of Wonderslide and may not be copied,
                imitated or used, in whole or in part, without our prior written permission. All other
                trademarks, registered trademarks, product names and company names or logos
                mentioned in the Site are the property of their respective owners. Reference to any
                products, services, processes or other information, by trade name, trademark,
                manufacturer, supplier or otherwise does not constitute or imply endorsement,
                    sponsorship or recommendation by Wonderslide.
                </p>
            <h3>Sign Up</h3>
                <p>
                    You may register a personal account on Site. Your registration becomes effective once
                    we activate your account.
                </p>
                <p>
                    Any information submitted to us during registration or in other communication must be
                complete and correct. Keep your password confidential and your user profile updated so
                    we can contact you at any time.
                </p>
                <p>
                    Your account is valid for you and only you. You may not transfer it to anyone else or
                    allow someone else to use it.
                </p>
            <h3>Plans and payment</h3>
            <p>All available subscription options are listed on the page <Link to={route_path.pricing}>https://wonderslide.com/pricing</Link>.</p>
            <p>All prices are given in USD per calendar month.</p>
            <p className="legal_doc_light_red">By using our service, you always keep full control of your Account.</p>
            <h4>Unsubscribe</h4>
            <p>
                You have the right to unsubscribe at any time using your Account.
            </p>
            <p>
                Refunds for past subscription periods are not possible. A refund for the newly paid
                period later than 10 days from the date of payment and the beginning of the period is
                impossible.
            </p>
            <p>
                The refund amount for the period of use of fewer than 10 days is calculated in
                proportion to the paid plan. We have the right to withhold an amount equal to the cost of
                fees of payment systems and banks and overhead costs for the refund. For a refund,
                please get in touch with us by emailing <a href="mailto:support@wonderslide.com">support@wonderslide.com</a>.
            </p>
            <h4>Recurring payments</h4>
            <p>
                Recurring payments - an automatic renewal of the subscription to the plan. Recurring
                payments activate automatically when you pay the subscription for the first time. The
                charge is made on the last day of the paid period or the day after the end of the plan.
            </p>
            <p>
                A refund for a recurring payment is possible in case of non-use of the service and
                contacting us at support@wonderslde.com within 10 days after the actual charge is
                made.
            </p>
            <p>
                You can disable recurring payments in the Settings section of your Account. If the
                recurring payment is disabled, the service continues to work until the end of the paid
                period.
            </p>
            <h4>Artificial intelligence from OpenAI, Microsoft and Google (AI)</h4>
            <p>
                To improve Wonderslide, we will use AI API to provide services on our site. This means
                that some of the information might be processed using an API from OpenAI, Microsoft
                or Google. The need to use these APIs is determined automatically on a case-by-case
                basis.
            </p>
            <p>
                By using Wonderslide, you consent to the processing of information by the AI and have
                read the terms of use of the AI. The AI terms of use are located here:
            </p>
            <ul>
                <li><a href="https://openai.com/policies" target="_blank" rel="nofollow">1. https://openai.com/policies</a></li>
                <li><a href="https://azure.microsoft.com/en-us/support/legal/" target="_blank" rel="nofollow">2. https://azure.microsoft.com/en-us/support/legal/</a></li>
                <li><a href="https://developers.google.com/terms" target="_blank" rel="nofollow">3. https://developers.google.com/terms</a></li>
            </ul>
            <p>
                WONDERSLIDE AND DISCLAIM ALL WARRANTIES INCLUDING BUT NOT LIMITED
                TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET
                ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF
                DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL
                BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT
                WILL BE SECURE OR NOT LOST OR ALTERED.
            </p>
            <h3>Use of this site and contents. Revocation</h3>
            <p>
                This Site and its contents are intended for use by Wonderslide’s customers and visitors.
                In using the Site and the Content, you represent and warrant that you will comply with
                all applicable laws and regulations, including, without limitation, those relating to the
                Internet, data, e-mail, privacy, and the transmission of technical data exported from the
                country in which you reside. Wonderslide reserves the right, in its sole discretion, to
                revoke your authorization to view, download, and use the Content and information
                available on the Site at any time and for any reason, with or without notice to you of
                such revocation, and you agree to immediately discontinue such use upon notice from
                Wonderslide.
            </p>
            <h3>General disclaimers</h3>
            <p>
                THIS SITE AND THE CONTENT ARE PROVIDED "AS IS" AND WONDERSLIDE
                EXCLUDES, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY
                WARRANTY, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
                IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR
                FITNESS FOR A PARTICULAR PURPOSE. WONDERSLIDE WILL NOT BE LIABLE
                FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE OR
                THE CONTENT, OR THE UNAVAILABILITY OF THE SITE OR CONTENT,
                INCLUDING, BUT NOT LIMITED TO LOST PROFITS, AND DIRECT, INDIRECT,
                INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES. THE FUNCTIONS
                EMBODIED ON OR IN THE MATERIALS OF THIS SITE ARE NOT WARRANTED TO
                BE UNINTERRUPTED OR WITHOUT ERROR. YOU, NOT WONDERSLIDE, ASSUME
                THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION
                DUE TO YOUR USE OF THIS SITE OR WONDERSLIDE CONTENT. WE MAKE NO
                WARRANTY THAT THE SITE OR THE CONTENT IS FREE FROM INFECTION BY
                VIRUSES OR ANYTHING ELSE THAT HAS CONTAMINATING OR DESTRUCTIVE
                PROPERTIES.
            </p>
            <p>
                Some countries do not permit the exclusion or limitation of implied warranties or liability
                for certain categories of damages. Therefore, some or all of the limitations above may
                not apply to you to the extent they are prohibited or superseded by your country.
            </p>
            <h3>Limitation of liability</h3>
            <p>
                IN NO EVENT SHALL WONDERSLIDE, ITS DIRECTORS, MEMBERS, EMPLOYEES
                OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
                INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF
                DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT
                LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
                CONNECTED WITH THE USE OF THE SITE, THE SERVICES, THE CONTENT OR
                THE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SITE, INCLUDING
                WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM
                RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM WONDERSLIDE,
                OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION
                OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
                TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT
                RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT,
                DESTRUCTION OR UNAUTHORIZED ACCESS TO WONDERSLIDE'S RECORDS,
                CONTENT, PROGRAMS OR SERVICES. IN NO EVENT SHALL THE AGGREGATE
                LIABILITY OF WONDERSLIDE, WHETHER IN CONTRACT, WARRANTY, TORT
                (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED),
                PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF
                OR RELATING TO THE USE OF THE SITE EXCEED ANY COMPENSATION YOU
                PAY, IF ANY, TO WONDERSLIDE FOR ACCESS OR USE OF THE SITE.
                WONDERSLIDE GIVES NO RIGHTS OR WARRANTIES WITH RESPECT TO THE
                USE OF NAMES, TRADEMARK, LOGO TYPES, REGISTERED OR COPYRIGHTED
                DESIGNS OR WORKS OF ART DEPICTED IN ANY CONTENT, AND THE LICENSEE
                MUST SATISFY ITSELF THAT ALL NECESSARY RIGHTS, CONSENTS OR
                PERMISSION AS MAY BE REQUIRED FOR REPRODUCTION ARE SECURED.
                WONDERSLIDE IS NOT RESPONSIBLE AND DISCLAIMS ALL LIABILITY FOR ANY
                MISUSE OF THE CONTENT BY THIRD PARTIES.
            </p>
            <h3>INDEMNITY</h3>
            <p>
                You agree to defend, hold harmless and indemnify Wonderslide from and against any
                and all losses, costs, expenses, damages or other liabilities incurred by Wonderslide
                arising from or related to any cause of action, claim, suit, proceeding, demand or action
                brought by a third party against Wonderslide: (a) in connection with your use including
                any payment obligations; or (b) resulting from:
            </p>
            <p>
                (i) your use of Wonderslide; (ii) your decision to supply profile or payment information;
                (iii) any breach of contract or other claims made by Wonderslide with which you
                conducted business through Wonderslide; (iv) your breach of any provision of this
                Agreement; (v) any liability arising from the tax treatment of payments or any portion
                thereof; (vi) any negligent or intentional wrongdoing by any Member with which you
                conducted business through Wonderslide; (vii) any act or omission of yours with respect
                to the payment of fees; (viii) your dispute of or failure to pay any invoice or any other
                payment; or (ix) your obligations to a Wonderslide. Any such indemnification shall be
                conditioned on our: (a) notifying you in writing of any such claim, demand, action, cost,
                liability, loss or threat of any thereof; (b) cooperating with you in the defense or
                settlement thereof; and (c) allowing you to control such defense or settlement. We shall
                be entitled to participate in such defense through our own counsel at our own cost and
                expense. We reserve the right to report any wrongdoing of which we become aware to
                the applicable government agencies or otherwise.
            </p>
            <h3>Governing Law and Jurisdiction</h3>
            <p>
                These Terms shall be governed by the laws of the state of Delaware, without respect to
                its conflict of laws principles.
            </p>
            <p>
                Our Products are controlled and operated from the USA, and we make no
                representations that they are appropriate or available for use in other locations.
            </p>
            <h3>Notification Procedures and changes to these Terms.</h3>
            <p>
                Wonderslide reserves the right to determine the form and means of providing
                notifications to you, and you agree to receive legal notices electronically if we so
                choose. We may revise these Terms from time to time and the most current version will
                always be posted on our website. By continuing to access or use the Site after revisions
                become effective, you agree to be bound by the revised Terms. If you do not agree to
                the new terms, please stop using the Site.
            </p>
            <p>November 29, 2023</p>
        </LegalLayoutFeature>
    );
};

export default TermsPage;