import type { AssetThemesType } from '@app/types/theme.type'

const shapesDefault = [1, 7, 9, 10, 5, 4, 2, 21, 102, 104]
const colorsDefault = [
	{ value: 'FED929' },
	{ value: 'FF7914' },
	{ value: 'ED2626' },
	{ value: 'FF2D84' },
	{ value: '6C37AA' },
	{ value: '081893' },
	{ value: '3BA4DD' },
	{ value: '53C2C2' },
	{ value: '5FB342' },
	{ value: 'E5B1A5' },
	{ value: 'E7E6E6' },
]
const fontsDefault = [
	{ value: 'Advent Pro' },
	{ value: 'Alumni Sans' },
	{ value: 'Bellota' },
	{ value: 'Comfortaa' },
	{ value: 'Cormorant Infant' },
	{ value: 'Exo 2' },
	{ value: 'IBM Plex Sans' },
	{ value: 'Jost' },
	{ value: 'Lora' },
	{ value: 'Montserrat' },
	{ value: 'Mulish' },
	{ value: 'Noto Sans' },
	{ value: 'Nunito' },
	{ value: 'Open Sans' },
	{ value: 'Oswald' },
	{ value: 'Play' },
	{ value: 'Playfair Display' },
	{ value: 'Roboto' },
	{ value: 'Sofia Sans' },
	{ value: 'Ubuntu' },
]

const assetThemes: AssetThemesType = {
	classic: {
		origin: 'classic',
		colors: [
			{ value: 'FED929' },
			{ value: 'FF7914' },
			{ value: 'ED2626' },
			{ value: 'FF2D84' },
			{ value: '6C37AA' },
			{ value: '081893' },
			{ value: '3BA4DD' },
			{ value: '53C2C2' },
			{ value: '5FB342' },
			{ value: 'E5B1A5' },
			{ value: 'E7E6E6' },
		],
		shapes: [],
		fonts: fontsDefault,
	},
	'flat bubble': {
		origin: 'flat bubble',
		shapes: [9],
		colors: [
			{ value: '000000' },
			{ value: '808000' },
			{ value: '009688' },
			{ value: '6495ED' },
			{ value: '87CEFA' },
			{ value: 'FF1744' },
			{ value: 'FFA500' },
			{ value: 'FFD740' },
			{ value: 'd1bf7b' },
			{ value: 'FFDAB9' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	'bold circle': {
		origin: 'classic',
		shapes: [9, 5],
		colors: [
			{ value: '000000' },
			{ value: '00008B' },
			{ value: '6495ED' },
			{ value: '00BFAE' },
			{ value: '7CCD7C' },
			{ value: 'B03A7B' },
			{ value: 'FF69B4' },
			{ value: 'FF0000' },
			{ value: 'FFA500' },
			{ value: 'FFD740' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	'spark pulse': {
		origin: 'classic',
		shapes: [102],
		colors: [
			{ value: '000000' },
			{ value: '6A1B9A' },
			{ value: '0000FF' },
			{ value: '3B8DFF' },
			{ value: '00BFFF' },
			{ value: 'C0C0C0' },
			{ value: 'FF0000' },
			{ value: 'FF7F50' },
			{ value: 'FFEA00' },
			{ value: 'D2B48C' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
		],
	},
	'rigid square': {
		origin: 'classic',
		shapes: [1, 4, 10],
		colors: [
			{ value: '000000' },
			{ value: '546E7A' },
			{ value: '3949AB' },
			{ value: '03A9F4' },
			{ value: '9C27B0' },
			{ value: 'D32F2F' },
			{ value: 'FF6F00' },
			{ value: 'FFEB3B' },
			{ value: '009688' },
			{ value: '4CAF50' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
		],
	},
	'eco leaf': {
		origin: 'classic',
		shapes: [104],
		colors: [
			{ value: '000000' },
			{ value: '5D4037' },
			{ value: '808000' },
			{ value: '6B8E23' },
			{ value: '2E8B57' },
			{ value: '6495ED' },
			{ value: '00ACC1' },
			{ value: 'C0D9AF' },
			{ value: 'F0E68C' },
			{ value: 'D5C4A1' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	geometric: {
		origin: 'geometric',
		shapes: [1, 5, 9],
		colors: [
			{ value: '000000' },
			{ value: '800080' },
			{ value: 'B03A7B' },
			{ value: '6495ED' },
			{ value: '009688' },
			{ value: '00ACC1' },
			{ value: '87CEEB' },
			{ value: 'FFD740' },
			{ value: 'd1bf7b' },
			{ value: 'D3D3D3' },
		],
		fonts: [
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
		],
	},
	'heart flare': {
		origin: 'classic',
		shapes: [21],
		colors: [
			{ value: '000000' },
			{ value: '00ACC1' },
			{ value: '00BFFF' },
			{ value: '7B1FA2' },
			{ value: '8B0000' },
			{ value: 'C71585' },
			{ value: 'FF4081' },
			{ value: 'FFB6C1' },
			{ value: 'FFD700' },
			{ value: 'D2B48C' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	'angular edge': {
		origin: 'classic',
		shapes: [7, 2],
		colors: [
			{ value: '000000' },
			{ value: '673AB7' },
			{ value: '00BFFF' },
			{ value: '00ACC1' },
			{ value: '7CCD7C' },
			{ value: 'A52A2A' },
			{ value: 'FF0000' },
			{ value: 'FF7F50' },
			{ value: 'FFEA00' },
			{ value: 'D2B48C' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
		],
	},
	waves: {
		origin: 'waves',
		shapes: [9],
		colors: [
			{ value: '000000' },
			{ value: '009688' },
			{ value: '03A9F4' },
			{ value: '87CEFA' },
			{ value: '6495ED' },
			{ value: '673AB7' },
			{ value: 'FF1744' },
			{ value: 'FFD740' },
			{ value: 'FFA500' },
			{ value: 'D2B48C' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	scandic: {
		origin: 'scandic',
		shapes: [1],
		colors: [
			{ value: '000000' },
			{ value: '3C4868' },
			{ value: 'AEC6CF' },
			{ value: 'D3D3D3' },
			{ value: 'FFD1DC' },
			{ value: 'F5F5DC' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
			{ value: '808000' },
			{ value: 'A0522D' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
	},
	'flat bubble test': {
		origin: 'flat bubble test',
		shapes: [9],
		colors: [
			{ value: '000000' },
			{ value: '808000' },
			{ value: '009688' },
			{ value: '6495ED' },
			{ value: '87CEFA' },
			{ value: 'FF1744' },
			{ value: 'FFA500' },
			{ value: 'FFD740' },
			{ value: 'd1bf7b' },
			{ value: 'FFDAB9' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	'scandic test': {
		origin: 'scandic test',
		shapes: [1],
		colors: [
			{ value: '000000' },
			{ value: '3C4868' },
			{ value: 'AEC6CF' },
			{ value: 'D3D3D3' },
			{ value: 'FFD1DC' },
			{ value: 'F5F5DC' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
			{ value: '808000' },
			{ value: 'A0522D' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
	},
	'classic test': {
		origin: 'classic test',
		colors: [
			{ value: 'FED929' },
			{ value: 'FF7914' },
			{ value: 'ED2626' },
			{ value: 'FF2D84' },
			{ value: '6C37AA' },
			{ value: '081893' },
			{ value: '3BA4DD' },
			{ value: '53C2C2' },
			{ value: '5FB342' },
			{ value: 'E5B1A5' },
			{ value: 'E7E6E6' },
		],
		shapes: [],
		fonts: fontsDefault,
	},
	'waves test': {
		origin: 'waves test',
		shapes: [9],
		colors: [
			{ value: '000000' },
			{ value: '009688' },
			{ value: '03A9F4' },
			{ value: '87CEFA' },
			{ value: '6495ED' },
			{ value: '673AB7' },
			{ value: 'FF1744' },
			{ value: 'FFD740' },
			{ value: 'FFA500' },
			{ value: 'D2B48C' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
	},
	'geometric test': {
		origin: 'geometric test',
		shapes: [1, 5, 9],
		colors: [
			{ value: '000000' },
			{ value: '800080' },
			{ value: 'B03A7B' },
			{ value: '6495ED' },
			{ value: '009688' },
			{ value: '00ACC1' },
			{ value: '87CEEB' },
			{ value: 'FFD740' },
			{ value: 'd1bf7b' },
			{ value: 'D3D3D3' },
		],
		fonts: [
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
		],
	},
	'nordic essence': {
		origin: 'scandic',
		shapes: [1],
		colors: [
			{ value: 'D0D0D0' },
			{ value: 'AEC6CF' },
			{ value: 'C0D0C0' },
			{ value: 'C0C1D0' },
			{ value: 'D8B4A6' },
			{ value: 'C4A69F' },
			{ value: 'D9C8A1' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
			{ value: '808000' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
		image_data_source: 'sharp_abstract',
	},
	simple: {
		origin: 'simple',
		shapes: [9],
		colors: [
			{ value: '000000' },
			{ value: 'D0D0D0' },
			{ value: '6495ED' },
			{ value: '009688' },
			{ value: '00ACC1' },
			{ value: '87CEEB' },
			{ value: 'AEC6CF' },
			{ value: 'C2B280' },
			{ value: 'FFD740' },
			{ value: 'FF1744' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
	},
	'marble elegance': {
		origin: 'marble elegance',
		shapes: [9, 5],
		colors: [
			{ value: '000000' },
			{ value: 'D0D0D0' },
			{ value: 'AEC6CF' },
			{ value: '5D4037' },
			{ value: 'C4A69F' },
			{ value: 'D7B29A' },
			{ value: 'E6D0B5' },
			{ value: 'D9C8A1' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
		],
		fonts: [
			{ name: 'Nunito', value: 'Nunito' },
			{ name: 'Comfortaa', value: 'Comfortaa' },
			{ name: 'Bellota', value: 'Bellota' },
			{ name: 'Mulish', value: 'Mulish' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
			{ name: 'Playfair Display', value: 'Playfair Display' },
			{ name: 'Lora', value: 'Lora' },
			{ name: 'Cormorant Infant', value: 'Cormorant Infant' },
		],
		image_data_source: 'marble_elegance',
	},
	'testing purposes': {
		origin: 'testing purposes',
		shapes: [1, 5, 9],
		colors: [
			{ value: 'D0D0D0' },
			{ value: 'AEC6CF' },
			{ value: 'C0D0C0' },
			{ value: 'C0C1D0' },
			{ value: 'D8B4A6' },
			{ value: 'C4A69F' },
			{ value: 'D9C8A1' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
			{ value: '808000' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
		image_data_source: 'sharp_abstract',
	},
	monochrome: {
		origin: 'monochrome',
		shapes: [1],
		colors: [
			{ value: 'D0D0D0' },
			{ value: 'AEC6CF' },
			{ value: 'C0D0C0' },
			{ value: 'C0C1D0' },
			{ value: 'D8B4A6' },
			{ value: 'C4A69F' },
			{ value: 'D9C8A1' },
			{ value: 'C2B280' },
			{ value: 'D5AE41' },
			{ value: '808000' },
		],
		fonts: [
			{ name: 'Open Sans', value: 'Open Sans' },
			{ name: 'Exo 2', value: 'Exo 2' },
			{ name: 'IBM Plex Sans', value: 'IBM Plex Sans' },
			{ name: 'Montserrat', value: 'Montserrat' },
			{ name: 'Oswald', value: 'Oswald' },
			{ name: 'Play', value: 'Play' },
			{ name: 'Roboto', value: 'Roboto' },
			{ name: 'Noto Sans', value: 'Noto Sans' },
			{ name: 'Advent Pro', value: 'Advent Pro' },
			{ name: 'Sofia Sans', value: 'Sofia Sans' },
			{ name: 'Jost', value: 'Jost' },
			{ name: 'Alumni Sans', value: 'Alumni Sans' },
			{ name: 'Ubuntu', value: 'Ubuntu' },
		],
		image_data_source: 'default',
		fx: 'gray',
	},
}

const themeColors = [
	'800080',
	'808000',
	'000000',
	'009688',
	'6495ed',
	'87cefa',
	'ff1744',
	'ffa500',
	'ffd740',
	'd1bf7b',
	'ffdab9',
	'00008b',
	'00bfae',
	'7ccd7c',
	'b03a7b',
	'ff69b4',
	'ff0000',
	'6a1b9a',
	'0000ff',
	'3b8dff',
	'00bfff',
	'c0c0c0',
	'ff7f50',
	'ffea00',
	'd2b48c',
	'546e7a',
	'3949ab',
	'03a9f4',
	'9c27b0',
	'd32f2f',
	'ff6f00',
	'ffeb3b',
	'4caf50',
	'5d4037',
	'6b8e23',
	'2e8b57',
	'00acc1',
	'c0d9af',
	'f0e68c',
	'd5c4a1',
	'87ceeb',
	'd3d3d3',
	'7b1fa2',
	'8b0000',
	'c71585',
	'ff4081',
	'ffb6c1',
	'ffd700',
	'673ab7',
	'a52a2a',
]

export { assetThemes, themeColors }
