import React, { FC } from 'react';
import { Tariffs } from '../../../pricing/tariffs';
import { useAppSelector } from "../../../hooks/use-store";
import { TrialType } from "../../../types/trial.type";
import {useOutletContext} from "react-router";
import {CommonOutletContext} from "../../../pages/common.outlet";

type TrialPopupProps = {
    close: () => void;
    type: TrialType;
    context: any;
    onClickDownload: () => void;
}

const TrialPopup: FC<TrialPopupProps> = (props) => {
    const {close, type, context, onClickDownload} = props;
    const { isGlobalRequest } = useAppSelector((state) => state.commonState);
    const { TagManager } = useOutletContext<CommonOutletContext>();

    const onClose = () => {
        let popupType = '';
        if (type === 'download trial' || type === 'download expired') popupType = 'download_trial_popup';
        if (type === 'logo') popupType = 'logo_trial_popup';
        if (type === 'style') popupType = 'style_trial_popup';
        if (type === 'image' || type === 'upgrade') popupType = 'slide_edit_trial_popup';
        if (type === 'trial limit') popupType = 'projects_trial_popup';
        // @ts-ignore
        amplitude.getInstance().logEvent('trial popup close', {
            'popup name': popupType === 'slide_edit_trial_popup' ? 'style_trial_popup' : popupType,
        });
        TagManager.dataLayer({
            dataLayer: {
                event : 'trial_popup_close',
                popup_name : popupType
            }
        });
        close?.();
    }

    return(
        <div className="modal modal-trial backdrop-blur-sm ">
            <div className="modal_overlay" onClick={onClose}></div>
            <div className="modal_window">
                <div className="modal_close" onClick={onClose}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z" fill="#6E718F"/></svg>
                </div>

                <div className={'modal-trial_header' + (isGlobalRequest ? ' disabled ' : '')}>
                    {
                        type === 'download trial' &&
                        <>
                            <div className="modal-trial_title">
                                {
                                    !isGlobalRequest &&
                                    <>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31.5801 18.5202C30.8518 12.7835 25.9384 8.3335 20.0068 8.3335C15.4134 8.3335 11.4234 11.0185 9.60175 15.2502C6.02175 16.3202 3.34009 19.7002 3.34009 23.3335C3.34009 27.9285 7.07842 31.6668 11.6734 31.6668H13.3401V28.3335H11.6734C8.91675 28.3335 6.67342 26.0902 6.67342 23.3335C6.67342 20.9935 8.67175 18.7385 11.1284 18.3068L12.0968 18.1368L12.4168 17.2068C13.5884 13.7885 16.4968 11.6668 20.0068 11.6668C24.6018 11.6668 28.3401 15.4052 28.3401 20.0002V21.6668H30.0068C31.8451 21.6668 33.3401 23.1618 33.3401 25.0002C33.3401 26.8385 31.8451 28.3335 30.0068 28.3335H26.6734V31.6668H30.0068C33.6834 31.6668 36.6734 28.6768 36.6734 25.0002C36.6714 23.506 36.1686 22.0557 35.2453 20.881C34.322 19.7063 33.0315 18.8751 31.5801 18.5202Z" fill="#6E718F"/>
                                            <path d="M21.6734 23.3332V16.6665H18.3401V23.3332H13.3401L20.0068 31.6665L26.6734 23.3332H21.6734Z" fill="#6E718F"/>
                                        </svg>
                                        Upgrade to download
                                    </>
                                }
                                {/*{*/}
                                {/*    isGlobalRequest &&*/}
                                {/*    <>*/}
                                {/*        <img width="40" height="40" className="modal-trial__loading" src="/static/i/gif/processing_icon7.gif" />*/}
                                {/*        Upgrade to download*/}
                                {/*    </>*/}
                                {/*}*/}
                                {
                                    isGlobalRequest &&
                                    <>
                                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.5002 3.3335C11.3102 3.3335 3.8335 10.8102 3.8335 20.0002C3.8335 29.1902 11.3102 36.6668 20.5002 36.6668C29.6902 36.6668 37.1668 29.1902 37.1668 20.0002C37.1668 10.8102 29.6902 3.3335 20.5002 3.3335ZM20.5002 33.3335C13.1485 33.3335 7.16683 27.3518 7.16683 20.0002C7.16683 12.6485 13.1485 6.66683 20.5002 6.66683C27.8518 6.66683 33.8335 12.6485 33.8335 20.0002C33.8335 27.3518 27.8518 33.3335 20.5002 33.3335Z" fill="#6E718F"/>
                                            <path d="M17.165 22.6451L13.3333 18.8201L10.98 21.1801L17.1683 27.3551L28.345 16.1784L25.9883 13.8218L17.165 22.6451Z" fill="#6E718F"/>
                                        </svg>
                                        Downloaded 3 slides out of 10
                                    </>
                                }
                            </div>
                            <div className="modal-trial_text">
                                <p>During the trial period, your download is limited to 3 slides.</p>
                                <p>To download the full presentation, please purchase any plan.</p>
                            </div>
                            <div className={'modal-trial_btn btn-outlined' + (isGlobalRequest ? ' modal-trial_btn-preparing animate-pulse opacity-80 hover:opacity-80' : '')} onClick={() => onClickDownload()}>
                                {
                                    isGlobalRequest &&
                                    <>
                                        <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 22C17.421 22 22 17.421 22 12H20C20 16.337 16.337 20 12 20C7.663 20 4 16.337 4 12C4 7.664 7.663 4 12 4V2C6.579 2 2 6.58 2 12C2 17.421 6.579 22 12 22Z" fill="#008A65"/>
                                        </svg>
                                        Preparing your file
                                    </>
                                }
                                {
                                    !isGlobalRequest &&
                                    <>Download</>
                                }
                            </div>
                        </>
                    }
                    {
                        type === 'download expired' &&
                        <>
                            <div className="modal-trial_title">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.5801 18.5202C30.8518 12.7835 25.9384 8.3335 20.0068 8.3335C15.4134 8.3335 11.4234 11.0185 9.60175 15.2502C6.02175 16.3202 3.34009 19.7002 3.34009 23.3335C3.34009 27.9285 7.07842 31.6668 11.6734 31.6668H13.3401V28.3335H11.6734C8.91675 28.3335 6.67342 26.0902 6.67342 23.3335C6.67342 20.9935 8.67175 18.7385 11.1284 18.3068L12.0968 18.1368L12.4168 17.2068C13.5884 13.7885 16.4968 11.6668 20.0068 11.6668C24.6018 11.6668 28.3401 15.4052 28.3401 20.0002V21.6668H30.0068C31.8451 21.6668 33.3401 23.1618 33.3401 25.0002C33.3401 26.8385 31.8451 28.3335 30.0068 28.3335H26.6734V31.6668H30.0068C33.6834 31.6668 36.6734 28.6768 36.6734 25.0002C36.6714 23.506 36.1686 22.0557 35.2453 20.881C34.322 19.7063 33.0315 18.8751 31.5801 18.5202Z" fill="#6E718F"/>
                                    <path d="M21.6734 23.3332V16.6665H18.3401V23.3332H13.3401L20.0068 31.6665L26.6734 23.3332H21.6734Z" fill="#6E718F"/>
                                </svg>
                                Upgrade to download
                            </div>
                            <div className="modal-trial_subtitle">
                                <p>Sorry, but you cannot download the presentation on your current plan.</p>
                                <p>To download the full presentation, please purchase any plan.</p>
                            </div>
                        </>
                    }
                    {
                        type === 'upgrade' &&
                        <>
                            <div className="modal-trial_title">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.8667 4.46666C32.9956 4.04761 31.0841 3.83523 29.1667 3.83333C27.3558 3.71835 25.5401 3.96249 23.8239 4.55171C22.1078 5.14093 20.525 6.0636 19.1667 7.26666C17.3778 9.05555 15.6056 10.8389 13.85 12.6167C11.8333 12.4 7.05001 12.2833 3.85001 15.5167C3.53959 15.8289 3.36536 16.2514 3.36536 16.6917C3.36536 17.132 3.53959 17.5544 3.85001 17.8667L22.6833 36.7333C22.9956 37.0437 23.418 37.218 23.8583 37.218C24.2987 37.218 24.7211 37.0437 25.0333 36.7333C28.2833 33.4 28.1833 28.7 27.9833 26.7333L33.3333 21.4C38.65 16.0833 36.2333 6.1 36.1333 5.68333C36.0564 5.38272 35.8971 5.10956 35.6733 4.89461C35.4495 4.67966 35.1701 4.53144 34.8667 4.46666ZM30.9333 19.05L25 24.95C24.8079 25.144 24.6663 25.3823 24.5879 25.6439C24.5094 25.9054 24.4964 26.1822 24.55 26.45C24.9101 28.6797 24.5967 30.9661 23.65 33.0167L7.53334 16.8667C9.61643 15.9079 11.945 15.6169 14.2 16.0333C14.4668 16.0681 14.738 16.0377 14.9905 15.9448C15.243 15.852 15.4693 15.6993 15.65 15.5C15.65 15.5 17.9833 13.0833 21.5 9.56666C23.6624 7.83019 26.4001 6.97315 29.1667 7.16666C30.468 7.17311 31.7666 7.28458 33.05 7.5C33.45 9.88333 34.0833 15.9 30.9333 19.05Z" fill="#6E718F"/>
                                    <path d="M26.2167 17.6667C28.0576 17.6667 29.55 16.1743 29.55 14.3333C29.55 12.4924 28.0576 11 26.2167 11C24.3757 11 22.8834 12.4924 22.8834 14.3333C22.8834 16.1743 24.3757 17.6667 26.2167 17.6667Z" fill="#6E718F"/>
                                    <path d="M8.33333 27.167C5 28.8337 5 35.5003 5 35.5003C8.08511 35.4123 11.0386 34.2309 13.3333 32.167L8.33333 27.167Z" fill="#6E718F"/>
                                </svg>
                                Upgrade your plan
                            </div>
                            <div className="modal-trial_text">
                                <p>And unlock the full potential of Wonderslide</p>
                            </div>
                        </>
                    }
                    {
                        (type === 'logo' || type === 'image' || type === 'style') &&
                        <>
                            <div className="modal-trial_title">
                                <svg width="27" height="34" viewBox="0 0 27 34" fill="none">
                                    <path d="M13.5 0.333008C8.90502 0.333008 5.16669 4.07134 5.16669 8.66634V13.6663H3.50002C1.66169 13.6663 0.166687 15.1613 0.166687 16.9997V30.333C0.166687 32.1713 1.66169 33.6663 3.50002 33.6663H23.5C25.3384 33.6663 26.8334 32.1713 26.8334 30.333V16.9997C26.8334 15.1613 25.3384 13.6663 23.5 13.6663H21.8334V8.66634C21.8334 4.07134 18.095 0.333008 13.5 0.333008ZM23.5 16.9997L23.5034 30.333H3.50002V16.9997H23.5ZM8.50002 13.6663V8.66634C8.50002 5.90967 10.7434 3.66634 13.5 3.66634C16.2567 3.66634 18.5 5.90967 18.5 8.66634V13.6663H8.50002Z" fill="#6E718F"/>
                                </svg>
                                Feature is locked
                            </div>
                            <div className="modal-trial_text">
                                <p>Sorry, but you cannot use this feature on your current plan.</p>
                                <p>Purchase one of our plans to unlock all features</p>
                            </div>
                        </>
                    }
                    {
                        (type === 'trial limit') &&
                        <>
                            <div className="modal-trial_title">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.8667 4.46666C32.9956 4.04761 31.0841 3.83523 29.1667 3.83333C27.3558 3.71835 25.5401 3.96249 23.8239 4.55171C22.1078 5.14093 20.525 6.0636 19.1667 7.26666C17.3778 9.05555 15.6056 10.8389 13.85 12.6167C11.8333 12.4 7.05001 12.2833 3.85001 15.5167C3.53959 15.8289 3.36536 16.2514 3.36536 16.6917C3.36536 17.132 3.53959 17.5544 3.85001 17.8667L22.6833 36.7333C22.9956 37.0437 23.418 37.218 23.8583 37.218C24.2987 37.218 24.7211 37.0437 25.0333 36.7333C28.2833 33.4 28.1833 28.7 27.9833 26.7333L33.3333 21.4C38.65 16.0833 36.2333 6.1 36.1333 5.68333C36.0564 5.38272 35.8971 5.10956 35.6733 4.89461C35.4495 4.67966 35.1701 4.53144 34.8667 4.46666ZM30.9333 19.05L25 24.95C24.8079 25.144 24.6663 25.3823 24.5879 25.6439C24.5094 25.9054 24.4964 26.1822 24.55 26.45C24.9101 28.6797 24.5967 30.9661 23.65 33.0167L7.53334 16.8667C9.61643 15.9079 11.945 15.6169 14.2 16.0333C14.4668 16.0681 14.738 16.0377 14.9905 15.9448C15.243 15.852 15.4693 15.6993 15.65 15.5C15.65 15.5 17.9833 13.0833 21.5 9.56666C23.6624 7.83019 26.4001 6.97315 29.1667 7.16666C30.468 7.17311 31.7666 7.28458 33.05 7.5C33.45 9.88333 34.0833 15.9 30.9333 19.05Z" fill="#6E718F"/>
                                    <path d="M26.2167 17.6667C28.0576 17.6667 29.55 16.1743 29.55 14.3333C29.55 12.4924 28.0576 11 26.2167 11C24.3757 11 22.8834 12.4924 22.8834 14.3333C22.8834 16.1743 24.3757 17.6667 26.2167 17.6667Z" fill="#6E718F"/>
                                    <path d="M8.33333 27.167C5 28.8337 5 35.5003 5 35.5003C8.08511 35.4123 11.0386 34.2309 13.3333 32.167L8.33333 27.167Z" fill="#6E718F"/>
                                </svg>
                                Upgrade your plan
                            </div>
                            <div className="modal-trial_text">
                                <p>You have reached the presentation creation limit.</p>
                                <p>Upgrade to one of our plans to create an unlimited number of&nbsp;presentations.</p>
                            </div>
                        </>
                    }
                </div>

                <div className="about8_items">
                    <Tariffs context={context} page={`popup ${type}`} />
                </div>
            </div>
        </div>
    );
}

export default TrialPopup;