import React, {useRef, useState} from "react";
import cl from 'classnames';

const sliderFiles = {
    // 'classic': [1,2,3,4,5,6,7],
    'geometric': [1,2,3,4,5,6,7],
    'flat bubble': [1,2,3,4,5,6,7,8],
    'rigid square': [1,2,3,4,5,6,7,8,9],
    'bold circle': [1,2,3,4,5,6,7,8],
    'angular edge':[1,2,3,4,5,6,7],
    'spark pulse':[1,2,3,4,5],
    'heart flare':[1,2,3,4,5,6,7],
    'eco leaf':[1,2,3,4,5,6,7],
    'waves':[1,2,3,4,5,6,7],
    'scandic': [1,2,3,4,5],
    'nordic essence':[1,2,3,4,5,6,7],
    'simple': [1,2,3,4,5,6,7],
    'marble elegance': [1,2,3,4,5],
};

const ThemeItem = (props) => {
    const { theme, imgs, isActive, select, inactive } = props;
    const [slide, setSlide] = useState(0);
    const [shift, setShift] = useState(0);
    const itemRef = useRef(null);

    const move = dir => {
        const lastSlide = sliderFiles[theme].length - 1;
        let newSlide = 0;
        if (dir === 'left') {
            newSlide = slide - 1;
            if (newSlide < 0) newSlide = lastSlide;
        }
        if (dir === 'right') {
            newSlide = slide + 1;
            if (newSlide > lastSlide) newSlide = 0;
        }
        setSlide(newSlide);
        setShift(newSlide * (itemRef.current.clientWidth + 15));
    }

    return(
        <div className={cl('gen_styles_item cursor-pointer', isActive && 'active', inactive && 'inactive' )} id={theme === 'Coming soon' ? '' : theme.toLowerCase()} onClick={select} ref={itemRef}>
            <div className="gen_styles_item__imgs">
                <div className="gen_styles_item__imgs_inner" style={{transform: `translateX(-${shift}px)`}}>
                    {imgs && imgs.map((img, i) => (
                        <img src={'/static/i/styles/' + img + '.webp?1'} key={i} />
                    ))}
                    {sliderFiles[theme] && sliderFiles[theme].map(i => (
                        <img src={'/static/i/styles/' + theme.replaceAll(' ', '_') + i + '.webp?1'} key={i} />
                    ))}
                </div>
                {
                    (imgs && imgs.length > 1 || sliderFiles[theme] && sliderFiles[theme].length > 1) &&
                    <>
                        <div className="gen_styles_item__arr left" onClick={(e) => {move('left'); e.stopPropagation()}}>{svgIcons.sliderArrLeft}</div>
                        <div className="gen_styles_item__arr right" onClick={(e) => {move('right'); e.stopPropagation()}}>{svgIcons.sliderArrRight}</div>
                    </>
                }
            </div>
            <div className="gen_styles_item__name">
                {theme === 'classic' ? 'Original Formula' : theme}
                {theme.match(/nordic essence|simple|marble elegance/) && <div className="beta">new</div>}</div>
        </div>
    );
}


const svgIcons = {
    sliderArrRight: <svg width="29" height="36" viewBox="0 0 29 36" fill="none"> <g filter="url(#filter0_d_7200_13566)"> <path d="M10.8449 25.5117L20.3566 16L10.8449 6.48834L8.48828 8.84501L15.6433 16L8.48828 23.155L10.8449 25.5117Z" fill="#F8F8FB"/> </g> <defs> <filter id="filter0_d_7200_13566" x="0.488281" y="0.488342" width="27.8691" height="35.0233" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="4"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7200_13566"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7200_13566" result="shape"/> </filter> </defs> </svg>,
    sliderArrLeft:  <svg width="29" height="36" viewBox="0 0 29 36" fill="none"> <g filter="url(#filter0_d_7200_13564)"> <path d="M18.1552 6.48834L8.64355 16L18.1552 25.5117L20.5119 23.155L13.3569 16L20.5119 8.84501L18.1552 6.48834Z" fill="#F8F8FB"/> </g> <defs> <filter id="filter0_d_7200_13564" x="0.643555" y="0.488342" width="27.8682" height="35.0233" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="4"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7200_13564"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7200_13564" result="shape"/> </filter> </defs> </svg>
}

export default ThemeItem;