import { createApi } from "@reduxjs/toolkit/query/react";
import { appBaseQueryWithResend } from "./base-query";
import { serverPath } from "../../utils/server-path";


export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: appBaseQueryWithResend,
    tagTypes: ['INIT'],
    endpoints: (build) => ({
        getInit: build.query({
            query: () => ({
                url: serverPath.init,
            })
        })
    }),
});

export const { useGetInitQuery, useLazyGetInitQuery } = userApi;
export default userApi;