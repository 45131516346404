import React from 'react';

import { legalData } from "./features/legal.data";
import LegalLayoutFeature from "./features/legal-layout.feature";
import {Link} from "react-router-dom";
import {route_path} from "../../utils/route_path";

const PrivacyPage = () => {
    return (
        <LegalLayoutFeature {...legalData['privacy']}>
            <h2>PRIVACY POLICY</h2>
                <p>
                    This privacy policy informs you about our online collection and use of data and how
                    we use information that identifies you personally when you use our website,
                    www.wonderslide.com (the “Site” or “Website”).
                </p>
                <p>Effective starting: November 29, 2023</p>
            <h3>What This Privacy Policy Covers</h3>
                <p>
                    Site and services owned and operated by Everypixel Labs Inc., located on 2021
                    Fillmore Street PMB 15, San Francisco, CA 94115 and its affiliates (hereinafter referred
                    to as “Wonderslide”, “we” or “us”).
                </p>
                <p>
                    We collect certain information about you which enables us to do business with you
                    and improve our services. Your privacy is very important to us. And in order to
                    protect it we have adopted this Privacy Policy which applies to any and all personal
                    information you submit to us in connection with your access to and/or use of our
                    website: <a href="https://wonderslide.com" target="_blank">wonderslide.com</a>.
                </p>
                <p>
                    We offer a wide range of services: Wonderslide is an online automatic (AI-driven)
                    presentation design service. Our users can upload their own presentation file in .pptx
                    format and download back the formatted file, as if the designer did this work (referred
                    to herein as "Services").
                </p>
                <p>
                    This Privacy Policy also covers the options you have regarding how we use the
                    provided information about you. These options include: how you can object to certain
                    uses of your personal information and how you can access certain information which
                    you wish to update. <strong>If you do not agree to abide by this Privacy Policy, please do
                    not access or use our Services or interact with any other aspect of our
                    business.</strong>
                </p>
            <h3>What Information We Collect About You</h3>
                <p>
                    We shall collect information about you that you provide to us while using our
                    Services, and when other third parties provide it to us, as further described below.
                </p>
                <h4>Information That You Provide To Us</h4>
                    <p>
                        We shall collect information about you when you input it into the Services or
                        otherwise provide it directly to us.
                    </p>
                <h4>Device and Connection Information</h4>
                    <p>
                        Whenever you access the Website, some information may be collected, e.g. your IP,
                        URLs of referring/exit pages, device identifiers, crash data, email address, and
                        navigational information. In addition to log data, we may also collect information
                        about the device you’re using Wonderslide on, including its type, operating system,
                        settings, unique identifiers, and crash data. Whether we collect any or all of this
                        information often depends on what type of device you’re using and its settings. For
                        example, different types of information are available depending on whether you’re
                        using a Mac or a PC, or an iPhone or an Android phone. To learn more about what
                        information your device makes available to us, please check the policies of your
                        device manufacturer or software provider.
                    </p>
            <h4>Account and Profile Information</h4>
                    <p>
                        Your email address, password, and other information shall be collected when you
                        register online with our Services. If you Sign up or Log in to the Service using a third
                        party, for example, using the "Sign up with Google" button, we also store information
                        obtained from these services.
                    </p>
                    <p>
                        We may occasionally ask questions about your preferences as a user in order to
                        best serve you and improve the use of our website. Providing information regarding
                        your usage and preferences is always voluntary. For improving the service and
                        developing new features we (including the involvement of third parties) may use the
                        user's presentations and passed data for presentation, for correct and reliable
                        operation.
                    </p>
                    <p>
                        However, if you choose not to provide the information, some products and services
                        may not be available to you. We may use your email to contact you regarding our
                        services, products and promotional offers for direct marketing purposes.
                    </p>
            <h4>Information You Provide Through Our Support Channels</h4>
                    <p>
                        The Services also include our customer support, where you may choose to submit
                        information regarding any problems you may experience while using our Services.
                        Whether you designate yourself as a technical contact, open a support ticket, speak
                        to one of our representatives directly or otherwise engage with our support team, you
                        will be asked to provide contact information, a summary of the problem you are
                        experiencing, and any other documentation, screenshots or details that would be
                        helpful in resolving the issue. In the event of problems with the Site operation,
                        employees or contractors may have access to user data.
                    </p>
                    <p>
                        In personal account you have the option to let us know in advance if you wish not to
                        store your data on Site
                    </p>
            <h4>Payment Information</h4>
                    <p>
                        We shall collect certain payment and billing information when you register for certain
                        paid Services. For example, we ask you to designate a billing representative,
                        including name and contact information, upon registration. You might also provide
                        payment information, such as card details, which we collect via secure payment
                        processing services.
                    </p>
            <h4>Your Use of the Services</h4>
                    <p>
                        We keep track of certain information about you when you visit and interact with any
                        of our Services. This information includes the features you use; the links you click
                        on; the type, size, content and filenames of attachments you upload to the Services;
                        frequently used search keywords; and how you interact with other users on the
                        Services. We also collect information about the teams and people you work with and
                        how you work with them, e.g. who you collaborate with and communicate with most
                        frequently.
                    </p>
            <h4>About Cookies</h4>
                    <p>
                        Whenever you access the Website, a text file called a cookie is placed in the
                        browser directory of your computer. A cookie is information that a website can store
                        on your web browser and later retrieve. The information that cookies collect includes
                        the date and time of your visit, your registration information, your navigational and
                        licensing information. It allows the web browser to recognize the pages you have
                        been to while you are visiting the Website, and allows you to quickly return to viewed
                        pages.
                    </p>
                <h5>We may also use "web beacons" that monitor your use of our Websites.</h5>
                    <p>
                        Web beacons are small strings of code that provide a method for delivering a graphic
                        image on a web page for the purpose of transferring data, such as the IP address of
                        the computer that downloads the page on which the web beacon appears, the URL
                        (Uniform Resource Locator) of the page on which the web beacon appears, the time
                        the page containing the web beacon is viewed, the types of browser that fetch the
                        web beacon, and the identification number of any cookie on the computer previously
                        placed by that server. When corresponding with you via HTML capable e-mail, web
                        beacons let us know whether you received and opened our e-mail. On their own,
                        cookies or web beacons do not contain or reveal any personally identifiable
                        information. However, if you choose to provide personally identifiable information,
                        this information can be linked to the data stored in the cookies/web beacons.
                    </p>
                    <p>
                        By accepting this Privacy Policy, you specifically agree to our use of cookies and
                        web beacons as described herein. You may adjust your browser to reject cookies
                        from us or from any other website. Additionally, by setting your web browser to
                        display HTML emails as text only, you may be able to prevent the use of some web
                        beacons. Please check the "Help" section of your browser for more information.
                        However, before you do so, please note that deleting cookies or similar software may
                        limit your ability to use some portions of our Site.
                    </p>
                    <p>
                        You can find more information about the cookies in our <Link to={route_path.legal_cookie}>Cookie policy</Link>.
                    </p>
            <h4>Third Parties</h4>
                    <p>
                        We receive information about you and your activities on and off the Services from
                        third-party partners, such as advertising and market research partners who provide
                        us with information about your interest in and engagement with our Services and
                        online advertisements.
                    </p>
            <h3>How We Use Information We Collect</h3>
                <p>
                    We may use the information we collect for a number of purposes. How we use the
                    information we collect depends in part on which Services you use, how you use
                    them, and any preferences which you have communicated to us. These specific
                    purposes are as follows.
                </p>
                <h4>To Provide the Services and Personalize Your Experience</h4>
                    <p>
                        We use information about you to provide the Services to you, including to process
                        transactions with you, authenticate you when you log in, provide customer support,
                        and operate and maintain the Services.
                    </p>
                    <p>
                        Our Services also include tailored features that personalize your experience,
                        enhance your productivity, and improve your ability to collaborate effectively with
                        others by automatically analyzing your team’s activities to provide search results,
                        activity feeds, notifications, connections, and recommendations that are most
                        relevant for you and your team. Whenever you use the multiple Services, we
                        combine information about you and your activities to provide an integrated
                        experience.
                    </p>
                <h4>For Research and Development Purposes</h4>
                    <p>
                        We use collective learning about users’ behavior on our Services and direct
                        feedback to troubleshoot and to identify trends, usage, activity patterns, and aspects
                        of the Services to integrate and to improve the Services. We automatically analyze
                        and aggregate content from uploaded files to improve the accuracy and relevance of
                        suggested designs when you use the Site.
                    </p>
                <h4>For Communication Purposes</h4>
                    <p>
                        We shall use your contact information to send transactional communications via
                        email and within the Services, i.e. to remind you of subscription expirations, to
                        respond to your comments, questions and requests, to provide customer support,
                        and to forward you technical notices, updates, security alerts, and administrative
                        messages.
                    </p>
                <h4>Customer Support</h4>
                    <p>
                        We use your information to resolve any technical issues you encounter, to respond
                        to your requests for assistance, to analyze crash information, and to maintain and
                        improve the Services.
                    </p>
                <h4>To Protect Our Interests And Legal Rights</h4>
                    <p>
                        We shall disclose the information to the extent required by law including legal claims,
                        compliance, regulatory, and audit, or otherwise we believe required to protect our
                        legal rights, interests and the interest of third parties, including without limitation
                        disclosure obligations upon the business acquisition, merger or sale.
                    </p>
                <h4>Your Consent</h4>
                    <p>
                        We use information about you where you have given us consent to do so for a
                        specific purpose not listed above. For example, we may publish testimonials or
                        featured customer stories to promote the Services, provided that we received your
                        prior permission.
                    </p>
                <h4>Legal Basis for Processing Information (for EEA Users)</h4>
                    <p>
                        If you are an individual in the European Economic Area (EEA), we shall collect and
                        process information about you only where we have legal basis for such actions
                        under applicable EU laws. The legal basis depends on the Services you use and
                        how you use them. This means we shall collect and use your information only when:
                    </p>
            <ul>
                <li>
                    ● We need it to provide you with the Services, including to operate the
                        Services, provide customer support and personalized features and to ensure
                        the safety and security of the Services;
                </li>
                <li>
                 ● It satisfies a legitimate interest (which is not overridden by your data
                    protection interests), such as for research and development, to market and
                    promote the Services, and to protect our legal rights and interests;
                </li>
                <li>
                    ● You have given us prior permission for a specific purpose; or
                </li>
                <li>
                    ● We need it to disclose your personal information to the extent required by
                        law.
                </li>
            </ul>
            <p>
                If you have given us prior permission to use your personal information, you are
                entitled to withdraw this permission at any time; however, it will not affect any
                processing that has already been started.
            </p>
            <h4>How We Share Information We Collect</h4>
            <p>
                We create collaboration tools to facilitate your work and we wish them to aid you as
                best as possible. This implies sharing information through the Services with certain
                third parties. We share information we collect about you the following ways,
                including: in connection with possible business transfers, but we are not in the
                business of selling information about you to advertisers or other third parties.
            </p>
            <h4>Sharing Information with Other Service Users</h4>
            <p>When you use the Services, we share certain information about you with other
                Service users.</p>
            <h4>Sharing Information with Third Parties</h4>
            <p>
                We don’t share your personal information with any unrelated third parties. But we
                may use third parties to outsource one or more aspects of our business (such as
                operating, providing, improving, integrating, customizing, supporting, and marketing
                our Services). We reserve the right to disclose to such third parties the information
                necessary to provide such services.</p>
            <h4>Sharing Information with Affiliated Companies</h4>
            <p>
                We share information we collect with affiliated companies and, in some cases, with
                prospective affiliates. Affiliated companies are companies owned or operated by us.
                The protection of this privacy policy applies to the information we share in these
                circumstances.
            </p>
            <h4>Sharing Information with Artificial Intelligence systems from
                OpenAI, Microsoft and Google (AI)</h4>
            <p>
                We might share information we collect with AI. In order to provide better services on
                our Site, we use AI from OpenAI, Microsoft and Google.
            </p>
            <p>
                The information you provide to us may be sent to the AI which has its own privacy
                policy. The AI terms of use are located here:
            </p>
            <ul>
                <li><a href="https://openai.com/policies" target="_blank" rel="nofollow">1. https://openai.com/policies</a></li>
                <li><a href="https://azure.microsoft.com/en-us/support/legal/" target="_blank" rel="nofollow">2. https://azure.microsoft.com/en-us/support/legal/</a></li>
                <li><a href="https://developers.google.com/terms" target="_blank" rel="nofollow">3. https://developers.google.com/terms</a></li>
            </ul>
            <h4>Service Providers</h4>
            <p>
                We work with third parties that provide us with certain services including but not
                limited to website and application development, hosting, maintenance, backup,
                storage, virtual infrastructure, payment processing, analysis, and other services,
                which may require them to access or use information about you. If a service provider
                needs to access your personal information to perform services on our behalf, they do
                so under close instruction from us, including policies and procedures designed to
                protect your confidential information.
            </p>
            <h4>Links to Third Party Sites</h4>
            <p>
                The Services may include links to third party websites or services. Third parties may
                have different privacy policies. If you submit any information to any third party
                websites, your information is governed by their privacy policies. Wonderslide is not
                responsible for such privacy policies, you do so at your own risk.
            </p>
            <h4>
                Compliance with Enforcement Requests and Applicable Laws;
                Enforcement of Our Rights
            </h4>
            <p>
                In exceptional circumstances, we may disclose information about you to a third party
                if such disclosure deems reasonable, appropriate or necessary to (a) comply with
                any applicable law, regulation, legal process or governmental request, including to
                meet national security requirements, (b) enforce our agreements, policies and terms
                of service, (c) protect the security or integrity of our products and services.
            </p>
        <h3>How We Store and Secure Information We Collect</h3>
            <h4>Data Security</h4>
            <p>
                Whenever you request to Wonderslide, you can do so over a secure (i.e. encrypted)
                connection to ensure the entire security of your personal information.
            </p>
            <p>
                In the unlikely event that an unauthorized third party compromises Everypixel’s
                security measures, Wonderslide will not be responsible for any damages directly or
                indirectly caused by an unauthorized third party’s ability to view, use, or disseminate
                your information.
            </p>
            <p>
                Reviewing, Updating or Correcting Your Information. If at any point you wish to
                access your personal information to change your preferences, review the accuracy,
                correct, supplement or modify your information, you may either sign in your account,
                or make a written request to <a href="mailto:support@wonderslide.com">support@wonderslide.com</a>.
            </p>
            <h4>How Long We Keep Information</h4>
            <p>
                How long we keep the personal information we collect depends on the type of
                information, as described in further detail below. After such time, we will either delete
                or anonymize your information or, if this is not possible (for example, because the
                information has been stored in backup archives), then we shall securely store your
                information and isolate it from any further use until deletion is possible.
            </p>
            <h4>Account Information</h4>
                <p>
                    We retain your account information for as long as your account is active and a
                    reasonable period thereafter in case you decide to re-activate the Services. We also
                    retain some of your information as necessary to comply with our legal obligations, to
                    resolve disputes, to enforce our agreements, to support business operations, and to
                    continue to develop and improve our Services. Whenever we retain information for
                    Service improvement and development, we take steps to eliminate information that
                    directly identifies you, and we only use the information to uncover collective insights
                    about the use of our Services, not to specifically analyze your personal
                    characteristics.
                </p>
            <h4>Marketing Information</h4>
                <p>
                    If you have signed up to receive our newsletters, we retain information about your
                    marketing preferences for a reasonable period of time from the date you last
                    expressed interest in our Services. We retain information derived from cookies and
                    other tracking software for a reasonable period of time from the date such
                    information was created.
                </p>
            <h3>Access to Your Information</h3>
                <p>
                    We keep your personal data only for as long as we need to. This depends on why it
                    was collected, or if we have a continuing legal basis to do so (such as to fulfill a
                    contract between us, perform a service you requested or for our legitimate interests).
                    Rest assured, if we no longer have a reason or legal requirement to process your
                    personal information, we will delete it or store it in a way so that it no longer identifies
                    you.
                </p>
                <p>
                    We have different retention policies for different types of personal data taking into
                    account:
                </p>
            <ul>
                <li>● The purpose for collecting the personal data;</li>
                <li>● How long it will take to fulfill that purpose; and</li>
                <li>● Any specific reason or overriding legal obligation to retain the personal data
                        for a specific amount of time.
                </li>
            </ul>
            <p>
                Also, if you have an account with us, we will keep personal data such as your email
                address, name and other details so you can log in and access our services for as
                long as you have an account. We may keep other information indefinitely – such as
                website activity linked to an IP address – which we use to understand our users,
                improve our products and services, and protect our business interests.
            </p>
            <p>We may also delete User Content, which may include personal data.</p>
            <p>
                <strong>
                    No matter how long the retention period is, you can ask us to delete your
                    personal data in certain circumstances if you make a request to the address {' '}
                    <a href="mailto:support@wonderslide.com">support@wonderslide.com</a>.
                </strong>
            </p>
            <h4>Access and Update Your Information</h4>
            <p>
                Our Services enable you to access and update certain personal information within
                the Services. You can update your profile information within your profile settings and
                modify content that contains your personal information using editing tools associated
                with that content.
            </p>
            <h4>Access to Your Google Data</h4>
            <p>
                We may use your Google data for registration and authorization (login) to our
                service. We may also send you emails to the email address specified in the data.
            </p>
            <p>
                At the same time, you may refuse to receive informational emails at the moment of
                registering or on the Settings screen of your Account.
            </p>
            <h4>Deactivate Your Account</h4>
            <p>
                If you no longer wish to use our Services, you or your administrator may be able to
                deactivate your account.
            </p>
            <h4>Request Us To Stop Using Your Information</h4>
            <p>
                In some cases, you may ask us to stop accessing, storing, using or otherwise
                processing your information if you believe we don't have the appropriate rights to do
                so. You can also opt out of our use of your information for marketing purposes by
                contacting us, as provided below. When you make such requests, we may need time
                to investigate and facilitate your request. If there is a delay or dispute about whether
                we have the right to continue using your information, we will restrict any further use
                of your information until the request is honored or the dispute is resolved, provided
                your administrator does not object (where applicable).
            </p>
            <h4>Opt Out of Communications</h4>
            <p>
                You may opt out of receiving promotional communications from us by using the
                unsubscribe link within each email, updating your email preferences within your
                Service account settings menu, or by contacting us as provided below to have your
                contact information removed from our promotional email list or registration database.
            </p>
            <h3>How We Transfer Information We collect</h3>
            <h4>Internationally International Transfers of Information We Collect</h4>
            <p>
                We collect information globally and primarily store that information in Germany. We
                transfer, process, and store your information outside of your country of residence, to
                wherever we or our third-party service providers operate for the purpose of providing
                you with the Services. Whenever we transfer your information, we take steps to
                protect it.
            </p>
            <h3>How We Protect Your Personal Information</h3>
            <p>
                Whenever you request to Wonderslide, you can do so over a secure (i.e. encrypted)
                connection to ensure that your personal information is not at risk. In the unlikely
                event that an unauthorized third-party compromises Wonderslide’s security
                measures, we will not be responsible for any damages directly or indirectly caused
                by an unauthorized third party’s ability to view, use, or disseminate your information.</p>
            <p>
                In any case, we urge you to be careful and attentive to the data you transmit via the
                Internet. We will protect any information about you, however, we are not protected
                from misconduct against Wonderslide and cannot guarantee unlimited protection. In
                the event of hacks and hacker attacks against us, we do not guarantee the security
                of your data.
            </p>
            <h3>Children’s Privacy</h3>
            <p>
                We do not intend to either solicit or knowingly collect personal information from
                children under the age of 13. If you are under 13, please do not access, use, or enter
                information on the Website. If you are under 18 you must have your parent or legal
                guardian’s permission to use the Site. If you are accessing this Site on behalf of any
                entity, you further represent and warrant that you are authorized to accept these
                terms on such entity's behalf, and that such entity agrees to indemnify Wonderslide
                for violations of these Site terms.
            </p>
            <p>
                If You are a parent or guardian and You are aware that Your child has provided Us
                with Personal Data, please contact Us. If We become aware that We have collected
                Personal Data from anyone under the age of 13 without verification of parental
                consent, We take steps to remove that information from Our servers.
            </p>
            <p>
                If We need to rely on consent as a legal basis for processing Your information and
                Your country requires consent from a parent, We may require Your parent's consent
                before We collect and use that information.
            </p>
            <h3>Changes to our Privacy Policy</h3>
            <p>
                We may change the terms of this Privacy Policy at any time. We will post any revised
                privacy policy on this page and, if the changes are significant, we will post a notice
                on the Services homepages, login screens, or inform you via an email notification.
                We will also keep prior versions of this Privacy Policy in an archive for your review.
                We encourage you to review our privacy policy whenever you use the Services to
                stay informed about our information practices and the ways you can help protect
                your privacy.
            </p>
            <p>
                If you disagree with any changes to this privacy policy, you will need to stop using
                the Services and deactivate your account(s), as outlined above.
            </p>
            <h3>Contact Us</h3>
            <p>
                Your information is governed by Everypixel Labs Inc. If you have any questions
                regarding this Privacy Policy, please contact us at <a href="mailto:support@wonderslide.com">support@wonderslide.com</a>.
            </p>
            <p>Everypixel Labs Inc., 2021 Fillmore Street PMB 15, San Francisco, CA 94115</p>
        </LegalLayoutFeature>
    );
};

export default PrivacyPage;