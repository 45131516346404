import React, {useCallback, useEffect, useRef, useState} from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import cl from "classnames";

import t from "../../../../../lib/lng";
import { OnboardingMessage } from "../../../../components/template";
import { assetThemes } from "../../../../data";
import GenSelect from "./gen-select.block";
import { useOutletContext } from "react-router";
import { ONBOARDING_MESSAGE_STYLE, ONBOARDING_TYPE } from "../../data/onboarding.data";
import ColorPicker from "./gen-color-picker.block";
import { useLazyDeletePresetQuery } from "../../../../store/api/preset.api";
import { getRandomInt } from "../../../../../lib/utils";
import { useAppSelector } from "../../../../hooks/use-store";
import useSendOptionsBuild from "../../../../hooks/use-send-options";
import {
    useLazyDeleteLogoQuery,
    useLazyLoadPresentationQuery,
    useUpdatePresentationStyleMutation
} from "../../../../store/api/presentation.api";
import { serverPath } from "../../../../utils/server-path";
import  { useToastContext } from "../../../../hooks/use-toast-provider";
import { shapesSVG } from '../../data/shapes-svg-list.data';

const GEN_STYLE_TEXTS = {
    saveAndContinue: 'Save and continue',
    back: 'Back'
}

const USER_AMPLITUDE_DATA= {
    'event': 'click generation start',
    'style status': 'unchanged',
    'color status': 'unchanged',
    'shape status': 'unchanged',
    'font status': 'unchanged',
    'logo status': 'unchanged',
    'logo': 'no logo',
};
const USER_AMPLITUDE_KEYS = {
    color: 'color status',
    shape: 'shape status',
    font: 'font status',
}

const GenStylePanelBlock = (props) => {
    const { theme, presentationOptions, isLoadingPresentation, isFirstStyleSettings, isPPTXUpload, firstGeneratedSlide } = props;
    const { context, onboardingMessage, updateData, TagManager, setTrialPopup } = useOutletContext();
    const { getToast } = useToastContext();
    const optionsBuilder = useSendOptionsBuild(theme);
    const colorpickerRef = useRef(null);
    const [showColorpicker, setShowColorpicker] = useState(false);
    const [deletingColor, setDeletingColor] = useState(false);
    const [presetColors, setPresetColors] = useState([]);
    const [selectedThemeStyle, setSelectedThemeStyle] = useState({});
    const [uploadLogoList, setUploadLogoList] = useState([]);
    const [userActionsAmplitude, updateUserActionsAmplitude] = useState(USER_AMPLITUDE_DATA);
    const [draftThemeParams, setDraftThemeParams] = useState({});
    const [currentOnboardingStep, changeCurrentOnboardingStep ] = onboardingMessage(ONBOARDING_TYPE);
    const { projectId, fileUpload: { process } } = useAppSelector((state) => state.presentationState);
    const [ deletePresetQuery ] = useLazyDeletePresetQuery();
    const [ deleteLogoMutation ] = useLazyDeleteLogoQuery();
    const [ updatePresentationStyle, { isLoading: isUpdatePresentationStyleLoading } ] = useUpdatePresentationStyleMutation();
    const [ loadPresentationQuery, { isLoading: isLoadingUpdatePresentation } ] = useLazyLoadPresentationQuery();
    const navigate = useNavigate();

    const isLoadingStyleProcess = isLoadingUpdatePresentation || isLoadingPresentation || isUpdatePresentationStyleLoading;
    const validateThemeParams = ({ param, value }) => {
        if(param === 'fonts' && assetThemes?.[theme]?.['fonts']) assetThemes[theme]['fonts'] = context?.options?.font || assetThemes[theme]['fonts'];
        const currentParam = param === 'color' ? 'colors' : param;
        const randomResult = (assetThemes[theme]?.[currentParam][getRandomInt(0, assetThemes[theme]?.[currentParam].length-1)]?.value ?? assetThemes[theme]?.[currentParam][getRandomInt(0, assetThemes[theme]?.[currentParam].length-1)]?.font ?? assetThemes[theme]?.[currentParam][getRandomInt(0, assetThemes[theme]?.[currentParam].length-1)]);
        if(!value) return randomResult?.value ?? randomResult;
        return  assetThemes[theme]?.[currentParam]?.[0]?.value ? assetThemes[theme]?.[currentParam].find((res) => (res?.value) === value)?.value ?? randomResult?.value ?? randomResult : (assetThemes[theme]?.[currentParam].includes(value) ? value : (randomResult?.result ?? randomResult))
    }
    const changeSelectedStyle = useCallback((params) => {
        !params?.logo && USER_AMPLITUDE_KEYS[Object.keys(params)[0]] && changeUserSettingsAmplitudeHandler(USER_AMPLITUDE_KEYS[Object.keys(params)[0]]);
        setSelectedThemeStyle((prevState) => {
            const changesParams = {
                color:  params?.color ??  prevState.color,
                shape:  params?.shape ?? prevState.shape,
                font:  params?.font === t.AutomaticSelection ? assetThemes[theme]?.fonts[getRandomInt(0, assetThemes[theme]?.fonts.length-1)]?.value :  params?.font  ?? prevState.font,
                logo: params?.logo !== '' ? params?.logo : prevState.logo ?? "",
				fx: assetThemes[theme].fx ?? ""
            }
            setDraftThemeParams( changesParams);
            return changesParams
        });

    }, [theme]);
    const { product_balance = {} } = context;
    const isNextButtonDisabled = (Object.values(selectedThemeStyle).includes(null) || isLoadingStyleProcess || showColorpicker || process) || !presentationOptions;
    function onboardingChangeColorNextHandler(color){
        changeCurrentOnboardingStep({ step: 4, freeze: 2 });
        changeSelectedStyle({ color });
    }
    function analyticCatchError(error, httpCode){
        amplitude.getInstance().logEvent('service error system', {
            'message': error || t.oopsSomethingBroke,
            'httpCode': httpCode,
            'action': 'sendOptions',
        });
        TagManager.dataLayer({
            dataLayer: {
                event : 'error',
                error_type : 'system_error'
            }
        });
        Sentry.captureException({
            function: 'sendOptions',
            msg: error || t.oopsSomethingBroke,
            text: "",
            index: 0,
        });
    }
    function changeUserSettingsAmplitudeHandler(typeAction, value='changed'){
        updateUserActionsAmplitude((prevState) => ({...(prevState ?? {}), [typeAction]: value}));
    }
    function sendOptions(){
        const formData = optionsBuilder({ setup: selectedThemeStyle });
        if(isPPTXUpload){
            TagManager.dataLayer({
                dataLayer: {
                    event : isFirstStyleSettings ? "click_generation_start" : "style_change_save",
                    processed_file_id: projectId,
                }
            });
                    amplitude.getInstance().logEvent('file generation first slide', {
                        'slide number': firstGeneratedSlide ?? '',
                        'processed file id': +projectId
                    });
        }else{
            TagManager.dataLayer({
                dataLayer: {
                    event : isFirstStyleSettings ? "click_generation_start" : "style_change_save",
                    style_name: theme
                }
            });
            amplitude.getInstance().logEvent(isFirstStyleSettings ? 'click generation start' : 'style change save', {
                ...(userActionsAmplitude ?? {}),
                style: theme,
                color: selectedThemeStyle['color'],
                shape: selectedThemeStyle['shape'],
                font: selectedThemeStyle['font'],
            });
        }
            updatePresentationStyle({ projectId, body: formData }).unwrap().then((updatedPresentationInfo) => {
                if(!updatedPresentationInfo.status){
                    throw({
                        error: updatedPresentationInfo.errors,
                        httpCode: '500'
                    });
                }
                loadPresentationQuery({ projectId }).unwrap().then((presentationUpdate) => {
                    if(presentationUpdate.status){
                        if(!Object.keys(presentationUpdate.result.process.option[0]).length)  setTimeout( () => sendOptions(), 2000);
                        else {
                            try{
                                if(isPPTXUpload && isFirstStyleSettings){
                                    const errorList = presentationUpdate?.result?.queue[0]?.reduce((slideErrors, slide) => {
                                        if(Object.keys(slide?.error).length > 0) {
                                            slideErrors.push(slide?.error?.message);
                                        }
                                        return slideErrors;
                                    }, []) ?? [];
                                    const lessInfo = (count, total, prefix) =>{
                                        const percentOfPrefix = count / total  * 100;
                                        switch (percentOfPrefix){
                                            case (percentOfPrefix < 20): {
                                                return `${prefix}_status_less_20%`
                                            }
                                            case (percentOfPrefix > 20 && percentOfPrefix < 80): {
                                                return `${prefix}_status_20_80%`;
                                            }
                                            case (percentOfPrefix >= 80 && percentOfPrefix < 100 ): {
                                                return  `${prefix}_status_from_80%`;
                                            }
                                            default: {
                                                return  `${prefix}_status_missing`
                                            }
                                        }
                                    }
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event : "file_generation_done",
                                            generation_slides_number: presentationUpdate?.result?.source_files?.[0]?.total_slide_count ?? 1,
                                            generation_error_status : errorList.length ? lessInfo(errorList.length, presentationUpdate.result.queue[0], 'error') : "error_status_missing",
                                            generation_warning_status : presentationUpdate?.result.warning.length  ? lessInfo(presentationUpdate?.result.warning.length, presentationUpdate.result.queue[0], 'warning') : 'warning_status_missing',
                                            processed_file_id : +projectId,
                                        }
                                    });
                                    amplitude.getInstance().logEvent('file generation done', {
                                        'slides in presentation': presentationUpdate?.result?.source_files?.[0]?.total_slide_count ?? 1,
                                        'slide errors': errorList.length,
                                        'slide error rate': errorList.length ? Math.round(errorList.length /  presentationUpdate.result.queue[0].length * 100) : 0,
                                        'slide error names': errorList.length,
                                        'slide warnings': presentationUpdate.result.warning.length,
                                        'slide warning rate': presentationUpdate.result.warning.length ? Math.round(presentationUpdate.result.warning.length /  presentationUpdate.result.queue[0].length * 100) : 0,
                                        'slide warning names': presentationUpdate.result.warning,
                                        'processed file id': projectId,
                                    });
                                }
                            }catch(error) {
                                console.error('[ANALYTIC ERROR]', error);
                            }
                            navigate(serverPath.project + projectId);
                        }
                    }else{
                        getToast({
                            systemMessage: {
                                msg: 'CREATE PRESENTATION ERROR',
                                autoclose: 4000
                            }
                        });
                    }
                }).catch((error) => {
                    console.error('[ERROR]', error);
                    getToast({
                        systemMessage: {
                            msg: `PRESENTATION UPDATE ERROR`,
                            autoclose: 4000
                        }
                    });
                });
            }).catch((error, httpCode) => {
                console.error('UPDATE PRESENTATION STYLE ERROR]: ', error);
                analyticCatchError(error, httpCode);
            });
    }
    function deleteLogoHandler (id) {
        deleteLogoMutation({ params: id });
    }
    function deleteColor (item) {
        setDeletingColor(true);
        deletePresetQuery({ presetId: item.id }).unwrap().then((deleteResult) => {
            if (deleteResult.status) {
                setPresetColors((prevState) => prevState.filter(({id}) => id !== item.id));
                setShowColorpicker(false);
            }
        }).finally(() => {
            setDeletingColor(false);
        });
    }
    function toggleColorpicker(status = false){
        setShowColorpicker((prevState) => status || !prevState);
    }
    useEffect(() => {
        if(!Object.keys(draftThemeParams).length){
            setDraftThemeParams((prevState) => {
                return Object.keys(prevState).length ? prevState :
                    (Object.keys(presentationOptions ?? {}).length ? {
                        color: rgbToHex(presentationOptions?.color_value),
                        shape: presentationOptions?.shape_value,
                        font: presentationOptions?.font.value,
                        logo: '',
                    } : {
                        color: validateThemeParams({ param: 'color' }), //prevState?.color && assetThemes[theme]?.colors.some(i => i.value === prevState?.color.toLowerCase()) ? prevState.color : assetThemes[theme]?.colors[getRandomInt(0, assetThemes[theme]?.colors.length-1)].value,
                        shape: validateThemeParams({ param: 'shapes' }),
                        font:   validateThemeParams({ param: 'fonts' }),
                        logo: '',
                    })
            });
        }
    }, [presentationOptions]);
    useEffect(() => {
        setSelectedThemeStyle({
            logo: '',
            color: validateThemeParams({ param: 'color', value: draftThemeParams?.color }), //prevState?.color && assetThemes[theme]?.colors.some(i => i.value === prevState?.color.toLowerCase()) ? prevState.color : assetThemes[theme]?.colors[getRandomInt(0, assetThemes[theme]?.colors.length-1)].value,
            shape: validateThemeParams({ param: 'shapes', value: draftThemeParams?.shape}),
            font:   validateThemeParams({ param: 'fonts', value: draftThemeParams?.font}),
			fx: assetThemes[theme].fx ?? ""
        });
    }, [theme]);
    useEffect(() => {
        context['user-preset']?.color && setPresetColors(context['user-preset']?.color ?? []);
    }, [context]);

     return (
        <div className="gen_panel h-full">
            <div className="gen_panel__maintitle">Style settings</div>
            <div className="gen_panel__settings min-h-[calc(100vh-200px)]">
                {
                    !context.TAG &&
                    <div>
                        <div className="gen_panel__title">
                            {t.Style}
                            <div className="gen_helper gen_helper-style">
                                <div className="gen_helper__text">{t.styleHelperText}</div>
                            </div>
                        </div>
                        <div
                            className="gen_panel__style_name">{theme === 'classic' ? 'Original Formula' : theme}</div>
                    </div>
                }
                <div className="gen_panel__colors_wrap">
                    <div className="gen_panel__title">
                        {t.selectColor}
                        <div className="gen_helper gen_helper-color">
                            <div className="gen_helper__text">{t.colorHelperText}</div>
                        </div>
                    </div>
                    {
                        (!context.preset || !context.preset.color) &&
                        <>
                            <OnboardingMessage
                                boardId={3}
                                isActivate={currentOnboardingStep()}
                                onNext={() => onboardingChangeColorNextHandler(selectedThemeStyle.color ?? assetThemes[theme]?.colors[0]?.value)}
                                nowrapDisabled={true}
                                {...(ONBOARDING_MESSAGE_STYLE ?? {})}
                            >
                                <div className={'gen_panel__colors' + (deletingColor ? ' loading' : '')}>
                                    {
                                        !(context['user-preset'] && context['user-preset'].color && context['user-preset'].color.length > 100) &&
                                        <div className="gen_panel__add_color" onClick={toggleColorpicker}>
                                            <svg width="14" height="14" viewBox="0 0 14 14">
                                                <path d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z" fill="#00BA88"/>
                                            </svg>
                                        </div>
                                    }
                                    {assetThemes[theme]?.colors.map((item, i) => (
                                        <div key={i}
                                             className={'gen_panel__color gen_panel__color-' + item.value.toLowerCase() + (selectedThemeStyle.color === item.value ? ' active' : '')}
                                             title={item.name}
                                             style={{background: '#' + item.value}}
                                             onClick={() => {
                                                 changeSelectedStyle({ color: item.value});
                                                 changeCurrentOnboardingStep({step: 4, freeze: 2});
                                                 changeUserSettingsAmplitudeHandler('color status');
                                                }
                                             }
                                        />
                                    ))}
                                    {
                                        presetColors &&
                                        presetColors?.map((item, i) => (
                                            <div key={i}
                                                 className={'gen_panel__color gen_panel__color-' + item.value.toLowerCase() + (selectedThemeStyle.color === item.value ? ' active' : '')}
                                                 title={item.name}
                                                 style={{background: '#' + item.value}}
                                                 onClick={() => {
                                                     //selectOption('color', item.value, true);
                                                     changeSelectedStyle({ color: item.value});
                                                 }
                                                 }
                                            >
                                                <div className="gen_panel__color_del" onClick={(e) => {
                                                    deleteColor(item);
                                                    e.stopPropagation();
                                                }}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </OnboardingMessage>
                            <AnimatePresence>
                                {
                                    showColorpicker &&
                                    <motion.div initial={{ scale: 0, opacity: 0}} exit={{ height: 0, opacity: 0}} animate={{ scale: '100%', opacity: 1}} >
                                        <ColorPicker
                                        selectedColor={selectedThemeStyle.color}
                                        colorpickerRef={colorpickerRef}
                                        updateData={updateData}
                                        colors={context['user-preset'] && context['user-preset'].color ? context['user-preset'].color : []}
                                        baseColors={assetThemes[theme].colors}
                                        close={() => {
                                            toggleColorpicker(false)
                                        }}
                                        selectOption={(type, value) => changeSelectedStyle({ [type]: value})}/>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </>
                    }

                    {
                        (context.preset && context.preset.color) &&
                        <div>
                            <div className="gen_panel__colors">
                                {context.preset.color.map((item, i) => (
                                    <div key={i}
                                         className={'gen_panel__color gen_panel__color-' + item.value.toLowerCase() + (selectedThemeStyle.color === item.value ? ' active' : '')}
                                         title={item.name}
                                         style={{background: '#' + item.value}}
                                         onClick={() => changeSelectedStyle({ color: item.value }) }
                                    />
                                ))}
                            </div>
                        </div>
                    }
                </div>

                {
                    (selectedThemeStyle.color && (theme === 'classic' || assetThemes[theme]?.shapes.length > 1)) &&
                    <div className={'gen_panel__shapes_wrap' + (!!selectedThemeStyle.color ? '' : ' hidden')}>
                        <div className="gen_panel__title">
                            {t.selectStylisticShape}
                            <div className="gen_helper gen_helper-shape">
                                <div className="gen_helper__text">
                                    <p>{t.formatString(t.shapeHelperText1, <span>&nbsp;</span>)}</p>
                                    <p>{t.shapeHelperText2}</p>
                                    <ul>
                                        <li>{t.shapeHelperTextItem1}</li>
                                        <li>{t.shapeHelperTextItem2}</li>
                                        <li>{t.shapeHelperTextItem3}</li>
                                        <li>{t.shapeHelperTextItem4}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            (!context.preset || !context.preset.shape) &&
                            <div className="gen_panel__shapes">
                                {context.options.shape.map((item, i) => {
                                        if (selectedThemeStyle.theme !== 'classic' && !assetThemes[theme]?.shapes.includes(item.value)) return;
                                        return (
                                            <div key={i}
                                                 className={cl('gen_panel__shape', selectedThemeStyle.shape === item.value && 'active')}
                                                 onClick={() =>  changeSelectedStyle({ shape: item.value})}
                                            >{shapesSVG[item.value]}</div>
                                        )
                                    }
                                )}
                            </div>
                        }
                        {
                            (context.preset && context.preset.shape) &&
                            <div className="gen_panel__shapes">
                                {context.preset.shape.map((item, i) => (
                                    <div key={i}
                                         className={cl('gen_panel__shape', (selectedThemeStyle.shape === item.value) && 'active')}
                                         onClick={() =>  changeSelectedStyle({ shape: item.value})}
                                    >{shapesSVG[item.value]}</div>
                                ))}
                            </div>
                        }
                    </div>
                }

                {
                    (!selectedThemeStyle.color || !selectedThemeStyle.shape) &&
                    <div className="gen_bubble_wrap">
                        <div className="gen_bubble">{!!selectedThemeStyle.color ? t.formatString(t.chooseShapeMsg,
                            <br/>) : t.ChooseYourBrandColor}</div>
                    </div>
                }
                <AnimatePresence>
                    {!!selectedThemeStyle.color && !!selectedThemeStyle.shape && <motion.div initial={{ opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className="gen_panel__others">
                        <div className="gen_panel__title">
                            {t.Font}
                            <div className="gen_helper gen_helper-font">
                                <div className="gen_helper__text">{t.FontHelperText}</div>
                            </div>
                        </div>

                        <GenSelect name="font"
                                   options={(context?.preset && context?.preset?.font && context?.preset?.font.length) ? [] : [...[{
                                       name: t.AutomaticSelection,
                                       value: t.AutomaticSelection
                                   }], ...(context?.options?.font ?? [])]}
                                   activeItem={selectedThemeStyle.font}
                                   onSelect={(fontName, fontValue) => changeSelectedStyle({ font: fontValue})}
                                   preset={(context.preset && context.preset.font) ? context.preset.font : null}
                        />

                        <div className="gen_panel__title">{t.Logo}</div>
                        <GenSelect
                            name="logo"
                            staticUrl={context.ws_api_static}
                            // options={context.options.logo}
                            onSetUploadedLogo={setUploadLogoList}
                            options={[]}
                            activeItem={selectedThemeStyle.logo}
                            onSelect={(value) => {
                                if (value?.length && (product_balance.product?.is_trial)) {
                                    setTrialPopup({ type: 'logo' });
                                } else {
                                    changeUserSettingsAmplitudeHandler('logo', uploadLogoList.reduce((statusOfSelectedLogo, currentLogo) =>{
                                        if(context?.['user-preset'].logo.find((currentExistsLogo) => currentExistsLogo.split('/')[currentExistsLogo.split('/').length-1] === currentLogo)) {
                                            return 'new logo';
                                        }else{
                                            return  statusOfSelectedLogo;
                                        }
                                    }, 'existing logo' ));
                                    changeSelectedStyle({ logo: value });
                                }
                            }}
                            preset={(context.preset && context.preset.logo) ? context.preset.logo : (context?.['user-preset']?.logo) ? context['user-preset'].logo : null}
                            isRemovable={(context['user-preset'] && context['user-preset'].logo)}
                            deleteItem={deleteLogoHandler}
                            isCorporate={!!context.TAG}
                            isLocked={!product_balance?.is_active || product_balance.product?.is_trial}
                        />
                    </motion.div>}
                </AnimatePresence>
            </div>
                <div className="gen_panel__footer sticky bottom-0">
                            {/*<OnboardingMessage boardId={4} isActivate={currentOnboardingStep()} isFullWidthChild={true} {...ONBOARDING_MESSAGE_SAVE}>*/}
                                <div  className={cl('gen_btn_main save_style_btn save_style_btn__onboard ', isNextButtonDisabled && ' gen_btn_main-disabled')}
                                     onClick={() => !isUpdatePresentationStyleLoading && sendOptions()}>
                                    {process
                                        ? <span>{t.waitForUploading}</span>
                                        : <span>{GEN_STYLE_TEXTS.saveAndContinue}</span>
                                    }
                                </div>
                            {/*</OnboardingMessage>*/}
                    <div className="close_style_change_btn about_plain_link" onClick={() => navigate(-1)}>{GEN_STYLE_TEXTS.back}</div>
                </div>
        </div>
    );
};


export default GenStylePanelBlock;

function rgbToHex(rgb) {
	return ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1).toUpperCase();
}