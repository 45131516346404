import React, {useEffect, useRef, useState} from 'react';
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import {useOutletContext} from "react-router";

const UploadImageCropBlock = (props) => {
    const {imgSrc, previewCanvasRef} = props;
    const imgRef = useRef(null),
        blobUrlRef = useRef(''),
        [crop, setCrop] = useState(),
        [completedCrop, setCompletedCrop] = useState(),
        [scale, setScale] = useState(1),
        [rotate, setRotate] = useState(0),
        [aspect, setAspect] = useState(16 / 4);
    const hiddenAnchorRef = useRef(null);

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop({unit: '%', width: 90}, aspect, mediaWidth, mediaHeight),
            mediaWidth,
            mediaHeight,
        )
    }
    function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
        const TO_RADIANS = Math.PI / 180;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
        ctx.restore();
    }
    function useDebounceEffect(fn, waitTime, deps) {
        useEffect(() => {
            const t = setTimeout(() => {
                    fn.apply(undefined, deps);
                },
                waitTime);

            return () => {
                clearTimeout(t);
            }
        }, deps)
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
            setAspect(undefined);
        }
    }

    function onDownloadCropClick() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist');
        }

        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob');
            }
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
            }
            blobUrlRef.current = URL.createObjectURL(blob);
            // hiddenAnchorRef.current.href = blobUrlRef.current;
            // hiddenAnchorRef.current.click();
        })
    }

    useDebounceEffect(
        async () => {
            if (completedCrop.width && completedCrop.height && imgRef.current && previewCanvasRef.current) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
            }
        },
        100,
        [completedCrop, scale, rotate]
    )

    return (
        <div className="App">
            {
                !!imgSrc &&
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                >
                    <img
                        style={{maxHeight: '205px'}}
                        ref={imgRef}
                        alt=""
                        src={imgSrc}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            }
            {
                !!completedCrop &&
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        display: 'none',
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop.width,
                        height: completedCrop.height,
                    }}
                />
            }
            {/* <div>
			<button onClick={onDownloadCropClick}>Download Crop</button>
			<a
				ref={hiddenAnchorRef}
				download
				style={{
				position: 'absolute',
				top: '-200vh',
				visibility: 'hidden',
				}}
			>
				Hidden download
			</a>
			</div> */}
        </div>
    )
};

export default UploadImageCropBlock;