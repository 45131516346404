import React from 'react';
import {legalData} from "./features/legal.data";
import LegalLayoutFeature from "./features/legal-layout.feature";

const CookiePage = () => {
    return (
        <LegalLayoutFeature {...legalData['cookie']}>
            <h2>COOKIE POLICY</h2>
            <p>
                Wonderslide website uses cookies to distinguish you from other users of our
                website, provide certain user functionality of our website and also allow us to
                improve our site. A cookie is a small file written by a website and stored locally within
                the web browser or file system on your computer or mobile device. We use the
                following types of cookies:
            </p>
            <h4>Strictly necessary cookies</h4>
            <p>
                These are required for the operation of our website. They include, for example,
                cookies that enable you to log into secure areas of our website.
            </p>
            <h4>Functionality cookies</h4>
            <p>
                These are used to recognize you when you return to our website. This enables us to
                personalize our content for you, greet you by name, remember your preferences (for
                example, your choice of language or country), and to allow you to have an interactive
                chat with a support representative.
            </p>
            <h4>Analytical/performance/testing cookies</h4>
            <p>
                These allow us to recognize and count the number of visitors and to see how visitors
                move around our website when they are using it. This helps us to improve the way
                our website works, for example, by ensuring users are finding what they are looking
                for easily. These allow us to provide you with a consistent experience as we test
                different versions of features and functionality.
            </p>
            <p>
                You may block cookies by activating the setting on your browser that allows you to
                refuse the setting of all or some cookies. However, if you use your browser settings
                to block all cookies (including essential cookies) you may not be able to access all or
                parts of our site. More on <a href="https://www.aboutcookies.org" target="_blank" rel="nofollow" >www.aboutcookies.org</a>.
            </p>
        </LegalLayoutFeature>
    );
};

export default CookiePage;