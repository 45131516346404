import React, {useEffect, useRef, useState} from 'react';
import t from '../../../../../lib/lng';
import UploadLogo from "./upload-logo.block";
import {AnimatePresence} from "framer-motion";
import {useLazyDeleteLogoQuery} from "../../../../store/api/presentation.api";
import {useOutletContext} from "react-router";
import {useClickOutside} from "../../../../hooks/use-click-outside";
import cl from "classnames";


const fontsDescrs = {
    'Alumni Sans': 'digital, natural',
    'Comfortaa': 'gentle, soft',
    'Exo 2': 'futuristic, technologic',
    'IBM Plex Sans': 'corporate, premium',
    'Montserrat': 'solid, modern',
    'Nunito': 'friendly, neutral',
    'Open Sans': 'universal, neutral',
    'Oswald': 'official, scientific',
    'Play': 'reserved, financial',
    'Roboto': 'universal, neutral',
    'Mulish': 'corporate, premium',
    'Bellota': 'friendly, neutral',
    'Noto Sans': 'friendly, neutral',
    'Advent Pro': 'futuristic, technologic',
    'Playfair Display': 'friendly, elegant',
    'Ubuntu': 'reserver, financial',
    'Lora': 'classic, elegant',
    'Jost': 'solid, modern',
    'Cormorant Infant': 'friendly, elegant',
    'Sofia Sans': 'digital, natural',
};

const GenSelectBlock =  (props) => {
    const { options, activeItem, onSelect, name, preset, isCorporate, staticUrl, isLocked, onSetUploadedLogo } = props;
    const isRemovable = true;
    const { updateData, setTrialPopup } = useOutletContext();
    const [deleteLogoQuery] = useLazyDeleteLogoQuery();
    const [ isShowUploadLogo, setShowUploadLogo ] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const genSelectRef = useRef(null);
    useEffect(() => {
        function closeOnClick(event) {
            if (isOpen && event.target.closest('.gen_select') !== genSelectRef.current) {
                setIsOpen(false);
            }
        }
        window.addEventListener('click',  closeOnClick);

        return () => {
            window.removeEventListener('click', closeOnClick);
        }
    }, []);

    useEffect(() => {
        if(isOpen && genSelectRef?.current?.getBoundingClientRect().bottom > window.innerHeight){
            genSelectRef.current.scrollIntoView(false);
        }
    }, []);

    useClickOutside(genSelectRef, () => setIsOpen(false));

    function showUploadLogo(...params){
        if (isLocked) {
            setTrialPopup({type: 'logo'});
        }
        else {
            setShowUploadLogo(true);
        }
    }

    function deleteLogoHandler(id){
            // if (this.props.context['user-preset'].logo.filter(i => i.id == id)[0].value == this.state.options.logo) {
            //     this.state.options.logo = '';
            //     updateData();
            // }
        deleteLogoQuery({ id }).unwrap().then((deleteLogoResponse) => {
            if(deleteLogoResponse?.status){
                updateData();
            }
        }).catch((error) => {
            console.error('[DELETE LOGO ERROR]: ', error);
        })
            // let response = await fetch('/logo-delete/'+id, {
            //     headers: xhrHeaders()
            // });
            // if (response.status) {
            //     this.props.updateData();
            // }
    }

    // function deleteItem(...params){
    //     console.log('deleteItems', params);
    // }

    let activeItemName = options.filter(item => item.value === activeItem)[0],
        fromPreset = false,
        colorBg = '';

    if (!activeItemName && preset) {
        activeItemName = preset.filter(item => item.value === activeItem)[0];
        fromPreset = true;
    }

    if (activeItemName) {
        if (activeItemName.color) colorBg = activeItemName.color;
        if (name === 'font' || name === 'logo') {
            activeItemName = activeItemName.value;
        }
        else {
            activeItemName = activeItemName.name;
        }
    }
    else if (name === 'logo') {
        activeItemName = t.NoLogo;
    }
    else {
        activeItemName = t.Choose;
    }

    if (name === 'logo') {
        return (
            <div className={cl('gen_select gen_select-logos', isOpen && 'open', activeItem && 'selected' )} style={{backgroundColor: colorBg ? colorBg : ''}} ref={(n)=>{genSelectRef.current = n}}>
                <div className="gen_select__current" onClick={()=>{setIsOpen((prevState) => !prevState)}}>
                    { !activeItem && activeItemName }
                    { activeItem && fromPreset && <img className="gen_select__img" src={activeItemName.match(/^\//) || activeItemName.match('https://') ? activeItemName : '/'+activeItemName} alt="" /> }
                    { activeItem && !fromPreset && <img className="gen_select__img" src={staticUrl.substring(0, staticUrl.length-1) + activeItemName} alt="" /> }
                </div>
                {
                    <div className="gen_select__options" hidden={!isOpen}>
                        <div className="gen_select__options__scroll">
                            <div className="gen_select__logo_btns">
                                { !isCorporate && <div className="gen_select__upload_logo" onClick={showUploadLogo}>{t.UploadLogo}</div> }
                                <div className={'gen_select__nologo' + (!activeItem ? ' active' : '')} onClick={()=>{onSelect('', ''); setIsOpen((prevState) => !prevState)}}>{t.NoLogo}</div>
                            </div>
                            {
                                preset &&
                                <ul className="gen_select__logos">
                                    {preset.map((item, i) => (
                                        <li key={i}
                                            style={{backgroundColor: item.color ? item.color : '#000'}}
                                            className={'gen_select__logo' + (item.value === activeItem ? ' active' : '' )}
                                            onClick={()=>{onSelect(item.value); setIsOpen((prevState) => !prevState)}}
                                        >
                                            <img className="gen_select__logo_img" src={item.value.match(/^\//) || item.value.match('https://') ? item.value : '/'+item.value} />
                                            {
                                                isRemovable &&
                                                <div className="gen_select__logo_delete" onClick={(e) => { deleteLogoHandler(item.id); e.stopPropagation();}}></div>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            }
                            {
                                !preset &&
                                <ul className="gen_select__logos">
                                    {options.map((item, i) => {
                                        return(
                                            <li key={i}
                                                hidden={search && !item.name.toLowerCase().includes(search)}
                                                className={'gen_select__logo' + (item.value === activeItem ? ' active' : '' )}
                                                onClick={()=>{onSelect(name, item.value); setIsOpen((prevState) => !prevState)}}
                                            >
                                                <img className="gen_select__logo_img" src={staticUrl.substring(0, staticUrl.length-1) + item.value} />
                                            </li>
                                        )}
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                }
                <AnimatePresence>
                    {isShowUploadLogo && <UploadLogo onCloseUpload={() => setShowUploadLogo(false)}/>}
                </AnimatePresence>
            </div>
        );
    }

    function selectItemHandler(value) {
        onSelect(value);
    }
    return (
        <div className={cl('gen_select hover:z-[100] ', name && ' gen_select-' + name, isOpen ? ' open' : '')} onClick={()=>{setIsOpen((prevState) => !prevState)}} ref={(n)=>{genSelectRef.current = n}}>
            <div className="gen_select__current" style={name === 'font' ? { fontFamily: options.find((font) => font.name === activeItemName)?.value} : { fontFamily: 'Roboto' }}>{activeItemName}</div>
            {
                isOpen &&
                <div className="gen_select__options">
                    <div className="gen_select__options__scroll">
                        <div>
                            {
                                preset &&
                                <div className="gen_select__options__preset">
                                    {/* <div className="gen_select__options__preset_name">{t.Corporative} {presetNames[name]}</div> */}
                                    {preset.map((item, i) => {
                                        if (name === 'font' && item.face !== 'regular') return;
                                        return(
                                            <div key={i}
                                                 className={'gen_select__option' + (item.value === activeItem ? ' active' : '' )}
                                                 onClick={()=>{onSelect(name, item.value, true)}}
                                            >{name === 'font' ? item.value : item.name}</div>
                                        )}
                                    )}
                                </div>
                            }
                            {options.map((item, i) => (
                                <div key={i} className={'gen_select__option' + (item.value === activeItem ? ' active' : '' ) + (name && name.match(/font/) && item.value === t.AutomaticSelection ? ' dedicated' : '')}
                                     onClick={()=>{onSelect(name, item.value)}}
                                     style={name === 'font' && item.value !== t.AutomaticSelection ? {fontFamily: item.value === 'Exo 2' ? 'Exo-2' : item.value} : {}}
                                >
                                    {item.name}
                                    {name === 'font' && item.value !== t.AutomaticSelection && <span className="gen_select__option_descr">{fontsDescrs[item.value]}</span>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            <AnimatePresence>
                {isShowUploadLogo && <UploadLogo onSetUploadedLogo={onSetUploadedLogo} onCloseUpload={() => setShowUploadLogo(false)} />}
            </AnimatePresence>
        </div>
    );
}

export default GenSelectBlock;

//  class GenSelect extends React.Component {
//     constructor() {
//         super();
//         this.state = {
//             isOpen: false,
//             search: ''
//         };
//         this.closeOnClick = this.closeOnClick.bind(this);
//     }
//
//     componentDidMount() {
//         window.addEventListener('click', this.closeOnClick);
//     }
//
//     componentWillUnmount() {
//         window.removeEventListener('click', this.closeOnClick);
//     }
//
//     componentDidUpdate(prevProps, prevState) {
//         const options = this.elementDOM.querySelector('.gen_select__options');
//         if (!options) return;
//         if (this.state.isOpen && options.getBoundingClientRect().bottom > window.innerHeight) {
//             options.scrollIntoView(false);
//         }
//     }
//
//     closeOnClick(e) {
//         if (this.state.isOpen && e.target.closest('.gen_select') !== this.elementDOM) {
//             this.setState({isOpen: false});
//         }
//     }
//
//     render() {
//         const { isOpen, search } = this.state,
//             { options, activeItem, onSelect, name, preset, isCorporate, staticUrl } = this.props;
//
//         let activeItemName = options.filter(item => item.value === activeItem)[0],
//             fromPreset = false,
//             colorBg = '';
//
//         if (!activeItemName && preset) {
//             activeItemName = preset.filter(item => item.value === activeItem)[0];
//             fromPreset = true;
//         }
//
//         if (activeItemName) {
//             if (activeItemName.color) colorBg = activeItemName.color;
//             if (name === 'font' || name === 'logo') {
//                 activeItemName = activeItemName.value;
//             }
//             else {
//                 activeItemName = activeItemName.name;
//             }
//         }
//         else if (name === 'logo') {
//             activeItemName = t.NoLogo;
//         }
//         else {
//             activeItemName = t.Choose;
//         }
//
//         if (name === 'logo') {
//             return (
//                 <div className={'gen_select gen_select-logos' + (isOpen ? ' open' : '') + (activeItem ? ' selected' : '')} style={{backgroundColor: colorBg ? colorBg : ''}} ref={(n)=>{this.elementDOM = n}}>
//                     <div className="gen_select__current" onClick={()=>{this.setState({isOpen: !isOpen})}}>
//                         { !activeItem && activeItemName }
//                         { activeItem && fromPreset && <img className="gen_select__img" src={activeItemName.match(/^\//) || activeItemName.match('https://') ? activeItemName : '/'+activeItemName} alt="" /> }
//                         { activeItem && !fromPreset && <img className="gen_select__img" src={staticUrl.substring(0, staticUrl.length-1) + activeItemName} alt="" /> }
//                     </div>
//                     {
//                         <div className="gen_select__options" hidden={!isOpen}>
//                             <div className="gen_select__options__scroll">
//                                 <div className="gen_select__logo_btns">
//                                     { !isCorporate && <div className="gen_select__upload_logo" onClick={this.props.showUploadLogo}>{t.UploadLogo}</div> }
//                                     <div className={'gen_select__nologo' + (!activeItem ? ' active' : '')} onClick={()=>{onSelect(name, ''); this.setState({isOpen: !isOpen})}}>{t.NoLogo}</div>
//                                 </div>
//                                 {
//                                     preset &&
//                                     <ul className="gen_select__logos">
//                                         {preset.map((item, i) => (
//                                             <li key={i}
//                                                 style={{backgroundColor: item.color ? item.color : '#000'}}
//                                                 className={'gen_select__logo' + (item.value === activeItem ? ' active' : '' )}
//                                                 onClick={()=>{onSelect(name, item.value, true); this.setState({isOpen: !isOpen})}}
//                                             >
//                                                 <img className="gen_select__logo_img" src={item.value.match(/^\//) || item.value.match('https://') ? item.value : '/'+item.value} />
//                                                 {
//                                                     this.props.isRemovable &&
//                                                     <div className="gen_select__logo_delete" onClick={(e) => {this.props.deleteItem(item.id); e.stopPropagation();}}></div>
//                                                 }
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 }
//                                 {
//                                     !preset &&
//                                     <ul className="gen_select__logos">
//                                         {options.map((item, i) => {
//                                             return(
//                                                 <li key={i}
//                                                     hidden={search && !item.name.toLowerCase().includes(search)}
//                                                     className={'gen_select__logo' + (item.value === activeItem ? ' active' : '' )}
//                                                     onClick={()=>{onSelect(name, item.value); this.setState({isOpen: !isOpen})}}
//                                                 >
//                                                     <img className="gen_select__logo_img" src={staticUrl.substring(0, staticUrl.length-1) + item.value} />
//                                                 </li>
//                                             )}
//                                         )}
//                                     </ul>
//                                 }
//                             </div>
//                         </div>
//                     }
//                 </div>
//             );
//         }
//
//         else {
//             return (
//                 <div className={'gen_select' + (name ? ' gen_select-' + name : '') + (isOpen ? ' open' : '')} onClick={()=>{this.setState({isOpen: !isOpen})}} ref={(n)=>{this.elementDOM = n}}>
//                     <div className="gen_select__current">{activeItemName}</div>
//                     {
//                         isOpen &&
//                         <div className="gen_select__options">
//                             <div className="gen_select__options__scroll">
//                                 <div>
//                                     {
//                                         preset &&
//                                         <div className="gen_select__options__preset">
//                                             {/* <div className="gen_select__options__preset_name">{t.Corporative} {presetNames[name]}</div> */}
//                                             {preset.map((item, i) => {
//                                                 if (name === 'font' && item.face !== 'regular') return;
//                                                 return(
//                                                     <div key={i}
//                                                          className={'gen_select__option' + (item.value === activeItem ? ' active' : '' )}
//                                                          onClick={()=>{onSelect(name, item.value, true)}}
//                                                     >{name === 'font' ? item.value : item.name}</div>
//                                                 )}
//                                             )}
//                                         </div>
//                                     }
//                                     {options.map((item, i) => (
//                                         <div key={i} className={'gen_select__option' + (item.value === activeItem ? ' active' : '' ) + (name && name.match(/font/) && item.value === t.AutomaticSelection ? ' dedicated' : '')}
//                                              onClick={()=>{onSelect(name, item.value)}}
//                                              style={name === 'font' && item.value !== t.AutomaticSelection ? {fontFamily: item.value == 'Exo 2' ? 'Exo-2' : item.value} : {}}
//                                         >
//                                             {item.name}
//                                             {name === 'font' && item.value !== t.AutomaticSelection && <span className="gen_select__option_descr">{fontsDescrs[item.value]}</span>}
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>
//                     }
//                 </div>
//             );
//         }
//     }
// }
//
// export default GenSelect;