import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router";
import * as Sentry from "@sentry/browser";

import t from "../../../lib/lng";
import { useToastContext } from "../../hooks/use-toast-provider";
import { useUploadPPTXMutation } from "../../store/api/presentation.api";
import { serverPath } from "../../utils/server-path";
import UploadDraftPanel from "./blocks/upload-draft/upload-draft-panel.block";
import SetupStyleBlock from "./blocks/setup-style.block";
import { useAppDispatch } from "../../hooks/use-store";
import { uploadFileProgress } from "../../store/slice/presentation.slice";
import { ProcessLoader } from '../../components/ui';

const UploadDraftPage = (props) => {
    const [isUploadProcess, setUploadProcess] = useState(false);
    const { getToast } = useToastContext();
    const { context, TagManager } = useOutletContext();
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [downloadingDemo, setDownloadingDemo] = useState(false);
    const dispatch = useAppDispatch()

    // TODO: is sample add
    const isSample = false;
    const [useUploadPPTX] = useUploadPPTXMutation();

    useEffect(() => {
        if (props.isDemo) {
            tryWithSample();
        }
    }, []);
    
    async function tryWithSample() {
        setDownloadingDemo(true);
        let file = await fetch('/static/i/sample.pptx?3');
		file = await file.blob();
		file = new File([file], 'sample.pptx', {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
        setDownloadingDemo(false);
		uploadFile(file, true, 'demo_file_from_button');

		amplitude.getInstance().logEvent('service onboarding demofile', {
			'action': 'demo started'
		});
	}

    function uploadFile(file, isSample=false, sampleType, isDraft=false) {
        if (!file) return;
        const handleError = ({error, status}) => {
            let msg = error || t.oopsSomethingBroke;
            if (msg === 'Payload Too Large') msg = t.maximumSizeExceeded;
            console.error('[UPLOAD ERROR]', error);
            getToast({
                systemMessage: {
                    type: 'error',
                    msg: msg
                }
            })
            if (isSample) {
                amplitude.getInstance().logEvent('file uploaded demo', {
                    status: 'error',
                    message: msg
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event : 'demo_file_upload',
                        file_upload_status : 'demo_file_upload_error',
                        demo_file_place: sampleType
                    }
                });
            }
            else {
                amplitude.getInstance().logEvent('file uploaded', {
                    status: 'error',
                    message: msg
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event : 'file_upload',
                        file_upload_status : 'file_upload_error'
                    }
                });
            }

            Sentry.captureException({
                function: 'uploadFile',
                msg: msg,
                file_name: file ? file.name : '',
                file_size: file ? file.size : ''
            });
        }
        const splitName = file.name.split('.');
        if (splitName[splitName.length-1] !== 'pptx') {
            getToast({
                systemMessage: {
                    type: 'error',
                    msg: t.weSupportOnlyPPTX
                }
            });
            return;
        }
        setUploadProcess(true);
        const formData = new FormData();
        formData.append('file', file);
        dispatch(uploadFileProgress({ fileName: file.name, uploadProgress: 0 }));
        useUploadPPTX({url: isSample ? `${serverPath.upload}?sample` : serverPath.upload, data: formData}).unwrap().then((responseFileUpload) => {
            if (responseFileUpload.status) {
                setCurrentProjectId(responseFileUpload.result.project_id);
                //navigate(`${route_path.setupStyle}${responseFileUpload.result.project_id}`);
                if (isSample) {
                    amplitude.getInstance().logEvent('file uploaded demo', {
                        status: 'uploaded',
                        message: ''
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            event : 'demo_file_upload',
                            file_upload_status : 'demo_file_upload_success',
                            demo_file_place: sampleType
                        }
                    });
                }
                else {
                    amplitude.getInstance().logEvent('file uploaded', {
                        status: 'uploaded',
                        message: ''
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            event : 'demo_file_upload',
                            file_upload_status : 'file_upload_success',
                            demo_file_place: "demo_file_self_load"
                        }
                    });
                }
            }
            else {
                handleError(responseFileUpload.error);
            }
        }).catch((error) => {
            handleError(error);
        });
        if (file.size > context?.upload_max_size) {
            getToast({
                systemMessage: {
                    type: 'error',
                    msg: t.formatString(t.FileSizeShouldNotExceed, context?.upload_max_size / 1024 / 1024)
                }
            });
            document.getElementById('pptxInput').value = '';
            return;
        }
        if (file.size === 73041 && !sampleType) {
            isSample = true;
            sampleType = 'demo_file_self_upload';
        }

        if (isSample) {
            amplitude.getInstance().logEvent('file upload demo start', {
                from: sampleType.replaceAll('_', ' '),
                type: `demo ${isDraft ? "scratch" : "file"}`
            });
        }
        else {
            amplitude.getInstance().logEvent('file upload start', {
                'file extension': 'pptx'
            });
        }
        };
    function submitDragEnter (e) {
        e.stopPropagation();
        e.preventDefault();
    }
    function onDropHandler(event){
        event.stopPropagation();
        event.preventDefault();
        const droppedFile = event.dataTransfer.files?.[0];
        droppedFile &&  uploadFile(droppedFile);
    }

    if (downloadingDemo) {
        return <ProcessLoader />
    }

    return (
        <div className="gen_main text-wf">
            {!isUploadProcess ? (
                <>
                <div className="gen_content">
                    <form id="form-file-upload"
                          onDragEnter={submitDragEnter}
                          onDrop={onDropHandler}
                          onDragOver={submitDragEnter}
                          onSubmit={(e) => uploadFile(e.target.files[0])}>
                        <label id="label-file-upload" htmlFor="pptxInput">
                            <div className="gen_upload">
                                <div className="gen_upload__text">{t.uploadDraftOfYourPresentation}</div>
                                <div className="block w-full h-full">
                                    <div className="gen_btn_main gen_upload__btn"><span>{t.formatString(t.chooseFile,
                                        <span>&nbsp;</span>)}</span></div>
                                    <input className="gen_upload__input" id="pptxInput" type="file" accept=".pptx"
                                           onChange={(e) => {
                                               uploadFile(e.target.files[0])
                                           }}
                                    />
                                </div>
                                <div className="gen_upload__subtext">
                                    <div>
                                        {t.selectPptxFileOrDrag}<a href="/static/i/sample.pptx?2">{t.exampleFile}</a>.
                                        <div className="gen_upload__info">
                                            <div className="gen_upload__info_text">{t.uploadInfoText}</div>
                                        </div>
                                    </div>
                                    <div>{t.formatString(t.ChooseDraft30Mb, <strong>{t.draft}</strong>,
                                        <strong>{t._30slides}</strong>)}</div>
                                </div>
                    </div>
                </label>  </form>
                <div className="f_onboarding">
                    <div className="f_onboarding_title">Let’s collect the content, don’t design:</div>
                    <div className="f_onboarding_items">
                    <div className="f_onboarding_item">
                                    <img className="f_onboarding_item__img"
                                         srcSet="/static/i/onboarding1.webp, /static/i/onboarding1@2x.webp 2x" alt=""/>
                                    <div className="f_onboarding_item__title">Keep it simple! One slide - one thought,
                                        don't skimp
                                        on the slides!
                                    </div>
                                </div>
                                <div className="f_onboarding_item">
                                    <img className="f_onboarding_item__img"
                                         srcSet="/static/i/onboarding2.webp, /static/i/onboarding2@2x.webp 2x" alt=""/>
                                    <div className="f_onboarding_item__title">Don't use background images.</div>
                                </div>
                                <div className="f_onboarding_item">
                                    <img className="f_onboarding_item__img"
                                         srcSet="/static/i/onboarding3.webp, /static/i/onboarding3@2x.webp 2x" alt=""/>
                                    <div className="f_onboarding_item__title">Use bulletpoints for your ideas.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gen_panel a_shake_bubble a_fadein_shapes a_fadein_others">
                        <UploadDraftPanel />
                    </div>
                </>
            ) : (
                <SetupStyleBlock isPPTXUpload={true} projectId={currentProjectId ? String(currentProjectId) : null} />
            )}
        </div>
    );
};

export default UploadDraftPage;