import React from 'react';
import PropTypes from "prop-types";

const LegalLayoutFeature = (props) => {
    const { children, title, filename, date } = props;
    return (
        <div className="legal_content">
            <h1 className="legal_doc_title">{title}</h1>
            <div className="legal_doc_bar">
                <div className="legal_doc_date">{date}</div>
                <a className="legal_doc_dwnl" href={`/static/pdf/${filename}`} download="">download</a>
            </div>
            <div className="legal_doc ff-raleway">{children}</div>
            {/*<embed src={`/static/pdf/${docs[doc].filename}`} type="application/pdf" width="100%" height="600px"></embed>*/}
        </div>
    );
};

LegalLayoutFeature.prototype = {
    children: PropTypes.element,
    title: PropTypes.string,
    filename: PropTypes.string,
    date: PropTypes.string,
}

export default LegalLayoutFeature;