import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import cl from 'classnames';
import {useOutletContext} from "react-router";
import {useNavigate, useParams} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../hooks/use-store";
import { useLoadPresentationQuery } from "../../store/api/presentation.api";
import PreloadFonts from "../blocks/preload-fonts.block";
import ProjectPanel from "./blocks/project-panel.block";
import {svgIcons} from "../create/data/svg-icons.data";
import {RenameModal} from "../../components/template";
import SlideList from "./blocks/slide-list.block";
import {setGlobalRequest, updateSetup} from "../../store/slice/common.slice";
import {route_path} from "../../utils/route_path";
import {ONBOARDING_TYPE} from "../../pages/create/data/onboarding.data";
import {useAddSlideMutation} from "../../store/api/slide.api";
import ResultMap from "./blocks/result-map.block";
import SharingModal from "./blocks/sharing-modal.block";

const LANG = {
    add_new_slide: "Add new slide",
}

export const SlideEditContent = createContext({});

const ProjectIdPage = () => {
    const { context, onboardingMessage } = useOutletContext();
    const params = useParams();
    const [isModalOpen, setModalOpen] = useState({
        rename: false
    });
    const [showShareModal, setShowToggleModal] = useState(false);
    const [isEditPointOpen, setIsEditPointOpen] = useState(false);
    const [isOpenRenameModal, setIsOpenRenameModal] = useState(false)
    const slidesRefs = {};
    const isGlobalRequest = useAppSelector(state => state.commonState.isGlobalRequest);
    const [ addSlideMutation ] = useAddSlideMutation();
    const { data: presentationUpdate, isSuccess, isLoading, refetch } = useLoadPresentationQuery({ projectId: String(params.projectId)});
    const dispatch = useAppDispatch();
    const { setup } = useAppSelector((state) => state.commonState);
    const navigate = useNavigate();
    const [currentOnboardingKey] = onboardingMessage(ONBOARDING_TYPE);
    const readonly = false;

    function dispatchSetup(data) {
        dispatch(updateSetup(data));
    }

    const errorsCount = useMemo(() => {
        return Object.values(presentationUpdate?.queue ?? {})?.reduce((errors, slide) => {
            for (const slideItem of slide) {
                if (slideItem.error.hasOwnProperty('message')) {
                    errors += 1;
                    break;
                }
            }
            return errors;
        }, 0);
    }, [presentationUpdate?.result?.queue]);
    const presentationQuery = useCallback((presentation, callback) => {
        if (presentation) {
            const isPresentationReady = Object.values(presentation.queue_ready).reduce((result, slider, slideIndex) => {
                if(!presentation.slides_info[slideIndex]?.empty && !slider?.ready ) result = false;
                return result;
            }, true);
            if(!isPresentationReady || !Object.keys(presentation?.process?.option?.[0] ?? {}).length) {
                setTimeout( () => refetch().unwrap().then((presentation) => presentation?.result && presentationQuery(presentation.result, () => {})), 2000);
                return;
            }
            if (presentation?.slides_info?.[0]?.slide_type && localStorage.getItem(`${ONBOARDING_TYPE}_process`) !== 'finish') {
                dispatchSetup({presentationType: presentation?.slides_info?.[0]?.slide_type});
            }
            if (!presentation && presentation.download && !presentation.download && !setup.isFromScratch) {
                let firstPaintedPreview = +Object.keys(presentation.download.thumb)[0] + 1;
            }
            dispatch(setGlobalRequest({ requesting: false }));
             if (typeof (callback) === 'function') callback();
        } else {
            refetch();
            setTimeout(() => () => presentationQuery(presentation, callback), 2000);
        }
    }, [presentationUpdate]);

    useEffect(() => {
        isSuccess && presentationQuery(presentationUpdate?.result, () =>{});
    }, [presentationQuery, presentationUpdate, isSuccess]);

    function queuePing(){
        refetch().unwrap().then((fetchedPresentation) => {
            if(!fetchedPresentation?.result?.queue_ready || !fetchedPresentation?.result?.slides_info){
                setTimeout(() => queuePing(), 2000);
            }
        })
    }
    function toggleModalHandler(modalName, status = undefined){
        setModalOpen((prevState) => ({
            ...prevState,
            [modalName]: typeof status === "undefined" ? !prevState[modalName] : status,
        }));
    }

    function addSlide() {
        if(isGlobalRequest) return;
        dispatch(setGlobalRequest({ requesting: true }));
        const lastSlideIndex = Object.keys(presentationUpdate?.result?.slides_info).length;
        const fd = new FormData();
        fd.append('index', String(lastSlideIndex));
        addSlideMutation({ projectId: params.projectId, body: fd }).unwrap().then((addSlide) => {
            addSlide.status && queuePing();
        }).catch((error) => {
            console.error('[ADD SLIDE ERROR]: ', error);
        })
    }
    function showStyleChange() {
        navigate(`${route_path.setupStyle}${String(params.projectId)}/`);
    }

    const handleShowShareModal = () => {
        amplitude.getInstance().logEvent('click share button', {'interaction location': 'file page'})
        setShowToggleModal(true);
    }

    if(isLoading) return <></>;
    return (
        <>
            <PreloadFonts context={context} />
            <div className='gen_main'>
                    {isOpenRenameModal && (<div>
                        <RenameModal
                            close={() => {setIsOpenRenameModal( false)}}
                            fileName={presentationUpdate?.result.process.file_info?.filename}
                            projectId={Number(params.projectId)}
                        />
                    </div>)}
                <div className="gen_content"
                     style={{zIndex: Number(currentOnboardingKey()) > 4 && Number(currentOnboardingKey()) < 8 ? 30 : 1}}>
                    <div className="gen_content__create_slides">
                        <div className="create_slides_title">
                            {presentationUpdate?.result.process.file_info?.filename ?? 'Undefined'}
                            {presentationUpdate?.result && <div
                                className="create_slides_rename"
                                onClick={() => setIsOpenRenameModal(true)}>{svgIcons.rename}</div>
                            }
                        </div>
                        <div className="create_slides">
                            {
                                Object.keys(presentationUpdate?.result.slides_info ?? {}).length > 0 &&
                                <ResultMap presentation={presentationUpdate?.result} slidesRefs={slidesRefs}  addSlide={addSlide} />
                            }
                        <SlideEditContent.Provider value={{isEditPointOpen, setIsEditPointOpen, refetch, presentationQuery, presentation: presentationUpdate?.result}} >
                            <SlideList slidesRefs = {slidesRefs} />
                        </SlideEditContent.Provider>
                        </div>
                        {
                            !readonly &&
                            <div className={cl("gen_btn_second create_slides_add", isGlobalRequest && 'border-general_background_MIII_400_dark cursor-not-allowed animate-pulse text-general_background_MIII_400_dark')} onClick={addSlide}><span className="icon">+</span>{LANG.add_new_slide}</div>
                        }
                    </div>
                </div>
                {presentationUpdate?.result && showShareModal && <SharingModal shared_file={presentationUpdate?.result?.process?.file_info?.share} close={() => setShowToggleModal(false)} place="page" />}
                { presentationUpdate?.result && <ProjectPanel
                    projectId={params?.projectId}
                    presentation={presentationUpdate?.result}
                    presentationOption={presentationUpdate?.result?.process?.option?.[0]}
                    presentationName={presentationUpdate?.result?.process?.file_info?.filename}
                    isEditPointOpen={isEditPointOpen}
                    queueLength={Object.keys(presentationUpdate?.result?.queue?.length ?? {})}
                    isNotShareAvailable={(Object.keys(presentationUpdate?.result?.queue ?? {}).length === 1 && presentationUpdate?.result?.slides_info?.[0]?.empty) || Object.keys(presentationUpdate?.result?.queue ?? {}).length === errorsCount}
                    onShowShareModal={handleShowShareModal}
                />}
            </div>
        </>
    );
};

export default ProjectIdPage;