import React from 'react';
import { useOutletContext } from "react-router";

import {HorizontalCard, InfoBox} from "../../components/template";
import Header from "../../common/header";
import {Button, Section} from "../../components/ui";
import {route_path} from "../../utils/route_path";

const FEATURES_INFO_BOX_DATA = {
    title: <span>So much <span className="ff-emoji">&#129502;</span> more <br />than a&nbsp;presentation<br />maker</span>,
    description: <span className="feature_info_section_text">
                    But what makes it so? Keep scrolling to learn about all <br />the&nbsp;amazing features Wonderslide has in store!
                </span>,
    link: route_path.create,
    bgImageSrcSet: '/static/i/features_page/features_info_bg.webp 1x, /static/i/features_page/features_info_bg@2x.webp 2x',
    height: 728,
}
const FEATURED_CARDS_DATA = [
    {
        title: '1. Limitless compatibility',
        description: <span>Want to&nbsp;fine-tune the&nbsp;slides you just generated? Any&nbsp;presentation created in Wonderslide can be further edited in your software of choice, like PowerPoint or Google Slides</span>,
        picture: '/static/i/features_page/limited_compatibility.svg'
    },
    {
        title: '2. Automatic icon/image selection',
        description: <span>Our&nbsp;ever-expanding media library hosts a&nbsp;unique collection of high-quality icons and images, curated by top-class graphic designers and photographers. Your&nbsp;presentations will always look sleek, with no extra effort on your&nbsp;part</span>,
        picture: '/static/i/features_page/image_selection.svg'
    },
    {
        title: '3. Design that’s tailored to your needs',
        description: <span>Pick your color theme and the&nbsp;stylistic shape that will help set the&nbsp;tone of your&nbsp;presentation. Or, easily create your&nbsp;own&nbsp;theme using the&nbsp;Color&nbsp;Picker. Make every slide stand out with just a&nbsp;few&nbsp;clicks</span>,
        picture: '/static/i/features_page/design_tailored.svg'
    },
    {
        title: '4. Seamless text-to-bullet conversion',
        description: <span>Create a&nbsp;presentation from scratch and let AI handle bullet points for you, generating them on the&nbsp;go and helping streamline your&nbsp;thought process.</span>,
        picture: '/static/i/features_page/text_to_bullet.svg'
    },
    {
        title: '5. Stylish tables and graphs',
        description: <span>Wonderslide automatically designs tables, graphs, and diagrams, utilizing harmonious colors and a&nbsp;sleek, easily-readable style</span>,
        picture: '/static/i/features_page/stylish_table.svg'
    },
    {
        title: '6. Automatic logo placement',
        description: <span>Our ever-expanding media library hosts a&nbsp;unique collection of high-quality icons and images, curated by top-class graphic designers and photographers. Your presentations will always look sleek, with no extra effort on your&nbsp;part</span>,
        picture: '/static/i/features_page/automatic_logo.svg'
    },
    {
        title: '7. Curated fonts library',
        description: <span>Upload your&nbsp;company logo and the&nbsp;system will automatically add it to&nbsp;the&nbsp;slide, always suggesting the&nbsp;optimal placement</span>,
        picture: '/static/i/features_page/curated_fonts.svg'
    },
    {
        title: '8. Multiple design options',
        description: <span>Designing your&nbsp;slides doesn’t have to&nbsp;be&nbsp;tedious. If you don’t like a&nbsp;particular slide’s layout — easily change it up with just a&nbsp;few&nbsp;clicks</span>,
        picture: '/static/i/features_page/multiple_design.svg'
    },
    {
        title: '9. Integrated slide editing',
        description: <span>Fine-tune your presentation with ease: simply click on a&nbsp;highlighted element — be it text, icon or image — and make the&nbsp;desired changes on the fly</span>,
        picture: '/static/i/features_page/slide_editing.svg'
    },
    {
        title: '10. Easy, secure sharing',
        description: <span>Instantly share your&nbsp;presentation with a&nbsp;link, no download necessary. You can also protect it with a&nbsp;PIN for increased security and discretion. Our&nbsp;cutting-edge encryption will ensure no unwanted eyes will see your&nbsp;work</span>,
        picture: '/static/i/features_page/secure_sharing.svg'
    },
    {
        title: '11. Full PowerPoint integration',
        description: <span>Experience seamless integration with PowerPoint: every element imported from Wonderslide — be it text, icons, or graphs — is made to&nbsp;be&nbsp;fully backwards-compatible</span>,
        picture: '/static/i/features_page/full_power_point.svg'
    }
];

const FeaturesPage = () => {
    const {context, updateData} = useOutletContext();
    return (
        <div className="features_page">
            <InfoBox header={<Header context={context} updateData={updateData}
                                     isTransparent/>}  {...FEATURES_INFO_BOX_DATA}  />
            <Section className="feature_page_section">
                {FEATURED_CARDS_DATA.map((card, index) => <HorizontalCard key={index} {...card}
                                                                          isReverse={!(index % 2)}/>)}
            </Section>
            <Section className="feature_page__get_section ff-raleway">
                    <h2>Try it now. It's{' '}
                        <strong className="feature_page__get_section__highlight"><span className="feature_page__get_section__text">simple</span></strong></h2>
                  <Button label="Get started" size="extra_middle" rounded="large" link={route_path.create}  isLink />
            </Section>
        </div>
    );
};

export default FeaturesPage;