import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {useOutletContext} from "react-router";

import SharingModal from '../../sharing/SharingModal';
import RenameModal from '../../common/RenameModal';
import DeleteModal from '../../common/DeleteModal';
import {
    useLazyCopyPresentationQuery, useLazyLoadPresentationQuery,
    useLoadPresentationsQuery,
    useRemovePresentationMutation,
    useRenamePresentationMutation
} from "../../store/api/presentation.api";
import { Skeleton } from "../../components/ui";
import FileItem from "./blocks/file-item.block";
import { useToastContext } from "../../hooks/use-toast-provider";
import {usePresentationDownload} from "../../hooks/use-presentation-download";


const ProjectsPage = () => {
    const { TagManager, context, setTrialPopup, setSendConfirmNotify, setResendNotify } = useOutletContext();
    const { getToast } = useToastContext();
    const [ loadPresentationQuery ] = useLazyLoadPresentationQuery();
    const [redirectToCreate, setRedirectToCreate] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showSharingModal, setShowSharingModal] = useState(false);
    const { downloadHandler } = usePresentationDownload({
        tagManager: TagManager,
        context,
        setResendNotify,
        setTrialPopup,
        getToast,
    });
    const { data: presentations, isLoading, refetch: getFiles } = useLoadPresentationsQuery();
    const [ removePresentation ] = useRemovePresentationMutation();
    const [renamePresentation] = useRenamePresentationMutation();
    const [copyPresentation] = useLazyCopyPresentationQuery();
    const [editedFileIdx, setEditedFileIdx] = useState(-1);
    const [hasFilesLimit, setHasFilesLimit] = useState(context.product_balance?.product?.is_trial || !context.product_balance?.is_active);

    const deleteFile = (id) => {
        removePresentation({ projectId: id }).unwrap().then(() => {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'file_delete'
                }
            });
            amplitude.getInstance().logEvent('file delete');
        }).finally(() => {
            setShowDeleteModal(false);
        });
    }

    const renameFile = async (id, name) => {
        const fd = new FormData();
        fd.append('description', name);
        renamePresentation({ projectId: id, body: fd }).unwrap().then((responseRename) => {
            setShowRenameModal(false);
            TagManager.dataLayer({
                dataLayer: {
                    event: 'file_rename',
                    file_interaction_location: 'file_list'
                }
            })
            amplitude.getInstance().logEvent('file rename', {
                'file interaction location': 'file list'
            });
        });
    }

    const copyFile =  (id) => {
        if (hasFilesLimit && presentations?.files.length > 2) {
            setTrialPopup({ type: 'trial limit' });
            return;
        }
        copyPresentation({ projectId: String(id)}).unwrap().then((presentationResult) => {
           if(presentationResult.status){
               getFiles().unwrap().then(() => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'file_create_duplicate'
                        }
                    });
                   amplitude.getInstance().logEvent('file create duplicate');
               });
           }else{
               console.error('[COPY PRESENTATION ERROR]');
           }
        });
    }
    function downloadFile(projectId, noCheckTrial){
        loadPresentationQuery({ projectId }).unwrap().then((presentationInfo) => {
            if(presentationInfo.status){
               downloadHandler({ presentation: presentationInfo.result, retry: 0, ignore_code: noCheckTrial });
            }
        })

    }

    const onClickMenuItem = async (type, i) => {
        const file = presentations.files[i];
        if (type === 'download') {
            downloadFile(file.project_id);
            TagManager.dataLayer({
                dataLayer: {
                    event : 'click_file_download',
                    file_interaction_location : 'file_list'
                }
            });
        }
        if (type === 'copy') {
            copyFile(file.project_id);
        }
        if (type === 'delete') {
            setEditedFileIdx(i);
            setShowDeleteModal(true);
        }
        if (type === 'rename') {
            setEditedFileIdx(i);
            setShowRenameModal(true);
        }
        if (type === 'share') {
            setEditedFileIdx(i);
            setShowSharingModal(true);
            amplitude.getInstance().logEvent('click share button', {'interaction location': 'file list'})
        }
    }

    if (redirectToCreate) return <Navigate to="/create/" />

    let shared_file = null;
    if (showSharingModal) {
        if (presentations.files[editedFileIdx].sharing.status) {
            shared_file = {
                is_private: presentations.files[editedFileIdx].sharing.settings.is_private,
                need_pin: presentations.files[editedFileIdx].sharing.settings.need_pin,
                short_url: presentations.files[editedFileIdx].sharing.url,
            }
        }
    }
    return(
        <div className="gen files">
            <div className="gen_main">
                <div className="gen_content">
                    <div className="files_content">
                        <div className="files_header">
                            <h1 className="files_title">My presentations</h1>
                        </div>

                        <div className="files_list">
                            {isLoading ? Array(8).fill('_').map((_, index) => <div  key={index}  className="file"> <Skeleton width="220px" height="124px" /><Skeleton className="my-[10px]" width="150px" height="14px" /> <Skeleton width="80px" height="10px" /></div>):
                                presentations?.files.map((file, i) => (
                                    <FileItem file={file} key={i} lastOpenedPresentation={null} onClickMenuItem={(type) => {onClickMenuItem(type, i)}} />
                                ))}
                        </div>
                    </div>
                </div>

                {
                    (hasFilesLimit && presentations?.files?.length > 2) &&
                    <div className="gen_panel">
                        {
                            <div className="limit_msg">
                                <div className="limit_msg_title">Presentation creation limit reached</div>
                                <div className="limit_msg_text">
                                    <p>You've hit the&nbsp;maximum number of&nbsp;presentations with the&nbsp;trial plan.</p>
                                    <p>Upgrade to one of&nbsp;our plans to&nbsp;create an&nbsp;<strong>unlimited number</strong> of&nbsp;presentations.</p>
                                </div>
                                <Link to="/pricing/" className="main_btn limit_msg_btn">View plans</Link>
                            </div>
                        }
                    </div>
                }

                {
                    (hasFilesLimit && presentations?.files.length > 0 && presentations?.files.length < 3) &&
                    <div className="gen_panel">
                        {
                            <div className="limit_counter">
                                <div className="limit_counter_title">
                                    Presentations available to create
                                    <div className="hint info_icon">
                                        <div className="hint_text hint_text-arrow">With the trial plan, you can create<br/>up to 3 presentations</div>
                                    </div>
                                </div>
                                <div className="limit_counter_items">
                                    <div className="limit_counter_item used" />
                                    <div className={'limit_counter_item' + (presentations?.files?.length == 2 ? ' used' : '')} />
                                    <div className="limit_counter_item" />
                                </div>
                                <div className="limit_counter_left">
                                    {3 - presentations?.files.length}/3
                                </div>
                                <Link to="/pricing/" className="main_btn limit_msg_btn">Get unlimited access</Link>
                            </div>
                        }
                    </div>
                }
            </div>
            {showDeleteModal && <DeleteModal close={() => {setShowDeleteModal(false); setEditedFileIdx(-1)}} deleteElement={() => {deleteFile(presentations?.files?.[editedFileIdx]?.project_id)}} />}
            {showRenameModal && <RenameModal close={() => {setShowRenameModal(false); setEditedFileIdx(-1)}} renameFile={renameFile} fileName={presentations?.files?.[editedFileIdx].description} projectId={presentations?.files?.[editedFileIdx].project_id} names={presentations?.files.map(file => file.description.toLowerCase().replace(/.pptx$|.json$/, ''))} needRequest={false} />}
            {showSharingModal && <SharingModal shared_file={shared_file} context={context} projectId={presentations?.files?.[editedFileIdx].project_id} close={() => {setShowSharingModal(false)}} setSystemMessage={(msg) => {getToast({systemMessage: {type: 'success', msg}})}} onSave={getFiles} place='list' />}
       </div>
    );
};




export default ProjectsPage;