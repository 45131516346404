import {SharingModal} from "../../../components/template";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";
import React from "react"

const SharingModalBlock = (props) => {
    const { close, shared_file, place } = props;
    const { context } = useOutletContext();
    const params = useParams();
    return (
        <SharingModal context={context} shared_file={shared_file} close={close} projectId={params.projectId} place={place} />
    );
};

export default SharingModalBlock;