import React from "react";

export const svgIcons = {
    addAssets: <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17.004 5H9C7.162 5 5.414 5.737 4.076 7.076C2.737 8.415 2 10.163 2 12C2 13.838 2.737 15.586 4.076 16.924C5.414 18.263 7.162 19 9 19H17V17H9C7.697 17 6.45 16.471 5.49 15.51C4.529 14.55 4 13.303 4 12C4 10.698 4.529 9.451 5.49 8.49C6.45 7.529 7.697 7 9 7H17V6L17.001 7H17.004C17.794 7 18.543 7.314 19.113 7.886C19.684 8.457 19.999 9.208 20 10.002C19.9997 10.3947 19.9214 10.7835 19.7696 11.1457C19.6179 11.5079 19.3957 11.8363 19.116 12.112C18.8392 12.3922 18.5098 12.6148 18.1466 12.7672C17.7835 12.9196 17.3938 12.9987 17 13H9C8.86963 12.9984 8.74086 12.9711 8.62108 12.9196C8.50129 12.8681 8.39286 12.7935 8.302 12.7C8.20794 12.609 8.13283 12.5003 8.08099 12.3802C8.02916 12.2601 8.00163 12.1308 8 12C8 11.748 8.11 11.493 8.301 11.302C8.39171 11.2079 8.50021 11.1327 8.6202 11.0809C8.7402 11.029 8.86929 11.0015 9 11H17V9H9C8.21 9 7.459 9.315 6.886 9.889C6.314 10.461 6 11.211 6 12C6 12.789 6.314 13.54 6.888 14.114C7.16401 14.3939 7.49277 14.6164 7.85529 14.7685C8.21781 14.9205 8.60688 14.9992 9 15H17.001C17.6572 14.9995 18.3068 14.8691 18.9123 14.6163C19.5178 14.3635 20.0673 13.9933 20.529 13.527C20.9948 13.0652 21.3644 12.5156 21.6167 11.9101C21.8689 11.3047 21.9988 10.6552 21.9987 9.99929C21.9986 9.34337 21.8686 8.69395 21.6161 8.08854C21.3637 7.48313 20.9939 6.93371 20.528 6.472C20.0674 6.0055 19.5186 5.63515 18.9137 5.38246C18.3087 5.12977 17.6596 4.99976 17.004 5Z" fill="#00BA88"/></svg>,
    image: <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7.49902 11C8.32745 11 8.99902 10.3284 8.99902 9.5C8.99902 8.67157 8.32745 8 7.49902 8C6.6706 8 5.99902 8.67157 5.99902 9.5C5.99902 10.3284 6.6706 11 7.49902 11Z"/><path d="M10.499 14L8.99902 12L5.99902 16H17.999L13.499 10L10.499 14Z"/><path d="M19.999 4H3.99902C2.89602 4 1.99902 4.897 1.99902 6V18C1.99902 19.103 2.89602 20 3.99902 20H19.999C21.102 20 21.999 19.103 21.999 18V6C21.999 4.897 21.102 4 19.999 4ZM3.99902 18V6H19.999L20.001 18H3.99902Z"/></svg>,
    table: <svg width="20" height="18" viewBox="0 0 20 18" fill="none"><path d="M2 18H17.893C18.996 18 19.893 17.103 19.893 16V2C19.893 0.897 18.996 0 17.893 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18ZM2 16V11H6V16H2ZM12 4V9H8V4H12ZM6 4V9H2V4H6ZM8 16V11H12V16H8ZM14 16V11H17.894V16H14ZM17.893 9H14V4H17.893V9Z"/></svg>,
    chart: <svg width="14" height="16" viewBox="0 0 14 16" fill="none"><path d="M4 2H6V16H4V2ZM8 4H10V16H8V4ZM12 0H14V16H12V0ZM0 8H2V16H0V8Z"/></svg>,
    close: <svg width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94283L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"/></svg>,
    rename: <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.6967 4.93392C12.9487 4.68192 13.0874 4.34725 13.0874 3.99125C13.0874 3.63525 12.9487 3.30059 12.6967 3.04859L11.6394 1.99125C11.3874 1.73925 11.0527 1.60059 10.6967 1.60059C10.3407 1.60059 10.006 1.73925 9.75469 1.99059L2.66669 9.05659V11.9999H5.60869L12.6967 4.93392ZM10.6967 2.93392L11.7547 3.99059L10.6947 5.04659L9.63735 3.98992L10.6967 2.93392ZM4.00002 10.6666V9.60992L8.69335 4.93125L9.75069 5.98859L5.05802 10.6666H4.00002ZM2.66669 13.3333H13.3334V14.6666H2.66669V13.3333Z" fill="#00A97B"/></svg>,
    arrowLeft: <svg width="14" height="15" viewBox="0 0 14 15" fill="currentColor"><path d="M8.70694 12.6108L4.41394 8.31784H13.9999V6.31784H4.41394L8.70694 2.02484L7.29294 0.61084L0.585938 7.31784L7.29294 14.0248L8.70694 12.6108Z"/></svg>,
    arrowRight: <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor"><path d="M5.293 12.293L6.707 13.707L13.414 6.99997L6.707 0.292969L5.293 1.70697L9.586 5.99997H0V7.99997H9.586L5.293 12.293Z"/></svg>,
    undo: <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor"><path d="M5 5.31787H11C12.654 5.31787 14 6.66387 14 8.31787C14 9.97187 12.654 11.3179 11 11.3179H8V13.3179H11C13.757 13.3179 16 11.0749 16 8.31787C16 5.56087 13.757 3.31787 11 3.31787H5V0.317871L0 4.31787L5 8.31787V5.31787Z"/></svg>,
    menu: <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M7.99996 6.66675C7.26663 6.66675 6.66663 7.26675 6.66663 8.00008C6.66663 8.73341 7.26663 9.33341 7.99996 9.33341C8.73329 9.33341 9.33329 8.73341 9.33329 8.00008C9.33329 7.26675 8.73329 6.66675 7.99996 6.66675ZM7.99996 2.66675C7.26663 2.66675 6.66663 3.26675 6.66663 4.00008C6.66663 4.73341 7.26663 5.33341 7.99996 5.33341C8.73329 5.33341 9.33329 4.73341 9.33329 4.00008C9.33329 3.26675 8.73329 2.66675 7.99996 2.66675ZM7.99996 10.6667C7.26663 10.6667 6.66663 11.2667 6.66663 12.0001C6.66663 12.7334 7.26663 13.3334 7.99996 13.3334C8.73329 13.3334 9.33329 12.7334 9.33329 12.0001C9.33329 11.2667 8.73329 10.6667 7.99996 10.6667Z"/></svg>,
    copy: <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M20 2H10C8.897 2 8 2.897 8 4V8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22H14C15.103 22 16 21.103 16 20V16H20C21.103 16 22 15.103 22 14V4C22 2.897 21.103 2 20 2ZM4 20V10H14L14.002 20H4ZM20 14H16V10C16 8.897 15.103 8 14 8H10V4H20V14Z"/></svg>,
    delete: <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"/><path d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"/></svg>,
    delete2: <svg width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94283L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#DFDFDF"/></svg>,
    info: <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M7.99992 1.33325C4.32392 1.33325 1.33325 4.32392 1.33325 7.99992C1.33325 11.6759 4.32392 14.6666 7.99992 14.6666C11.6759 14.6666 14.6666 11.6759 14.6666 7.99992C14.6666 4.32392 11.6759 1.33325 7.99992 1.33325ZM7.99992 13.3333C5.05925 13.3333 2.66659 10.9406 2.66659 7.99992C2.66659 5.05925 5.05925 2.66659 7.99992 2.66659C10.9406 2.66659 13.3333 5.05925 13.3333 7.99992C13.3333 10.9406 10.9406 13.3333 7.99992 13.3333Z"/><path d="M7.33325 7.33341H8.66659V11.3334H7.33325V7.33341ZM7.33325 4.66675H8.66659V6.00008H7.33325V4.66675Z"/></svg>,
    arrow2: <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M10.862 6.19531L7.99998 9.05731L5.13798 6.19531L4.19531 7.13798L7.99998 10.9426L11.8046 7.13798L10.862 6.19531Z"/></svg>
};