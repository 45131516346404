import React, {useEffect, useState} from "react";
import {
    hexToHsva,
    hexToRgba,
    hsvaToHex,
    hsvaToRgba,
    rgbaToHex,
    rgbaToHsva,
    rgbaToRgb,
    ShadeSlider,
    Wheel
} from "@uiw/react-color";
import {contrast, xhrHeaders} from "../../../../../lib/utils";
import t from "../../../../../lib/lng";
import cl from "classnames";
import { useSetColorMutation } from "../../../../store/api/preset.api";
import {useToastContext} from "../../../../hooks/use-toast-provider";

const ColorPicker = ({selectedColor, colorpickerRef, updateData, colors, baseColors, close, selectOption}) => {
    const [color, setColor] = useState(null);
    const [inputHex, setInputHex] = useState('');
    const [inputRgb, setInputRgb] = useState('');
    const [savingColor, setSavingColor] = useState(false);
    const [colorExists, setColorExists] = useState(false);
    const [ setColorMutation ] = useSetColorMutation();
    const {getToast} = useToastContext();

    useEffect(() => {
        if (!color) return;
        setInputHex(color.hex);
        setInputRgb(Object.values(color.rgb).toString());
    }, [color]);

    useEffect(() => {
        setColor({
            rgb: rgbaToRgb(hexToRgba(selectedColor)),
            hex: selectedColor,
            hsva: hexToHsva(selectedColor)
        });
    }, [selectedColor]);

    const onChangeShade = v => {
        const newHsva = {
            h: color.hsva.h,
            s: color.hsva.s,
            v: v.v,
            a: color.hsva.a
        };

        setColor({
            rgb: rgbaToRgb(hsvaToRgba(newHsva)),
            hex: hsvaToHex(newHsva),
            hsva: newHsva,
        });
    }

    const onChangeHex = e => {
        let val = e.target.value.replaceAll('#', '');
        if (val.match(/[^#a-fA-F0-9]/gm) || val.length > 7) return;
        if (!val) setInputRgb('');
        val = '#' + val;
        setInputHex(val);
        if (!isValidHex(val)) return;
        setColor({
            rgb: rgbaToRgb(hexToRgba(val)),
            hex: val,
            hsva: hexToHsva(val),
        });
    }

    const onChangeRgb = e => {
        let val = e.target.value;
        let valArr = val.split(',');
        if (val.match(/[^\d,]/gm) || val.length > 11 || valArr.length > 3 || valArr.some(i => i.length > 3) || valArr.some(i => i > 255)) return;

        if (val.length > inputRgb.length && valArr.length < 3 && (valArr[valArr.length-1].length === 3 || valArr[valArr.length-1] > 25)) {
            val += ',';
        }
        if (!val) setInputHex('');
        setInputRgb(val);
        if (!isValidRgb(val)) return;

        val = val.split(',');
        const rgb = {
            r: +val[0],
            g: +val[1],
            b: +val[2],
            a: 1
        };

        setColor({
            rgb: rgbaToRgb(rgb),
            hex: rgbaToHex(rgb),
            hsva: rgbaToHsva(rgb),
        });
    }

    const isValidHex = (hex) => {
        return !!hex.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
    }

    const isValidRgb = (rgb) => {
        rgb = rgb.split(',');
        if (rgb.length !== 3) return false;
        let res = true;
        rgb.forEach(item => {
            if (isNaN(parseInt(item)) || parseInt(item) < 0 || parseInt(item) > 255) res = false;
        });
        return res;
    }

    const saveColor = async () => {
        if (colors && colors.some(i => i.value.toUpperCase() === color.hex.replace('#', '').toUpperCase()) ||
            baseColors.some(i => i.value.toUpperCase() === color.hex.replace('#', '').toUpperCase())) {
            setColorExists(true);
            setTimeout(() => {
                setColorExists(false);
            }, 4000);
            return;
        }
        const ratioWhite = +contrast([255,255,255], Object.values(color.rgb)).toFixed(2),
            ratioBlack = +contrast([0,0,0], Object.values(color.rgb)).toFixed(2),
            textColor = [];
        let data = {
            color: color.hex.replace('#', '').toUpperCase()
        };

        if (ratioWhite > 2) textColor.push('FFFFFF');
        if (ratioBlack > 4.2) textColor.push('000000');
        if (ratioWhite <= 2 && ratioBlack <= 4.2) {
            if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
            else textColor.push('000000');
        }
        data.text_color = textColor;
        setSavingColor(true);
        setColorMutation(data).unwrap().then((resultOfSetColor) =>{
            if(resultOfSetColor.status){
                updateData(() => {
                    setSavingColor(false);
                    selectOption('color', data.color, true);
                    close();
                });
            }else{
                getToast({ systemMessage: { msg: resultOfSetColor.error }});
            }
        })
        // let response = await fetch('/set-color/', {
        //     method: 'post',
        //     body: JSON.stringify(data),
        //     headers: xhrHeaders()
        // });
        // response = await response.json();
        // if (response.status) {
        //     updateData(() => {
        //         setSavingColor(false);
        //         selectOption('color', data.color, true);
        //         close();
        //     });
        // }
    }

    return (
        <div className={cl('colorpicker', savingColor && ' saving')} ref={colorpickerRef}>
            {
                color &&
                <>
                    { colorExists && <div className="colorpicker_msg">{t.colorExists}</div> }
                    <Wheel color={color.hex} onChange={(color) => {setColor(color)}} />
                    <ShadeSlider hsva={color.hsva} onChange={(v)=>{onChangeShade(v)}} />
                    <div className="colorpicker_inputs">
                        <div className="colorpicker_input_wrap">
                            <div className="colorpicker_input_label">HEX</div>
                            <input className="colorpicker_input" value={inputHex} onChange={onChangeHex} />
                        </div>
                        <div className="colorpicker_input_wrap">
                            <div className="colorpicker_input_label">RGB</div>
                            <input className="colorpicker_input" value={inputRgb} onChange={onChangeRgb} />
                        </div>
                    </div>
                    <div className="colorpicker_btn" onClick={saveColor}>
                        <svg className="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V5L13 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18ZM4 2H8V4H10V2H12V6H4V2ZM4 10H14V16H4V10Z"/> </svg>
                        {savingColor ? t.Saving : t.SaveNewColor}
                    </div>
                </>
            }
        </div>

    );
}

export default ColorPicker;