 import React, {FC, useCallback, useEffect, useState} from 'react';
import { useOutletContext } from "react-router";

import { CommonOutletContext } from "../../../pages/common.outlet";
import { useLoadPresentationQuery } from "../../..//store/api/presentation.api";
import GenThemes from "./setup-style/gen-themes.block";
import GenPanel from "./setup-style/gen-style-panel.block";
import UploadProcessBlock from "../blocks/upload-draft/upload-process.block";
import { assetThemes } from "../../../data";
import { AssetThemeDataOption, AssetThemeThemeKey } from "../../../types/presentation.type";

type SetupStyleBlockProps = {
    projectId: string | null;
    isPPTXUpload?: boolean;
}
const DEFAULT_THEME = 'scandic';

const SetupStyleBlock: FC<SetupStyleBlockProps> = (props) => {
    const { projectId, isPPTXUpload = false } = props;
    const { context } = useOutletContext<CommonOutletContext>();
    const { data: presentation, isLoading } = useLoadPresentationQuery({ projectId: String(projectId) }, { skip: !projectId });
    console.debug(presentation, "!!! presentation")
    const defaultThemeKey = context.TAG ? 'classic': DEFAULT_THEME;
    const [selectedTheme, setSelectedTheme] = useState<string>(defaultThemeKey);
    const validateCurrentTheme = useCallback(({ theme, shape }: {theme: AssetThemeThemeKey, shape?: number}) => {
        if(presentation && assetThemes) {
            const option = presentation.result.process.option[0];
            if(theme === 'scandic' && option.image_data_source === 'sharp_abstract') return 'nordic essence';
            if(theme === 'classic' && shape) return Object.entries(assetThemes as unknown as AssetThemeDataOption)?.find(([_, themeOptions]) => themeOptions.shapes.includes(shape))?.[0] ?? defaultThemeKey;
            return theme;
        }else{
            return defaultThemeKey;
        }
    }, [presentation])
    useEffect(() => {
        const themeOptions = presentation?.result?.process?.option[0];
        if(themeOptions && Object.values(themeOptions ?? {}).length) {
            setSelectedTheme(validateCurrentTheme({theme: themeOptions.asset_theme, shape: themeOptions.shape_value}));
        }
    }, [presentation]);
    return (
        <div className="w-full">
            <div className="gen_main">
                <div className="gen_content w-full z-[0]">
                    <GenThemes
                    selectedTheme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                />
                    {isPPTXUpload && <UploadProcessBlock/>}
                </div>
                <GenPanel
                    isLoadingPresentation={isLoading}
                    theme={selectedTheme}
                    firstGeneratedSlide={presentation?.result?.download?.thumb[0]}
                    isPPTXUpload={isPPTXUpload}
                    isFirstStyleSettings={(presentation?.result?.source_files[0]?.slide_count===1 && presentation?.result?.slides_info?.[0].empty) || isPPTXUpload}
                    presentationOptions={presentation?.result?.process?.option?.[0]}
                />
            </div>
        </div>
    );
};

export default SetupStyleBlock;