import React, {useContext, useEffect, useRef, useState} from 'react';
import { useOutletContext } from "react-router";

import t from "../../../../lib/lng";
import { OnboardingMessage } from "../../../components/template";
import { ONBOARDING_MESSAGE_EDIT, ONBOARDING_TYPE } from "../../../pages/create/data/onboarding.data";
import {contrast, xhrHeaders} from "../../../../lib/utils";
import { usePrevious } from "../../../hooks/use-previous";
import {useAppDispatch, useAppSelector} from "../../../hooks/use-store";
import {useRemakeSlideDesignMutation, useSearchMediaMutation} from "../../../store/api/slide.api";
import {hexToRgba, rgbaToHex} from "@uiw/react-color";
import {themeColors} from "../../../data";
import {useLazyLoadPresentationQuery} from "../../../store/api/presentation.api";
import {SlideEditContent} from "../project-id.page";
import cl from "classnames";
import {setGlobalRequest} from "../../../store/slice/common.slice";
import {useClickOutside} from "../../../hooks/use-click-outside";

const SlideEditPanelBlock = (props) => {
    const {
        closeEditPanel,
        remake,
        projectId,
        editContent,
        option,
        allImages,
        shapes
    } = props;
    const { onboardingMessage, context: { product_balance }, setTrialPopup, TagManager } = useOutletContext();
    const {slideInfo, slideIndex, index, type } = editContent;
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentOnboardingStep, changeCurrentOnboardingStep] = onboardingMessage(ONBOARDING_TYPE);
    const [currentImage, setCurrentImage] = useState(null);
    const [textMaxSymbols, setTextMaxSymbols] = useState(0);
    const [textRowMaxSymbols, setTextRowMaxSymbols] = useState(0);
    const [numberMaxSymbols, setNumberMaxSymbols] = useState(0);
    const [digitMaxSymbols, setDigitMaxSymbols] = useState(0);
    const [text, setText] = useState(null);
    const [number, setNumber] = useState(null);
    const [digit, setDigit] = useState(null);
    const [editedText, setEditedText] = useState(null);
    const [editedNumber, setEditedNumber] = useState(null);
    const [editedDigit, setEditedDigit] = useState(null);
    const [saveAvail, setSaveAvail] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [foundImgs, setFoundImgs] = useState(null);
    const innerIds = useRef({});
    const iconsList = useRef();
    const prevInnerId = usePrevious(editContent.slideInfo.inner_id);
    const {isGlobalRequest} = useAppSelector((state) => state.commonState);
    const [ regenSlideMutation ] = useRemakeSlideDesignMutation();
    const { refetch: presRefetch} = useContext(SlideEditContent);
    const [ searchMediaMutation, { isFetching, isLoading } ] = useSearchMediaMutation();
    const loading = isLoading || isFetching;
    const imageType = slideInfo?.image_type ? slideInfo.image_type : 'Icon';
    const dispatch = useAppDispatch();
    const editBulletRef = useRef(null);
    let title = t.Editing+':';
    let imagesCount = 0;
    if (imageType === 'Photo' || imageType === 'Object') title = t.SelectNewImage;
    useClickOutside(editBulletRef, () => closeEditHandler());

    useEffect(() => {
        const switchOnEsc = e => {
            if (e.key === 'Escape' && foundImgs) {
                setFoundImgs(null);
                closeEditHandler();
            }
        }
        document.addEventListener('keydown', switchOnEsc);
        return () => {document.removeEventListener('keydown', switchOnEsc);};
    }, [foundImgs]);

    useEffect(() => {
        if (prevInnerId && prevInnerId == editContent.slideInfo.inner_id) return;
        setEditedText(null);
        setText(null);
        setTextMaxSymbols(0);
        setTextRowMaxSymbols(0);
        setEditedNumber(null);
        setNumber(null);
        setNumberMaxSymbols(0);
        setEditedDigit(null);
        setDigit(null);
        setDigitMaxSymbols(0);
        setSelectedImage(null);
        setCurrentImage(null);
        setSearchQuery('');
        setFoundImgs(null);
        if (type === 'shape') {
            if ((editContent.slideInfo.origin === 'bullet' || editContent.slideInfo.origin === 'metabullet')  && editContent.slideInfo.shapes) {
                editContent.slideInfo.shapes.forEach(shape => {
                    if (shape.extra_info.bullet_type === 'text') {
                        setEditedText(shape.processed_text);
                        setText(shape.processed_text);
                        setTextMaxSymbols(shape.max_rows * shape.row_max_symbols);
                        setTextRowMaxSymbols(shape.row_max_symbols);
                        innerIds.current.text = shape.inner_id;
                    }
                    if (shape.extra_info.bullet_type === 'number') {
                        setEditedNumber(shape.processed_text);
                        setNumber(shape.processed_text);
                        setNumberMaxSymbols(shape.row_max_symbols);
                        innerIds.current.number = shape.inner_id;
                    }
                    if (shape.extra_info.bullet_type === 'digit') {
                        setEditedDigit(shape.processed_text);
                        setDigit(shape.processed_text);
                        setDigitMaxSymbols(shape.row_max_symbols);
                        innerIds.current.digit = shape.inner_id;
                    }
                });
            }
            else {
                setEditedText(editContent.slideInfo.processed_text);
                setText(editContent.slideInfo.processed_text);
                setTextMaxSymbols(editContent.slideInfo.max_rows * editContent.slideInfo.row_max_symbols);
                setTextRowMaxSymbols(editContent.slideInfo.row_max_symbols);
                innerIds.current.text = editContent.slideInfo.inner_id;
            }

            if (editContent.slideInfo.icon && shapes && allImages.Icon) {
                let currentImage = shapes.filter(i => i.inner_id === editContent.slideInfo.icon)[0];
                if (currentImage.extra_info.image_set === 'local') {
                    setSelectedImage(editContent.slideInfo.all_icons[currentImage.extra_info.image_index]);
                    setCurrentImage(editContent.slideInfo.all_icons[currentImage.extra_info.image_index]);
                }
                else {
                    setSelectedImage(allImages.Icon[currentImage.extra_info.image_index]);
                    setCurrentImage(allImages.Icon[currentImage.extra_info.image_index]);
                }
                innerIds.current.icon = currentImage.inner_id;
            }
        }
        else if (type === 'image') {
            if (editContent.slideInfo.image_type === 'Icon') {
                setSelectedImage(allImages.Icon[editContent.slideInfo.extra_info.image_index]);
                setCurrentImage(allImages.Icon[editContent.slideInfo.extra_info.image_index]);
                innerIds.current.icon = editContent.slideInfo.inner_id;
            }
            else if (editContent.slideInfo.image_type === 'Object' || editContent.slideInfo.image_type === 'Photo') {
                const image = allImages?.[editContent.slideInfo.image_type]?.[editContent.slideInfo.extra_info.current_scope]?.[editContent.slideInfo.extra_info.image_index];
                setSelectedImage(image);
                setCurrentImage(image);
                innerIds.current.icon = editContent.slideInfo.inner_id;
            }
        }
    }, [editContent.slideInfo]);

    useEffect(() => {
        if (text !== editedText || number !== editedNumber || digit !== editedDigit || currentImage && selectedImage.image_id !== currentImage.image_id) {
            setSaveAvail(true);
        }
        else {
            setSaveAvail(false);
        }
    }, [editedText, editedNumber, editedDigit, selectedImage, currentImage]);

    useEffect(() => {
        if (selectedImage && currentImage && currentImage.image_id !== selectedImage.image_id) {
            save();
        }
    }, [selectedImage]);
    function closeEditHandler(){
        closeEditPanel();
        changeCurrentOnboardingStep({ step: 9, freeze: 9 })
    }
    const onChangeText = e => {
        setEditedText(addBreaks(e.target.value));

        function addBreaks(text) {
            let lines = text.split('\n');
            lines.forEach((line, i) => {
                if (line.length > textRowMaxSymbols) {
                    const delta = line.length - textRowMaxSymbols,
                        line1 = line.slice(0, -delta),
                        line2 = line.slice(-delta);
                    lines[i] = line1 + '\n' + line2;
                }
                if (lines[i].length > textRowMaxSymbols * 2) lines[i] = addBreaks(lines[i]);
            })
            return lines.join('\n');
        }
    }

    const onChangeNumber = e => {
        setEditedNumber(e.target.value);
    }

    const onChangeDigit = e => {
        setEditedDigit(e.target.value);
    }
    function getEditedImageData(){
        const imgFromSearch = !!selectedImage.path;
        const addData = {
            change_media: [{
                from_search: imgFromSearch,
                shape_inner_id: innerIds.current.icon,
                new_media_id: imgFromSearch ? selectedImage.path : selectedImage.image_id,
                media_type: editContent.slideInfo.image_type ? editContent.slideInfo.image_type : 'Icon',
            }],
        };
        if (selectedImage.scope) addData.change_media[0].scope = selectedImage.scope;
        if (imgFromSearch && editContent.slideInfo.extra_info?.current_scope) addData.change_media[0].scope = editContent.slideInfo.extra_info.current_scope;
        if (editContent.slideInfo=== 'Photo') {
            let photoType = ''
            if (editContent.slideInfo.shape_id === -1) photoType = 'background_image';
            else photoType = 'photo_fig';
            addData.__photoType = photoType;
        }
        return addData;
    }

    const save = () => {
        let element = '';
        let regenData = {'wonder_file_id': remake.wonder_file_id};
        //
        switch (type){
            case 'image': {
                regenData['action_type'] = 'change_media';
                regenData = {...regenData, ...getEditedImageData()}
                break;
            }
            case 'shape': {
                if ((text !== editedText || number !== editedNumber || digit !== editedDigit) && currentImage && selectedImage.image_id !== currentImage.image_id) {
                    regenData['action_type'] = 'change_entity';
                    regenData = {...regenData, ...{...getEditedTextData(), ...getEditedImageData()}};
                }
                else if (currentImage && selectedImage.image_id !== currentImage.image_id) {
                    regenData['action_type'] = 'change_media';
                    regenData = {...regenData, ...getEditedImageData()}
                }
                else {
                    regenData['action_type'] = 'change_text';
                    regenData = {...regenData, ...getEditedTextData()}
                }
                break;
            }
        }

        const ratioWhite = +contrast([255,255,255], Object.values(hexToRgba(rgbaToHex(option.color_value)))).toFixed(2),
            ratioBlack = +contrast([0,0,0], Object.values(hexToRgba(rgbaToHex(option.color_value)))).toFixed(2);
        let textColor = [];

        if (ratioWhite > 2) textColor.push('FFFFFF');
        if (ratioBlack > 4.2) textColor.push('000000');
        if (ratioWhite <= 2 && ratioBlack <= 4.2) {
            if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
            else textColor.push('000000');
        }
        if (themeColors.includes(rgbaToHex(option.color_value).toLowerCase()) || rgbaToHex(option.color_value).toLowerCase() === 'ed2626' && textColor.length > 1) textColor = ['FFFFFF'];
        regenData['text_color'] = JSON.stringify(textColor);

        if (type === 'shape') {
            element = editContent.slideInfo.origin;
        }
        else {
            if (editContent.slideInfo.image_type === 'Icon') element = 'decor_icon';
            if (editContent.slideInfo.image_type === 'Object') element = 'object';
            if (editContent.slideInfo.image_type === 'Photo') {
                if (editContent.slideInfo.shape_id === -1) element = 'photo_bg';
                else element = 'photo_fig';
            }
        }
        const formData = new FormData();
        for(const formKey of Object.keys(regenData)){
            formData.append(formKey, typeof regenData[formKey] === 'object' ? JSON.stringify(regenData[formKey]) : regenData[formKey]);
        }
        TagManager.dataLayer({
            dataLayer: {
                event: 'design_editing',
                editing_location: 'preview interaction',
                editable_element: element,
                processed_file_id: +projectId,
            }
        });
        amplitude.getInstance().logEvent('design editing done', {
            'element': element,
            'editing location': 'preview interaction',
            'processed file id': +projectId
        });
        regenSlideMutation({ params: { projectId }, body: formData }).unwrap().then((remakeResponse) => {
            dispatch(setGlobalRequest({ requesting: true }));
           if(remakeResponse.status){
               let retryChange = 0;
               presRefetch().catch(() => {
                 ++retryChange;
                 if(retryChange < 4 )  setTimeout(() => presRefetch(), 2000);
               }).finally(() => closeEditHandler());

               // reloadPresentation({ projectId }).unwrap().then((presentationData) => {
               //     if(presentationData.status){
               //         presentationQuery(presentationData.result);
               //         closeEditHandler();
               //     }else{
               //         setTimeout(() => refetch(), 2000);
               //     }
               // });
           }
        });

    }
    const getEditedTextData = () => {
        const addData = {
            edited_texts: []
        };

        if (editedText !== null) {
            addData.edited_texts.push({
                shape_inner_id: innerIds.current.text,
                text: editedText
            });
        }
        if (editedNumber !== null) {
            addData.edited_texts.push({
                shape_inner_id: innerIds.current.number,
                text: editedNumber
            });
        }
        if (editedDigit !== null) {
            addData.edited_texts.push({
                shape_inner_id: innerIds.current.digit,
                text: editedDigit
            });
        }
        return addData;
    }
    const searchImg = ( e ) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append('text', searchQuery);
        fd.append('media_type', (editContent.slideInfo.image_type || 'Icon').toLowerCase() + 's');
        if (editContent.slideInfo.extra_info?.scope) {
            editContent.slideInfo.extra_info.scope.forEach(i => {
                fd.append('media_categories[]', i);
            });
        }
        if (option.image_data_source) {
            fd.append('image_data_source', option.image_data_source);
        }
        searchMediaMutation({ body: fd }).unwrap().then((searchResponse) => {
            if (searchResponse.status && searchResponse.result.media.length) {
                setFoundImgs(searchResponse.result.media);
            }
        }).catch((error) => {
            console.error('[SEARCH IMAGE ERROR]: ', error);
        });
        // let response = await fetch('/search-media/', {
        //     method: 'post',
        //     body: fd,
        //     headers: xhrHeaders()
        // });
        // response = await response.json();
        // if (response.status && response.result.media.length) {
        //     setFoundImgs(response.result.media);
        // }
        // setTimeout(() => {
        //     setLoading(false);
        // }, 100);
    }

    return(
        <div ref={editBulletRef} className={'edit' + (loading || isGlobalRequest  ? ' disabled' : '')}>
            <div className="edit_inner">
                <div className="edit_header">
                    <div className="edit_title">{title}</div>
                </div>
                <div className="edit_inputs">
                    {
                        editedNumber !== null &&
                        <div className="edit_input_wrap">
                            <div className="edit_input_caption">{t.Number}</div>
                            <input className="edit_input" value={editedNumber} onChange={onChangeNumber} />
                            <div className={'edit_counter' + (editedNumber.length > numberMaxSymbols ? ' exceeded' : '')}>{editedNumber.length}/{numberMaxSymbols}</div>
                        </div>
                    }
                    {
                        editedDigit !== null &&
                        <div className="edit_input_wrap">
                            <div className="edit_input_caption">{t.Unit}</div>
                            <input className="edit_input" value={editedDigit} onChange={onChangeDigit} />
                            <div className={'edit_counter' + (editedDigit.length > digitMaxSymbols ? ' exceeded' : '')}>{editedDigit.length}/{digitMaxSymbols}</div>
                        </div>
                    }
                </div>
                {
                    editedText !== null &&
                    <div className="edit_input_wrap">
                        <div className="edit_input_caption">{t.Text}</div>
                        <textarea className="edit_field" value={editedText} onChange={onChangeText} autoFocus></textarea>
                        <div className={'edit_counter' + (editedText.length > textMaxSymbols ? ' exceeded' : '')}>{editedText.length}/{textMaxSymbols}</div>
                    </div>
                }
                {
                    type === 'shape' && editContent.slideInfo.edit_type.match(/rewrite|truncate/) 
                    //TODO: what is it doing && !regenSlideId.includes(editedShape.slideIdx)
                    &&
                    <div className="edit_chatgpt_msg">{editContent.slideInfo.edit_type === 'rewrite' ? t.chatgptInfo2 : t.textCutOffInfo}</div>
                }
                {
                    type === 'shape' && editContent.slideInfo.edit_type.match(/rewrite|truncate/) &&
                    <div className="edit_original">
                        <div className="edit_original_title">
                            {t.OriginalText}
                            <div className="edit_original_copy" title={t.Copy} onClick={() => {window.navigator.clipboard.writeText(editContent.slideInfo.original_text)}}>
                                <svg width="16" height="17" viewBox="0 0 16 17">
                                    <path d="M9.33594 5.83301H2.66927C1.93394 5.83301 1.33594 6.43101 1.33594 7.16634V13.833C1.33594 14.5683 1.93394 15.1663 2.66927 15.1663H9.33594C10.0713 15.1663 10.6693 14.5683 10.6693 13.833V7.16634C10.6693 6.43101 10.0713 5.83301 9.33594 5.83301Z"/>
                                    <path d="M13.3359 1.83301H6.66927C6.31565 1.83301 5.97651 1.97348 5.72646 2.22353C5.47641 2.47358 5.33594 2.81272 5.33594 3.16634V4.49967H10.6693C11.0229 4.49967 11.362 4.64015 11.6121 4.8902C11.8621 5.14025 12.0026 5.47939 12.0026 5.83301V11.1663H13.3359C13.6896 11.1663 14.0287 11.0259 14.2787 10.7758C14.5288 10.5258 14.6693 10.1866 14.6693 9.83301V3.16634C14.6693 2.81272 14.5288 2.47358 14.2787 2.22353C14.0287 1.97348 13.6896 1.83301 13.3359 1.83301Z"/>
                                </svg>
                            </div>
                        </div>
                        <div className="edit_original_text">{editContent.slideInfo.original_text}</div>
                    </div>
                }
                {
                    currentImage &&
                    <div className={'edit_ico' + (imageType !== 'Icon' ? ' edit_ico-image' : '')}>
                        {
                            (!product_balance.is_active || product_balance.product?.is_trial) &&
                            <div className="oneslide_info">
                                <svg width="20" height="14" viewBox="0 0 20 14" fill="none">
                                    <path d="M16.9479 6.112C16.5109 2.67 13.5629 0 10.0039 0C7.24791 0 4.85391 1.611 3.76091 4.15C1.61291 4.792 0.00390625 6.82 0.00390625 9C0.00390625 11.757 2.24691 14 5.00391 14H6.00391V12H5.00391C3.34991 12 2.00391 10.654 2.00391 9C2.00391 7.596 3.20291 6.243 4.67691 5.984L5.25791 5.882L5.44991 5.324C6.15291 3.273 7.89791 2 10.0039 2C12.7609 2 15.0039 4.243 15.0039 7V8H16.0039C17.1069 8 18.0039 8.897 18.0039 10C18.0039 11.103 17.1069 12 16.0039 12H14.0039V14H16.0039C18.2099 14 20.0039 12.206 20.0039 10C20.0027 9.10352 19.701 8.23332 19.147 7.5285C18.593 6.82368 17.8187 6.32494 16.9479 6.112Z" fill="#6E718F"/>
                                    <path d="M11.0039 9V5H9.00391V9H6.00391L10.0039 14L14.0039 9H11.0039Z" fill="#6E718F"/>
                                </svg>
                                <div className="oneslide_info__text">Upgrade your plan to unlock the full potential of Wonderslide</div>
                                {/* <span className="oneslide_info__link about_plain_link" onClick={() => {setTrialPopup('upgrade')}}>From just $4.99</span> */}
                            </div>
                        }
                        <div className="edit_ico__title">
                            {imageType === 'Icon' && t.Icon}
                            {imageType !== 'Icon' && t.ImagesCuratedByWonderslide}
                        </div>
                        <form className="edit_ico__search" onSubmit={searchImg}>
                            <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}} />
                            <button type="submit">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="currentColor"><path d="M7.33464 14.0003C8.81378 14 10.2503 13.5049 11.4155 12.5937L15.0788 16.257L16.2571 15.0787L12.5938 11.4153C13.5055 10.25 14.0009 8.81319 14.0013 7.33366C14.0013 3.65783 11.0105 0.666992 7.33464 0.666992C3.6588 0.666992 0.667969 3.65783 0.667969 7.33366C0.667969 11.0095 3.6588 14.0003 7.33464 14.0003ZM7.33464 2.33366C10.0921 2.33366 12.3346 4.57616 12.3346 7.33366C12.3346 10.0912 10.0921 12.3337 7.33464 12.3337C4.57714 12.3337 2.33464 10.0912 2.33464 7.33366C2.33464 4.57616 4.57714 2.33366 7.33464 2.33366Z"/></svg>
                            </button>
                        </form>
                        {
                            (!loading && allImages[imageType]) &&
                            <div className="edit_ico__items" ref={iconsList}>
                                {
                                    foundImgs &&
                                    <>
                                        {imageType === 'Icon' && foundImgs.map((image, i) => {
                                            if (image.image_id === currentImage.image_id) return;
                                            imagesCount++;
                                            let iconUnavailable = !!((!product_balance.is_active || product_balance.product?.is_trial) && imagesCount > 6);
                                            return(
                                                <div key={i}
                                                     className={'edit_ico__item' + (selectedImage.path === image.path ? ' active' : '') + (iconUnavailable ? ' icon_unav hint' : '')}
                                                     onClick={() => {
                                                         if (iconUnavailable) setTrialPopup({type: 'image'});
                                                         else setSelectedImage(image)
                                                     }}>
                                                    { iconUnavailable && <div className="hint_text hint_text-arrow">Upgrade your plan</div> }
                                                    <img src={image.thumb} />
                                                </div>
                                            );
                                        })}
                                        {imageType !== 'Icon' && foundImgs.map((image, i) => {
                                            imagesCount++;
                                            let imgUnavailable = !!((!product_balance.is_active || product_balance.product?.is_trial) && imagesCount > 2);
                                            return(
                                                <div key={i}
                                                     className={cl('edit_ico__item  opacity-80 hover:opacity-100 transition', selectedImage.path === image.path && 'active', imgUnavailable && 'img_unav hint')}
                                                     onClick={() => {
                                                         if (imgUnavailable) setTrialPopup({type: 'image'});
                                                         else setSelectedImage(image)
                                                     }}>
                                                    { imgUnavailable && <div className="hint_text hint_text-arrow">Upgrade your plan</div> }
                                                    <img src={image.thumb} />
                                                    {
                                                        imgUnavailable &&
                                                        <div className="edit_ico__unav">
                                                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                                                <path d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z" fill="#A1A2A8"/></svg>
                                                            <div>Upgrade your plan</div>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </>
                                }
                                {
                                    !foundImgs &&
                                    <>
                                        <div className={cl('edit_ico__item opacity-80 hover:opacity-100 transition',  selectedImage.image_id === currentImage.image_id && 'active')} onClick={() => {setSelectedImage(currentImage)}}>
                                            <img src={currentImage.url} />
                                        </div>
                                        {imageType === 'Icon' && ((editContent.slideInfo.origin === 'bullet' || editContent.slideInfo.origin === 'metabullet') ? [ ...allImages.Icon, ...allImages[imageType]] : allImages[imageType]).map((image, i) => {
                                            if (image.image_id === currentImage.image_id) return;
                                            imagesCount++;
                                            let iconUnavailable = !!((!product_balance.is_active || product_balance.product?.is_trial) && imagesCount > 6);
                                            return(
                                                <div key={i}
                                                     className={'edit_ico__item' + (selectedImage.image_id === image.image_id ? ' active' : '') + (iconUnavailable ? ' icon_unav hint' : '')}
                                                     onClick={() => {
                                                         if (iconUnavailable) setTrialPopup({type: 'image'});
                                                         else setSelectedImage(image)
                                                     }}>
                                                    { iconUnavailable && <div className="hint_text hint_text-arrow">Upgrade your plan</div> }
                                                    <img src={image.url} />
                                                </div>
                                            );
                                        })
                                        }
                                        {imageType !== 'Icon' && editContent.slideInfo.extra_info.scope.map((scope) => (
                                            allImages[imageType][scope].map((image, i) => {
                                                if (image.image_id === currentImage.image_id) return;
                                                imagesCount++;
                                                let imgUnavailable = !!((!product_balance.is_active || product_balance.product?.is_trial) && imagesCount > 2);
                                                return(
                                                    <div key={i}
                                                         className={cl('edit_ico__item   opacity-80 hover:opacity-100 transition', selectedImage.image_id === image.image_id && 'active', imgUnavailable && 'img_unav hint')}
                                                         onClick={() => {
                                                             if (imgUnavailable) setTrialPopup({type: 'image'});
                                                             else setSelectedImage({...image, scope})
                                                         }}>
                                                        { imgUnavailable && <div className="hint_text hint_text-arrow">Upgrade your plan</div> }
                                                        <img src={image.url} />
                                                        {
                                                            imgUnavailable &&
                                                            <div className="edit_ico__unav">
                                                                <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                                                    <path d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z" fill="#A1A2A8"/></svg>
                                                                <div>Upgrade your plan</div>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })
                                        ))}
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="edit_btns">
                <OnboardingMessage
                    boardId={8}
                    isActivate={currentOnboardingStep()}
                    {...ONBOARDING_MESSAGE_EDIT}
                >
                    <div className={'edit_btn gen_btn_main save' + (saveAvail ? '' : ' disabled')} title={t.Save}
                         onClick={save}>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.5 18H16.5C17.0304 18 17.5391 17.7893 17.9142 17.4142C18.2893 17.0391 18.5 16.5304 18.5 16V5L13.5 0H2.5C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2V16C0.5 16.5304 0.710714 17.0391 1.08579 17.4142C1.46086 17.7893 1.96957 18 2.5 18ZM4.5 2H8.5V4H10.5V2H12.5V6H4.5V2ZM4.5 10H14.5V16H4.5V10Z"
                                fill="#F8F8FB"/>
                        </svg>
                        {t.Save}
                    </div>
                    <div className="edit_btn btn-outlined close" onClick={closeEditHandler}>
                        {t.Close}
                    </div>
                </OnboardingMessage>
            </div>
        </div>
    );
};

export default SlideEditPanelBlock;