import {setGlobalRequest} from "../store/slice/common.slice";
import {contrast} from "../../lib/utils";
import {themeColors} from "../data";
import {rgbaToHex} from "@uiw/react-color";
import t from "../../lib/lng";
import {useAppDispatch} from "./use-store";
import {Dispatch, SetStateAction} from "react";
import TagManager from "react-gtm-module";
import {
    useLazyDownloadPresentationPingQuery, useLazyDownloadPresentationQuery,
    useLazyLoadPresentationQuery
} from "../store/api/presentation.api";
import {useRemakeSlideDesignMutation} from "../store/api/slide.api";
import { Presentation } from "../types/presentation.type";


export type DownloadTAgManagerStatus = 'error' | 'redirect';
export type DownLoadHookParams = {
    projectId: number,
    getToast: (sysMessageOptions: { systemMessage: { msg: string, endButtons?: { background: string; text: string; onClick: ({setShow}: { setShow: (status: boolean) => void }) => void }[] }}) => void,
    setTrialPopup: Dispatch<SetStateAction<{type: string, callBack: () => void, onClose: () => void}>>,
    setResendNotify: Dispatch<SetStateAction<boolean>>,
    context: any,
    tagManager: typeof TagManager,
    setDownloadDisabled?: Dispatch<SetStateAction<boolean>>
};

const DOWNLOAD_PROBLEMS_MESSAGES = [
    {
        msg: 'Please, wait a little longer',
        msg4: 'The generation is taking slightly longer than usual, but it will be ready soon',
        type: 'warning',
        autoclose: 10000,
    },
    {
        msg: 'Check your email!',
        msg4: 'To save your time, we\'ll email you the completed presentation after generation done',
        type: 'warning',
        autoclose: 10000,
    }
];

const RESULT_DOWNLOAD_WITHOUT_POPUP: {[key: string]:string} = { FAIL_DOWNLOAD: 'FAIL_DOWNLOAD' };
const RESULT_DOWNLOAD_STATUS: {[key: string]:string} = { USER_NOT_CONFIRMED:  "USER_NOT_CONFIRMED", INVALID_CONSTRAINTS: "INVALID_CONSTRAINTS", EMPTY_BALANCE: "EMPTY_BALANCE", };
const RESULT_TRIAL_POPUP_TYPE: {[key: string]:string} = { USER_NOT_CONFIRMED:  "download expired", INVALID_CONSTRAINTS: "download expired", IS_TRIAL: "download trial", EMPTY_BALANCE: "download expired" };

export function usePresentationDownload(
    {
        tagManager,
        setDownloadDisabled,
        context,
        setResendNotify,
        setTrialPopup,
        getToast,
    }:DownLoadHookParams){
    const dispatch = useAppDispatch();
    const [ reloadPresentationQuery ] = useLazyLoadPresentationQuery();
    const [ remakeSlideDesignMutation ] = useRemakeSlideDesignMutation();
    const [ downloadPresentationPing ] = useLazyDownloadPresentationPingQuery();
    const [ downloadPresentationQuery ] = useLazyDownloadPresentationQuery();

    function stopDownloading(){
        dispatch(setGlobalRequest({ requesting: false }));
        setDownloadDisabled?.(false);
    }
    function downloadHandler ({ presentation, retry=0, ignore_code=false }:{ retry?: number, ignore_code?:boolean, presentation: Presentation['result'], }) {
        if(presentation){
            const projectId = presentation.images_info.project_id;
            const isSample = false;
            function downloadTagManager({ status }: { status: DownloadTAgManagerStatus }){
                tagManager.dataLayer({
                    dataLayer: {
                        event : (isSample ? 'demo_' : '') + 'file_download',
                        file_download_status : (isSample ? 'demo_' : '') + 'file_download_'+status,
                        file_interaction_location: 'file_page',
                        slides_number: Object.keys(presentation?.queue ?? {}).length
                    }
                });
            }
            dispatch(setGlobalRequest({ requesting: true }));
            if(retry === 0){
                //@ts-ignore
                amplitude.getInstance().logEvent('file click download', {});
                tagManager.dataLayer({
                    dataLayer: {
                        event: `click_${isSample ? 'demo_' : ''}file_download`,
                        file_interaction_location: 'file_page',
                    }
                });
            }
            if(!context?.user_ctx?.is_confirmed){
                setResendNotify(true);
                stopDownloading();
                downloadTagManager({ status: 'redirect' });
                return;
            }
            if(context?.product_balance?.product?.is_trial && !ignore_code) {
                stopDownloading();
                setTrialPopup({type: RESULT_TRIAL_POPUP_TYPE.IS_TRIAL, callBack: () => downloadHandler({presentation, retry: 1, ignore_code: true}), onClose: () => {
                        stopDownloading();
                    }});
                downloadTagManager({ status: 'redirect' });
                return;
            }
            downloadPresentationQuery({ projectId }).unwrap().then((downloadResponse) => {
                setDownloadDisabled?.(true);
                const isExistError = Object.values(RESULT_DOWNLOAD_STATUS).includes(downloadResponse.result?.code);
                if(isExistError) {
                    setDownloadDisabled?.(false);
                    const showPopupError = () => getToast({systemMessage: {msg: 'Error downloading presentation'}});
                    dispatch(setGlobalRequest({ requesting: false }));
                    if(downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.FAIL_DOWNLOAD){
                        getToast({ systemMessage: { msg: 'Error downloading presentation' }});
                        dispatch(setGlobalRequest({ requesting: false}));
                        downloadTagManager({ status: 'error' });
                        return;
                    }
                    if(downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.INVALID_CONSTRAINTS){
                        if(retry > 3){
                            showPopupError();
                            return;
                        }
                        const formData = new FormData();
                        formData.append('action_type', 'remake_presentation');
                        formData.append('wonder_file_id', String(presentation?.remake.slide?.[0].wonder_file_id));
                        const ratioWhite = +contrast([255,255,255], presentation.process.option[0].color_value).toFixed(2),
                            ratioBlack = +contrast([0,0,0], presentation.process.option[0].color_value).toFixed(2);
                        let textColor = [];

                        if (ratioWhite > 2) textColor.push('FFFFFF');
                        if (ratioBlack > 4.2) textColor.push('000000');
                        if (ratioWhite <= 2 && ratioBlack <= 4.2) {
                            if (ratioWhite >= ratioBlack) textColor.push('FFFFFF');
                            else textColor.push('000000');
                        }
                        const rgbKeys= presentation.process.option[0].color_value;
                        const rgbFromOptionColor = {
                            r: rgbKeys[0],
                            g: rgbKeys[1],
                            b: rgbKeys[2],
                            a: 100,
                        }
                        if (themeColors.includes(rgbaToHex(rgbFromOptionColor).toLowerCase()) || rgbaToHex(rgbFromOptionColor).toLowerCase() === 'ed2626' && textColor.length > 1) textColor = ['FFFFFF'];
                        formData.append('text_color', JSON.stringify(textColor));
                        formData.append('text_color', String(["FFFFFF","000000"]));
                        remakeSlideDesignMutation({
                            params:{projectId}, body: formData }).unwrap().then((remakePresentationResult) => {
                            if(remakePresentationResult.status) {
                                downloadHandler({presentation, retry: ++retry });
                                reloadPresentationQuery({ projectId: String(projectId) });
                            }else showPopupError();
                        });
                        return;
                    }
                    if (downloadResponse.result?.code === RESULT_DOWNLOAD_STATUS.USER_NOT_CONFIRMED) {
                        //@ts-ignore
                        amplitude.getInstance().logEvent('file download result', {
                            'file interaction location': 'file page',
                            status: 'redirect',
                            message: '',
                            'slides number': Object.keys(presentation.queue).length
                        });
                        setResendNotify(true);
                        downloadTagManager({ status: 'redirect' });
                        return;
                    }
                    setTrialPopup({type: RESULT_TRIAL_POPUP_TYPE[downloadResponse.result?.code], callBack: () => downloadHandler({ presentation, retry: 1, ignore_code: true }), onClose: () => {
                            dispatch(setGlobalRequest({ requesting: false }));
                            setDownloadDisabled?.(false);
                        }});
                    downloadTagManager({ status: 'error' });
                    return;
                }
                if(downloadResponse.status){
                    saveFile({ presentation });
                }else{
                    stopDownloading();
                }
            }).catch((error) => {
                console.error('[DOWNLOAD ERROR]: ', error);
                getToast({systemMessage: {msg: t.oopsSomethingBroke}});
                stopDownloading();
                downloadTagManager({ status: 'error'});
            });
        } else {
            console.error('UNKNOWN PRESENTATION OR NOT INITIALIZED');
        }
    }

    function saveFile({ignoreTrial = false, retry = 1, presentation}: {presentation: Presentation['result'], retry?: number, ignoreTrial?: boolean}) {
        if(presentation){
            const projectId = presentation.images_info.project_id;
            downloadPresentationPing({ projectId }).unwrap().then((response) => {
                if(response?.result?.code) {
                    if(response?.result.code === RESULT_DOWNLOAD_WITHOUT_POPUP.FAIL_DOWNLOAD) {
                        getToast({systemMessage: {msg: t.oopsSomethingBroke}});
                        stopDownloading();
                    }
                    RESULT_TRIAL_POPUP_TYPE?.[response.result.code] && setTrialPopup({
                        type: RESULT_TRIAL_POPUP_TYPE[response.result.code],
                        callBack: () => downloadHandler({presentation, retry: 1, ignore_code: true}),
                        onClose: () => {  stopDownloading(); }
                    });
                    stopDownloading();
                    return;
                }
                if(response?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'){
                    const fileName = decodeURIComponent(presentation.process?.file_info?.filename);
                    const file = new File([response], fileName, {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(file);
                    link.href = url;
                    link.download = file.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    dispatch(setGlobalRequest({ requesting: false }));
                    const isSample = false;
                    //@ts-ignore
                    amplitude.getInstance().logEvent('file download result', {
                        'file interaction location': 'file page',
                        status: 'redirect',
                        message: '',
                        'slides number': Object.keys(presentation.queue).length
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            event : (isSample ? 'demo_' : '') + 'file_download',
                            file_download_status : (isSample ? 'demo_' : '') + 'file_download_success',
                            file_interaction_location: 'file_page',
                            slides_number: Object.keys(presentation.queue).length
                        }
                    });
                    stopDownloading();
                    return;
                }
                if(response?.result){
                    dispatch(setGlobalRequest({ requesting: false }));
                    setDownloadDisabled?.(false);
                }else{
                    const modalInfoButtonStyle = [{
                        background: '#F7F7FC',
                        text: 'Okay',
                        onClick: ({ setShow }: { setShow: (status: boolean) => void }) => setShow(false),
                    }
                    ];
                    if(retry === 5) {
                        getToast({ systemMessage: { ...DOWNLOAD_PROBLEMS_MESSAGES[0], endButtons: modalInfoButtonStyle }});
                    }
                    if(retry === 10){
                        getToast({ systemMessage: { ...DOWNLOAD_PROBLEMS_MESSAGES[1], endButtons: modalInfoButtonStyle } });
                        stopDownloading();
                        return;
                    }
                    setTimeout(() => saveFile({ignoreTrial: false, presentation, retry: ++retry }), 2000);
                }
            }).catch((error) =>{
                console.error('[ERROR DOWNLOAD PING]:', error);
                stopDownloading();
            })
            //     .finally(() => {
            //     stopDownloading();
            // });
        }else{
            console.error('[PRESENTATION NOT INITIALIZED]');
        }

    }
    return {downloadHandler, saveFile}
}

function downloadPresentationPing(arg0: { projectId: any; }) {
    throw new Error("Function not implemented.");
}
