export const legalData = {
    terms: {
		filename: 'Terms_of_use_Wonderslide__Nov_23_2023.pdf',
		title: 'Terms of use',
		date: 'Nov 23, 2023'
	},
	privacy: {
		filename: 'Privacy_Policy__Nov_29_2023.pdf',
		title: 'Privacy Policy',
		date: 'Nov 29, 2023'
	},
	cookie: {
		filename: 'Cookie_Policy.pdf',
		title: 'Cookie Policy',
		date: 'May 11, 2023'
	},
}