import React, { useState } from "react";

import { svgIcons } from "../../create/data/svg-icons.data";
import {useCloneSlideMutation, useRemoveSlideMutation} from "../../../store/api/slide.api";
import { useLazyLoadPresentationQuery } from "../../../store/api/presentation.api";
import { DeleteModal } from "../../../components/template";
import {useAppDispatch, useAppSelector} from "../../../hooks/use-store";
import { setGlobalRequest } from "../../../store/slice/common.slice";
import cl from "classnames";

const SlideMenu = (props) => {
    const { slideIndex, projectId, noDelete = false, noClone=false } = props;
    const { isGlobalRequest } = useAppSelector((state) => state.commonState);
    const [ isOpenDeleteModal, setOpenDeleteModal ] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [ cloneSlideMutation ] = useCloneSlideMutation();
    const [ removeSlideMutation ] = useRemoveSlideMutation();
    const [ updatePresentation ] = useLazyLoadPresentationQuery();
    const dispatch = useAppDispatch();

    if (noClone && noDelete) return null;

    function cloneHandler() {
        if(isGlobalRequest) return;
        dispatch(setGlobalRequest({ requesting: true }));
       const formData = new FormData();
       formData.append('index', slideIndex);
       cloneSlideMutation({ params: { projectId }, body: formData }).unwrap().then((cloneSlideResult) =>{
          if(cloneSlideResult.status){
              updatePresentation({ projectId }).unwrap().then(() => {
                  setIsOpen(false);
              });
          } else{
              console.error('[ ERROR SLIDE CLONE]: ', ' status is false ');
          }
       });
    }
    function deleteHandler(){
        if(isGlobalRequest) return;
        dispatch(setGlobalRequest({ requesting: true }));
        const formData = new FormData();
        formData.append('index', slideIndex);
        removeSlideMutation({ params: {projectId}, body: formData }).unwrap().then((removeSlideResult) => {
           if(removeSlideResult.status){
               updatePresentation({projectId}).unwrap().then(() => {
                   setIsOpen(false);
                   setOpenDeleteModal(false);
               });
           } else {
               console.error('[ error remove slide ]');
           }
        });
    }
    return(
        <div className="create_slide_menu z-[30]" onMouseLeave={() => {setIsOpen(false)}}>
            <div className="create_slide_menu_btn about_plain_link" onClick={() => {setIsOpen((prevState) =>!prevState)}}>{svgIcons.menu}</div>
            {
                isOpen &&
                <div className="file_menu group">
                    {!noClone && <div className={cl("file_menu_item", isGlobalRequest && 'text-general_text_MII_disabled group-hover:text-general_text_MII_disabled cursor-default')} onClick={cloneHandler}>{svgIcons.copy} Duplicate</div>}
                    {!noDelete && <div className={cl("file_menu_item", isGlobalRequest && 'text-general_text_MII_disabled group-hover:text-general_text_MII_disabled cursor-default')} onClick={() => setOpenDeleteModal(true)}>{svgIcons.delete} Delete</div>}
                </div>
            }
         <DeleteModal isOpen={isOpenDeleteModal} deleteType="slide" close={() => setOpenDeleteModal(false)} deleteElement={() => deleteHandler()} />
        </div>
    );
}

export default SlideMenu;