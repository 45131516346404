import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundary from './common/errorBoundary';
import { cookie } from '../lib/utils';
import t from '../lib/lng.js';
import { route_path } from "./utils/route_path";
import { WithoutHeaderLayout } from "./components/layout";
import DefaultLayout from "./components/layout/default";
import { ToastProvider } from "./hooks/use-toast-provider";
import {Provider} from "react-redux";
import {store} from "./store";
import {createRoot} from "react-dom/client";
import {registerAnalytics} from "./utils/analytics";
import { ProjectIdPage, SetupStylePage, CommonOutlet, CreateStartPage, UploadDraftPage, ProjectsPage } from './pages';

const LegalPage = lazy(() => import('./pages/legal/legal.page'));
const TermsPage = lazy(() => import('./pages/legal/terms.page'));
const PrivacyPage = lazy(() => import('./pages/legal/privacy.page'));
const CookiePage = lazy(() => import('./pages/legal/cookie.page'));
const FeaturesPage = lazy(() => import('./pages/features/features.page'));
const AboutPage = lazy(() => import('./pages/about/about.page'));
const Main = lazy(() => import('./main/main.js'));
const Pricing = lazy(() => import('./pricing/pricing.js'));
const Wondercheck = lazy(() => import('./wondercheck/wondercheck.js'));
const Login = lazy(() => import('./auth/login.js'));
const SharedFile = lazy(() => import('./sharing/SharedFile.js'));
const PasswordReset = lazy(() => import('./auth/passwordReset.js'));
const PasswordResetConfirm = lazy(() => import('./auth/passwordResetConfirm.js'));
const CookiePopup = lazy(() => import('./common/cookiePopup.js'));
const PaymentCancelNotify = lazy(() => import('./modals/paymentCancelNotify.js'));
const PaymentSuccessNotify = lazy(() => import('./modals/paymentSuccessNotify.js'));
const RegistrationPage = lazy(() => import('./pages/registration/registration.page.js'));
const Error404Page = lazy(() => import('./pages/error/error404.page'));

const metaData = {
	'/': {
		title: 'Wonderslide - Fast AI Presentation Designer',
		description: 'Wonderslide is a fast and easy way to design stunning presentations.'
	},
	'/pricing/': {
		title: 'Wonderslide Pricing',
		description: 'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.'
	},
	'/about/': {
		title: 'Wonderslide About',
		description: 'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.'
	},
	'/features/': {
		title: 'Wonderslide Features',
		description: 'Wonderslide makes it easy to create professional-looking presentations without breaking the bank.'
	}
};

export default function app() {
	// V18
	const container = document.getElementById('root');
	const root = createRoot(container);
	root.render(<App />)
	// V17
	// ReactDOM.render((
	// 	<App />
	// ), document.getElementById('root'));
}

let prevLocation = {};

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			context: window.context ? window.context : {},
			showCookiePopup: !cookie.get('cookie_accepted'),
			showPaymentSuccessNotify: false,
			showPaymentErrorNotify: false,
			showPaymentCancelNotify: false,
			paymentCancelNotifyBackUrl: '',
			logout: false
		};
	}
	
	componentDidMount() {
		if (window.sessionStorage.getItem('buyFromTrial')) {
			window.sessionStorage.removeItem('buyFromTrial');
			if (this.state.context.product_balance && this.state.context.product_balance.is_active && !this.state.context.product_balance.product?.is_trial && typeof(dataLayer) !== 'undefined') {
				if (this.state.context.product_balance.product.count) {
					dataLayer.push({
						event : 'trial_end',
						trial_end_reason : 'buy_payg'
					});
				}
				else {
					dataLayer.push({
						event : 'trial_end',
						trial_end_reason : 'buy_subscription'
					});
				}
			}
		}

		if (window.location.search.includes('success')) {
			this.setState({showPaymentSuccessNotify: true});
			window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+success/, ''));
		}
		else if (window.location.search.includes('error')) {
			this.setState({showPaymentErrorNotify: true});
			window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+error/, ''));
		}
		else {
			if (window.location.search.includes('cancel')) {
				this.setState({showPaymentCancelNotify: true});
				window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+cancel/, ''));
			}
			if (window.sessionStorage.getItem('gotoProduct')) {
				this.setState({
					showPaymentCancelNotify: true,
					paymentCancelNotifyBackUrl: JSON.parse(window.sessionStorage.getItem('gotoProduct')).url
				});
			}
		}
		window.sessionStorage.removeItem('gotoProduct');
	}
	
	async updateData(callback, logout=false) {
		try {
			let response = await fetch('/init/', {
				method: 'get',
				headers: {'X-Requested-With': 'XMLHttpRequest', 'X-CSRFToken': cookie.get('csrftoken')}
			});
			response = await response.json();

			if (response.status) {
				if (!Object.keys(response.result.user_ctx).length) response.result.user_ctx = null;
				if (!Object.keys(response.result.TAG).length) response.result.TAG = null;
				response.result.shared_file = this.state.context.shared_file;
				this.setState({
					context: response.result,
					logout
				}, () => {
					if (typeof(callback) === 'function') callback(response.result);
				});
				if (response.result.GEO) {
					cookie.set('GEO', response.result.GEO, '', '', '', '/');
				}
			}
		}
		catch(e) {
			console.log(e);
		}
	}

	updateSharedFile(data) {
		let state = {...this.state, context: {...this.state.context, shared_file: data}};
		this.setState(state);
	}

	render() {
		const { context, logout } = this.state;
		return(
			<ErrorBoundary>
				<Suspense fallback={<div style={{background: '#191B20', height: '100vh'}}></div>}>
					<Provider store={store}>
						<ToastProvider>
							<BrowserRouter basename="/">
								<RoutesContainer context={context} updateData={::this.updateData} updateSharedFile={::this.updateSharedFile} logout={logout} />
								{
									this.state.showCookiePopup &&
									<CookiePopup close={() => {this.setState({showCookiePopup: false})}} />
								}
								{
									this.state.showPaymentSuccessNotify &&
									<PaymentSuccessNotify close={() => {this.setState({ showPaymentSuccessNotify: false })}} />
								}
								{
									this.state.showPaymentErrorNotify &&
									<PaymentErrorNotify close={() => {this.setState({showPaymentErrorNotify: false})}} />
								}
								{
									this.state.showPaymentCancelNotify &&
									<PaymentCancelNotify close={() => {this.setState({showPaymentCancelNotify: false})}} backUrl={this.state.paymentCancelNotifyBackUrl} isTrial={context.product_balance.product?.is_trial} />
								}
							</BrowserRouter>
						</ToastProvider>
					</Provider>
				</Suspense>
			</ErrorBoundary>
		);
	}
}

function RoutesContainer({ context, updateData, updateSharedFile, logout }) {
	const location = useLocation();
	const navigate = useNavigate();

	
	useEffect(() => {
		window.scrollTo(0, 0);
		setMetaData(location.pathname);
		updateData();
		scrollToAnchor(location.hash);
		updateViewport(location.pathname);

		if (context.user_ctx) {
			amplitude.getInstance().setUserId(context.user_ctx.id);
			trackAuth(context.user_ctx.id);
			if (!cookie.get('uid')) {
				cookie.set('uid', context.user_ctx.id, '2050', '', '', '/');
			}
		}
		if (!location.pathname.match(/\/create\//)) window.sessionStorage.removeItem('genState');
		if (!location.pathname.match(/\/create-draft\//)) window.sessionStorage.removeItem('draftState');
		
		let projectId = location.pathname.match(/[0-9]+\/$/);
		if (projectId) {
			amplitude.getInstance().logEvent('pageview', {
				'URL': location.pathname,
				'processed file id': +projectId[0].replace('/', '')
			});
		}
		else {
			context?.user_ctx && amplitude.getInstance().logEvent('pageview', {
				'URL': location.pathname,
			});
		}

		if (location.pathname.match(/upload-draft/) && !location.search.includes('demo')) {
			if (typeof(dataLayer) !== 'undefined') {
				dataLayer.push({
					event: 'file_upload_open'
				});
			}
			amplitude.getInstance().logEvent('file upload open');
		}

		prevLocation = location;
	}, [location.pathname, location.hash]);

	return(
		<Routes>
			<Route path={route_path.main} element={<CommonOutlet updateSharedFile={updateSharedFile} context={context} updateData={updateData} logout={logout} />}>
				<Route index element={<Main context={context} updateData={updateData} />} />
				<Route path="/pricing/" element={<Pricing context={context} updateData={updateData} />} />
				<Route path={route_path.main} element={<WithoutHeaderLayout context={context} updateData={updateData} />}>
					{/***** Page about *****/}
					<Route path={route_path.about} element={<AboutPage />} />
					{/***** Page features *****/}
					<Route path={route_path.features} element={<FeaturesPage />} />
				</Route>
				{/* REDUX UPDATE */}
				<Route path={route_path.main} element={<DefaultLayout context={context} updateData={updateData}  />} >
					{/* START CREATE PAGE */}
					<Route path="/create/" element={
						<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>
							<CreateStartPage />
							{/*<Create page="create" context={context} navigate={navigate} updateData={updateData} isDemo={location.search.includes('demo') ? true : false} />*/}
						</ProtectedRoute>
					}/>
					{/* CREATE PRESENTATION AND SELECT STYLE */}
					<Route path={`${route_path.setupStyle}:projectId/`} element={
						<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>
							<SetupStylePage />
						</ProtectedRoute>
					}/>
					{/* UPLOAD DRAFT PAGE */}
					<Route path={`${route_path.uploadDraft}`} element={
						<ProtectedRoute
							user_ctx={context.user_ctx}
							logout={logout}>
								<UploadDraftPage isDemo={location.search.includes('demo')} />
						</ProtectedRoute>
					} />
					{/* PROJECT PAGE */}
					<Route path={`${route_path.project}:projectId/`} element={
						<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>
							<ProjectIdPage />
						</ProtectedRoute>
					}/>
					{/* PRESENTATIONS */}
					<Route path={route_path.projects} element={
						<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>
							<ProjectsPage />
						</ProtectedRoute>
					}/>
				</Route>
				{/*<Route path="/create-draft/" element={*/}
				{/*	<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>*/}
				{/*		<Draft context={context} updateData={updateData} />*/}
				{/*	</ProtectedRoute>*/}
				{/*}/>*/}
				{/*<Route path="/create-draft/*" element={*/}
				{/*	<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>*/}
				{/*		<Draft context={context} updateData={updateData} processedFileId={location.pathname.match(/\/create-draft\/[0-9]+\//) ? location.pathname.split('/create-draft/')[1].replaceAll('/', '') : null} />*/}
				{/*	</ProtectedRoute>*/}
				{/*}/>*/}
				{/*<Route path="/create/upload-draft/" element={*/}
				{/*	<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>*/}
				{/*		<Create page="upload-draft" context={context} updateData={updateData} navigate={navigate} />*/}
				{/*	</ProtectedRoute>*/}
				{/*}/>*/}
				{/*<Route path="/project/:projectId/" element={*/}
				{/*	<ProtectedRoute user_ctx={context.user_ctx} logout={logout}>*/}
				{/*		/!*<Create page="project" context={context} navigate={navigate} updateData={updateData} />*!/*/}
				{/*	</ProtectedRoute>*/}
				{/*}/>*/}
			<Route path="/wondercheck/*" element={<Wondercheck />} />
			{/***** Page LEGAL ROUTE *****/}
			<Route path={route_path.legal} element={<DefaultLayout context={context} updateData={updateData} />}>
				<Route index element={<LegalPage/>} />
				<Route path={route_path.legal_terms} element={<TermsPage />} />
				<Route path={route_path.legal_cookie} element={<CookiePage />} />
				<Route path={route_path.legal_privacy} element={<PrivacyPage />} />
			</Route>
			<Route path="/login/" element={<Login context={context} updateData={updateData} />} />
			{/***** REGISTRATION  *****/}
			<Route path="/register/" element={<RegistrationPage context={context} updateData={updateData} />} />
			<Route path="/password-reset/" element={<PasswordReset context={context} />} />
			<Route path="/password-reset/:token" element={<PasswordResetConfirm context={context} />} />
			<Route path="/s/*" element={<SharedFile context={context} updateSharedFile={updateSharedFile} />} />
			</Route>
			<Route path="*" element={<Error404Page/>} />
		</Routes>
	);
}

function ProtectedRoute({ user_ctx, logout, children }) {
	if (!user_ctx && !logout) {
		return <Navigate to="/register/" replace />;
	}
	return children;
};

function setMetaData(pathname) {
	if (metaData[pathname]) {
		document.querySelector('title').innerText = metaData[pathname].title;
		document.querySelector('meta[name="description"]').content = metaData[pathname].description;
	}
	else if (!pathname.match('/s/')) {
		document.querySelector('title').innerText = 'Wonderslide';
		document.querySelector('meta[name="description"]').content = '';
	}
}

function scrollToAnchor(hash) {
	if (!hash) return;
	setTimeout(() => {
		const el = document.getElementById(hash.replace('#', ''));
		if (el) el.scrollIntoView();
	}, 200);
}

function updateViewport(pathname) {
	if (window.innerWidth > 1024 || !pathname) return;
	if (pathname.match(/\/create|files/)) {
		document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024');
	}
	else if (!document.querySelector('meta[name="viewport"]').content.match(/width=device-width/)) {
		document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
	}
}

function trackAuth(userId) {
	let accountsOnDevice = 1;

	if (window.localStorage.getItem('accounts')) {
		const accounts = JSON.parse(window.localStorage.getItem('accounts'));
		if (!accounts.includes(userId)) {
			accounts.push(userId);
			window.localStorage.setItem('accounts', JSON.stringify(accounts));
		}
		accountsOnDevice = accounts.length;
	}
	else {
		window.localStorage.setItem('accounts', '['+userId+']');
	}
	let method = 'email';
	if (window.sessionStorage.getItem('auth_by_google')) {
		method = 'google';
	}
	if (window.sessionStorage.getItem('login') || window.sessionStorage.getItem('auth_by_google') && !cookie.get('oauth_reg')) {

		if (typeof(dataLayer) !== 'undefined') {
			dataLayer.push({
				event: 'login',
				method: method
			});
		}
		amplitude.getInstance().logEvent('service login', {
			'USER ID': userId,
			'accounts_on_device': accountsOnDevice
		});
		window.sessionStorage.removeItem('login');
		window.sessionStorage.removeItem('auth_by_google');
	}
	else if (window.sessionStorage.getItem('reg'))
	{
		registerAnalytics.userRegistered({ method: 'email', userId });
	}
}

const PaymentErrorNotify = ({ close }) => {
	return(
		<div className="modal">
			<div className="modal_overlay" onClick={close}></div>
			<div className="modal_window">
				<div className="modal_close" onClick={close}>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none">
						<line y1="-1" x2="21.2624" y2="-1" transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)" stroke="black" strokeWidth="2"/>
						<line y1="-1" x2="21.2624" y2="-1" transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)" stroke="black" strokeWidth="2"/>
					</svg>
				</div>
				<div className="modal_msg">{t.paymentErrorMsg1}<br/>{t.paymentErrorMsg2}</div>
			</div>
		</div>
	);
}