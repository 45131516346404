import React, { FC } from 'react';
import t from "../../../../../lib/lng";
import {useAppSelector} from "../../../../hooks/use-store";

type UploadProcessBlockProps = {
    uploadingFileName?: string;
}

const UploadProcessBlock: FC<UploadProcessBlockProps> = (props) => {
    const { fileUpload } = useAppSelector((state) => state.presentationState);
    const { uploadProgress, fileName } = fileUpload;
    if (!fileName) return null;
    return (
        <div className="sticky bottom-0 m-auto w-full bg-[#202020] z-[20]">
            <div className="gen_content__footer  w-full h-[87px]">
            <div className="gen_content__uploading">
                <div className="gen_content__uploading_process">
                    <div className="gen_content__uploading_process__inner"
                         style={{width: uploadProgress + '%'}}></div>
                </div>
                <div className="gen_content__uploading_info">
                    <span className="gen_content__uploading__percent">{uploadProgress}% </span>
                    {uploadProgress == 100 && <span className="gen_content__uploading_done">{t.Done}</span>}
                    <span className="gen_content__filename"><span>{fileName}</span></span>
                </div>
            </div>
        </div>
        </div>
    );
};

export default UploadProcessBlock;