import React, {useEffect, useState} from 'react';
import cl from "classnames";

import { useAppSelector } from "../../../hooks/use-store";

const ResultMapBlock = (props) => {
    const { slidesRefs, presentation, addSlide } = props;
    const isGlobalRequest = useAppSelector(state => state.commonState.isGlobalRequest);
    const [activeSlide, setActiveSlide] = useState(-1);
    const [postfix, setPostfix] = useState('');
    useEffect(() => {
        setPostfix('?' + Date.now());
    }, [presentation]);
    if(presentation )
    return(
        <div className="create_slides_map">
            <div className="create_slides_map__inner">
                <div className="create_slides_map__items">
                    {Object.keys(presentation.slides_info ?? {}).map((slideKey, slideMapIndex) => {return(
                        <div key={slideKey}
                             className={'create_slides_map__item' + (activeSlide === slideMapIndex ? ' active' : '')}
                             onClick={() => {
                                 setActiveSlide(slideMapIndex);
                                 if (slidesRefs[slideMapIndex]) {
                                     slidesRefs[slideMapIndex].scrollIntoView({block: 'center'});
                                 }
                             }}
                        >

                            {
                                presentation.download && presentation.download.thumb[slideKey] && presentation.queue_ready && presentation.queue_ready[slideKey] && presentation.queue_ready[slideKey].ready ?
                                    <img src={presentation.download.thumb[slideKey]+postfix} />
                                    :
                                    +slideKey + 1
                            }
                        </div>
                    )})}
                </div>
                <div className={cl("create_slides_map__add ", isGlobalRequest && 'text-general_background_MIII_400_dark animate-pulse')} onClick={() => addSlide()}>+</div>
            </div>
        </div>
    );
};

export default ResultMapBlock;