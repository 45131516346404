import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import FileMenu from "./file-menu.block";
import {useAppDispatch} from "../../../hooks/use-store";
import {svgIcons} from "../data/icon.data";

const FileItem = (props) => {
    const { file, onClickMenuItem } = props;
    const [fileMenuOpened, setFileMenuOpened] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const formatDate = datestr => {
        let str = '',
            date = new Date(datestr),
            localeTimeString = date.toLocaleTimeString().split(':');

        str = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric'
        }) + ', ' + localeTimeString[0] + ':' + localeTimeString[1];
        return str;
    }

    const displayedTitle = file.description ? file.description.replace(/.pptx$|.json$/, '') : 'Untitled';

    function openPresentationHandler(event){
        event.preventDefault();
        navigate(`${file.project}`);
    }
    return(
        <div className="file" onMouseLeave={() => {setFileMenuOpened(false)}}>
            <Link to={`${file.project}/`}
                  onClick={openPresentationHandler}
                  className="file_img_wrap"
                  title={displayedTitle}>
                {file.preview[0] && <img className="file_img" src={file.preview[0]} alt="" />}
            </Link>
            <div className="file_name_wrap">
                <Link to={`${file.project}/`} className="file_name" title={displayedTitle}>{displayedTitle}</Link>
                <div className="file_controls" onClick={() => {setFileMenuOpened(true)}}>{svgIcons.dots}</div>
            </div>
            <div className="file_date">{formatDate(file.date_created)}</div>
            {
                file.sharing.status &&
                <div className="file_share hint" onClick={() => {onClickMenuItem('share')}}>
                    {svgIcons.shared}
                    <div className="hint_text">Available by link</div>
                </div>
            }
            {
                fileMenuOpened &&
                <FileMenu onClickMenuItem={onClickMenuItem} is_protected={file.is_protected} readonly={file.readonly} close={() => {setFileMenuOpened(false)}} />
            }
        </div>
    );
}

export default FileItem;
